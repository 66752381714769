import { CalendarOutlined, CoffeeOutlined, UserOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'

interface IProps extends RouteComponentProps {
  className?: string
}

function MainNavigation(props: IProps) {
  return (
      <Menu selectedKeys={[props.location.pathname]} mode="horizontal" className={props.className}>
        <Menu.Item key="/" icon={<CalendarOutlined />}>
          <Link to="/">Dienstpläne</Link>
        </Menu.Item>
        <Menu.Item key="/offDuties" icon={<CoffeeOutlined />}>
          <Link to="/offDuties">Urlaub / Dienstfrei</Link>
        </Menu.Item>
        <Menu.Item key="/staff" icon={<UserOutlined />}>
          <Link to="/staff">Mitarbeiter</Link>
        </Menu.Item>
      </Menu>
  )
}

export default withRouter(MainNavigation)
