import { Button, Popconfirm, Space, Table, Tooltip } from 'antd'
import {
  DeleteOutlined,
  QuestionCircleFilled,
  EditOutlined,
} from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import {
  IMembership,
  remove,
} from '../../../../features/memberships/membershipsSlice'
import styles from './TableView.module.css'
import Radio from 'antd/lib/radio'
import { useAppDispatch } from '../../../../app/hooks'
import Initials from '../../../../common/components/Initials'
import Avatar from '../../../../common/components/Avatar'
import { EEmploymentType } from '../../../../features/memberships/employmentType'
import { getDayShortNames } from '../../../../common/lib/util/date'

interface IProps {
  memberships: IMembership[]
  employmentType: EEmploymentType
  handleEmploymentTypeChanged: (value: EEmploymentType) => void
  handleEditMembership: (value: IMembership) => void
}

export default function TableView(props: IProps) {
  const dispatch = useAppDispatch()
  const columns: ColumnsType<IMembership> = [
    {
      title: 'Avatar',
      key: 'avatar',
      render: (record: IMembership) => {
        return <Avatar membershipId={record.id} className={styles.avatar} />
      },
      width: 32,
    },
    {
      title: 'Farbe',
      key: 'color',
      render: (record: IMembership) => {
        return <Initials small backgroundColor={record.backgroundColor} useWhiteFont={record.useWhiteFont} firstName={record.firstName} lastName={record.lastName} />
      },
      width: 32,
    },
    {
      title: 'Nachname',
      key: 'lastname',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        return a.lastName.localeCompare(b.lastName)
      },
      render: (record) => {
        if (!record) return null
        return <span>{record.lastName}</span>
      },
    },
    {
      title: 'Vorname',
      key: 'firstname',
      sorter: (a, b) => {
        return a.firstName.localeCompare(b.firstName)
      },
      render: (record) => {
        if (!record) return null
        return <span>{record.firstName}</span>
      },
    },
    {
      title: 'Urlaubstage / Jahr',
      key: 'vacationdays',
      sorter: (a, b) => {
        return a.vacationDaysPerYear - b.vacationDaysPerYear
      },
      render: (record) => {
        if (!record) return null
        const value = record.vacationDaysPerYear
          ? record.vacationDaysPerYear
          : '-'
        return <span>{value}</span>
      },
    },
    {
      title: 'Stunden / Woche',
      key: 'workinghours',
      sorter: (a, b) => {
        return a.workingMinutesPerWeek - b.workingMinutesPerWeek
      },
      render: (record) => {
        if (!record) return null
        const value = record.workingMinutesPerWeek
          ? (record.workingMinutesPerWeek / 60).toFixed(2)
          : '-'
        return <span>{value}</span>
      },
    },
    {
      title: 'Arbeitstage / Woche',
      key: 'validWeekDays',
      sorter: (a, b) => {
        return a.validWeekDays.length - b.validWeekDays.length
      },
      render: (record) => {
        if (!record) return null
        const value = record.validWeekDays?.length || '-'
        const dayString = record.validWeekDays && record.validWeekDays.length < 5
          ? '(' + getDayShortNames(record.validWeekDays).join(', ') + ')'
          : ''
        return <span>{value} {dayString}</span>
      },
    },
    {
      title: '',
      key: 'actions',
      render: (record: IMembership) => {
        if (!record) return null
        return (
          <Space>
            <Tooltip title="Bearbeiten">
              <Button
                icon={<EditOutlined />}
                onClick={() => props.handleEditMembership(record)}
                shape={'circle'}
                disabled={!record.canUpdate}
              />
            </Tooltip>
            <Popconfirm
              placement="left"
              icon={<QuestionCircleFilled />}
              title="Soll dieser Mitarbeiter wirklich gelöscht werden?"
              okText="Ja, löschen"
              okButtonProps={{ danger: true }}
              onConfirm={() => dispatch(remove(record.id))}
              cancelText="Nein"
              disabled={!record.canRemove}
            >
              <Tooltip title="Löschen">
                <Button
                  icon={<DeleteOutlined />}
                  shape={'circle'}
                  danger
                  disabled={!record.canRemove}
                />
              </Tooltip>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const tableOptions = [
    { label: 'Ärztliches Personal', value: EEmploymentType.Doctor },
    { label: 'Pflegepersonal', value: EEmploymentType.Nurse },
  ]

  return (
    <>
      <div className={styles.header}>
        <Tooltip title={'Personaltyp auswählen'}>
          <Radio.Group
            options={tableOptions}
            onChange={(e) => {
              props.handleEmploymentTypeChanged(e.target.value)
            }}
            value={props.employmentType}
            optionType="button"
            buttonStyle={'solid'}
          />
        </Tooltip>
      </div>
      <Table
        rowKey="id"
        dataSource={props.memberships}
        columns={columns}
        pagination={false}
      />
    </>
  )
}
