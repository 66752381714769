import { getDaysInMonth, lastDayOfMonth } from 'date-fns'
import React, { useMemo } from 'react'
import { useAppSelector } from '../../app/hooks'
import {
  IDutyRoster,
  selectDutyRosterById,
} from '../../features/duty-rosters/dutyRostersSlice'
import { getWeekendsAndPublicHolidaysOfInterval } from '../lib/workingTime'
export interface IDutyRosterContext {
  businessDays: number
  firstDayOfMonth: Date | undefined
  dutyRoster: IDutyRoster | undefined
  isFullyLoaded: boolean
  isLoading: boolean
  setLoading: (value: boolean) => void
}
interface DutyRosterContextProviderProps {
  children: React.ReactNode
  dutyRosterId: string
  isFullyLoaded: boolean
  isLoading: boolean
  setLoading: (value: boolean) => void
}

const DutyRosterContext = React.createContext<IDutyRosterContext | undefined>(
  undefined
)

function DutyRosterContextProvider(props: DutyRosterContextProviderProps) {
  const dutyRoster = useAppSelector((state) =>
    selectDutyRosterById(state, props.dutyRosterId)
  )

  const firstDayOfMonth = useMemo(() => {
    if (!dutyRoster) {
      return undefined
    } else {
      return new Date(dutyRoster.year, dutyRoster.month, 1)
    }
  }, [dutyRoster])

  const businessDays = useMemo(() => {
    if (!dutyRoster || !firstDayOfMonth) return 0

    const lastDay = lastDayOfMonth(firstDayOfMonth)
    const numberOfDaysInMonth = getDaysInMonth(firstDayOfMonth)
    const { weekends, publicHolidays } = getWeekendsAndPublicHolidaysOfInterval(
      firstDayOfMonth,
      lastDay
    )
    return numberOfDaysInMonth - weekends.length - publicHolidays.length
  }, [dutyRoster, firstDayOfMonth])

  const value = {
    businessDays: businessDays,
    firstDayOfMonth: firstDayOfMonth,
    dutyRoster: dutyRoster,
    isFullyLoaded: props.isFullyLoaded,
    isLoading: props.isLoading,
    setLoading: props.setLoading
  }

  return (
    <DutyRosterContext.Provider value={value}>
      {props.children}
    </DutyRosterContext.Provider>
  )
}

function useDutyRosterContext() {
  const context = React.useContext(DutyRosterContext)
  if (context === undefined) {
    throw new Error('useDutyRoster must be used within a DutyRosterProvider')
  }
  return context
}

export { DutyRosterContextProvider, useDutyRosterContext }
