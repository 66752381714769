import { Form, FormInstance, InputNumber, Button, Tooltip } from 'antd'
import { Component, createRef } from 'react'

interface IProps {
  handleFinish: (values: IAdditionalVacationFormValues) => Promise<void>
  initialValues: IAdditionalVacationFormValues
  disabled: boolean
}

export interface IAdditionalVacationFormValues {
  days: number
}

export default class AdditionalVacationForm extends Component<IProps> {
  private formRef = createRef<FormInstance>()

  render() {
    return (
      <Form
        ref={this.formRef}
        layout="inline"
        onFinish={(values) => {
          !this.props.disabled && this.props.handleFinish(values)
        }}
        preserve={false}
        initialValues={this.props.initialValues}
      >
        <Form.Item label="" name="days">
          <InputNumber disabled={this.props.disabled} min={0} max={4} />
        </Form.Item>
        {!this.props.disabled && (
          <Form.Item>
            <Tooltip title="Zusatzurlaub speichern">
              <Button type="primary" htmlType="submit">
                Speichern
              </Button>
            </Tooltip>
          </Form.Item>
        )}
      </Form>
    )
  }
}
