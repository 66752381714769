import { IMembership } from '../../../../../../features/memberships/membershipsSlice'
import { IOffDuty } from '../../../../../../features/off-duty/offDutySlice'
import { RenderLane, RENDER_LANE_HEIGHT } from '../../CalendarView'
import Employee from '../Employee/Employee'
import styles from './Employees.module.css'

interface Props {
  memberships: IMembership[]
  renderLanes: RenderLane[]
  offDuties: IOffDuty[]
  height: number
}
const Employees = (props: Props) => {
  return (
    <div className={styles.root} style={{ height: props.height }}>
      {props.memberships.map((membership, index) => {
        const height =
          props.renderLanes.filter(
            (renderLane) => renderLane.membershipId === membership.id
          ).length * RENDER_LANE_HEIGHT

        const offDuties = props.offDuties.filter(
          (offDuty) => offDuty.membershipId === membership.id
        )

        return (
          <Employee
            key={membership.id}
            employee={membership}
            height={height}
            offDuties={offDuties}
            isOdd={index % 2 === 0}
          />
        )
      })}
    </div>
  )
}
export default Employees
