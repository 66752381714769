import React, { ForwardedRef, memo, useCallback, useLayoutEffect, useRef, useState } from 'react'
import { IMembership } from '../../../../../../features/memberships/membershipsSlice'
import { RenderLane } from '../../CalendarView'
import DayLines from '../DayLines/DayLines'
import OffDuties from '../OffDuties/OffDuties'
import styles from './Content.module.css'
import cs from 'classnames'

interface Props {
  memberships: IMembership[]
  renderLanes: RenderLane[]
  year: number
  height: number
  onMouseMove: (e: any) => void
  onGrabStart: (e: any) => void
  onGrabStop: (e: any) => void
}

const Content = (props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const dayLinesRef = useRef<HTMLDivElement>(null)
  const offDutiesRef = useRef<HTMLDivElement>(null)
  const [isGrabbing, setGrabbing] = useState(false)

  // sync the widths
  useLayoutEffect(() => {
    const widthOfDayLines = dayLinesRef.current?.clientWidth
    offDutiesRef.current!.style.width = `${widthOfDayLines!}px`
  }, [])

  const rootClasses = cs({
    [styles.root]: true,
    [styles.isGrabbing]: isGrabbing
  })

  const onMouseDown = useCallback((e) => {
    setGrabbing(true)
    props.onGrabStart(e)
  },[props])
  const onMouseUp = useCallback((e) => {
    setGrabbing(false)
    props.onGrabStop(e)
  },[props])

  return (
    <div
      className={rootClasses}
      ref={ref}
      style={{ height: props.height }}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={props.onMouseMove}
    >
      <DayLines ref={dayLinesRef} year={props.year} />
      <OffDuties
        ref={offDutiesRef}
        memberships={props.memberships}
        renderLanes={props.renderLanes}
      />
    </div>
  )
}

export default memo(React.forwardRef<HTMLDivElement, Props>(Content))
