import { useAppSelector } from '../../../app/hooks'
import { selectCurrentUser } from '../../../features/currentUser/currentUserSlice'
import AccountMenu from './AccountMenu'

const Container = () => {
  const currentUser = useAppSelector(selectCurrentUser)
  return (
    <AccountMenu
      firstName={currentUser.firstName}
      lastName={currentUser.lastName}
      avatarUrl={currentUser.avatar}
    />
  )
}

export default Container
