import { Draggable } from 'react-beautiful-dnd'
import { useAppSelector } from '../../app/hooks'
import WorkAssignmentPresentational from './WorkAssignment'
import { IWorkAssignment } from './workAssignmentsSlice'
import { selectShiftById } from '../shifts/shiftsSlice'
import { IShiftContext, ShiftContext } from '../../common/contexts/shift'
import { useMemo } from 'react'
import { selectSwapModeActive } from '../app/appSlice'
import { selectMembershipById } from '../memberships/membershipsSlice'
import { useDutyRosterContext } from '../../common/contexts/dutyRoster'
import { selectCurrentUser } from "../currentUser/currentUserSlice"

interface IProps {
  workAssignment: IWorkAssignment
  index: number
  shiftId: string
}

function ConnectedWorkAssigment({ workAssignment, index, shiftId }: IProps) {
  const dutyRosterContext = useDutyRosterContext()
  const currentUser: any = useAppSelector(selectCurrentUser)

  const plannedShift = useAppSelector((state) =>
    selectShiftById(state, workAssignment.shiftId)
  )
  const actualShift = useAppSelector((state) =>
    selectShiftById(state, workAssignment.actualShiftId)
  )
  const membership = useAppSelector((state) =>
    selectMembershipById(state, workAssignment.membershipId)
  )
  const isSwapModeActive = useAppSelector(selectSwapModeActive)

  const contextObject: IShiftContext | undefined = useMemo(() => {
    if (plannedShift) {
      return {
        planned: plannedShift,
        actual: actualShift,
        displayedShiftId: shiftId,
        isInactive: actualShift !== undefined && actualShift.id !== shiftId,
      }
    }
  }, [plannedShift, actualShift, shiftId])

  if (!dutyRosterContext.dutyRoster) return null

  const isRosterApproved = dutyRosterContext.dutyRoster.isApproved

  const hasAbbreviation = workAssignment.abbreviation !== null

  const workAssignmentIsInactive = contextObject
    ? contextObject.isInactive
    : false
  const draggableId = workAssignmentIsInactive
    ? `${workAssignment.id}_inactive`
    : workAssignment.id

  const isDragDisabled =
    isSwapModeActive ||
    workAssignmentIsInactive ||
    !workAssignment.canUpdate ||
    isRosterApproved ||
    workAssignment.isCanceled ||
    hasAbbreviation

  const noDropDown =
    isSwapModeActive ||
    workAssignmentIsInactive ||
    isRosterApproved ||
    dutyRosterContext.dutyRoster.employmentType !== currentUser.employmentType

  return (
    <Draggable
      draggableId={draggableId}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => {
        const { style: draggableStyles, ...draggableProps } =
          provided.draggableProps
        return (
          <div
            style={draggableStyles}
            {...draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            {/* todo rename to propably WorkAssignmentContext */}
            <ShiftContext.Provider value={contextObject}>
              <WorkAssignmentPresentational
                workAssignment={workAssignment}
                noDropdown={noDropDown}
                membership={membership!}
              />
            </ShiftContext.Provider>
          </div>
        )
      }}
    </Draggable>
  )
}

export default ConnectedWorkAssigment
