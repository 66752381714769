import { DatePicker, Form, FormInstance, Input, Select } from 'antd'
import Text from 'antd/lib/typography/Text'
import moment, { Moment } from 'moment'
import { Component, createRef } from 'react'
import { IMembership } from '../../../features/memberships/membershipsSlice'
import { IOffDuty } from '../../../features/off-duty/offDutySlice'
const { Option } = Select
const { RangePicker } = DatePicker
const { TextArea } = Input

interface IProps {
  visible: boolean
  memberships: IMembership[]
  handleFinish: (values: ITimeOffFormValues) => Promise<void>
  offDuty: IOffDuty | undefined
  currentUserUuid: string
}

export interface ITimeOffFormValues {
  period: Moment[]
  membershipId: string
  comment: string
}

export default class TimeOffForm extends Component<IProps> {
  private formRef = createRef<FormInstance>()

  submit() {
    this.formRef.current?.submit()
  }

  isDateDisabled(current: Moment) {
    // disable today and days before today
    return current && current < moment().endOf('day')
  }

  renderOptions() {
    if (!this.props.memberships) return null

    return this.props.memberships
      .sort((a, b) => (a.lastName < b.lastName ? -1 : 1))
      .map((membership) => {
      return (
        <Option value={membership.id} key={membership.id}>
          {`${membership.lastName}, ${membership.firstName}`}
        </Option>
      )
    })
  }

  defaultSelect: any = this.props.memberships.find(
    (membership) => membership.userId === this.props.currentUserUuid
  )?.id

  render() {
    return (
      <>
        <Text style={{ marginBottom: '1rem', display: 'block' }}>
          Hier können Sie dienstfreie Tage beantragen, die bei der Erstellung
          des Dienstplans - soweit möglich - berücksichtigt werden.
        </Text>
        <Form
          ref={this.formRef}
          layout="vertical"
          onFinish={(values) => {
            this.props.handleFinish(values)
          }}
          initialValues={{
            membershipId:
              this.props.offDuty?.membershipId || this.defaultSelect,
            period: [
              moment(this.props.offDuty?.from),
              moment(this.props.offDuty?.to),
            ],
            comment: this.props.offDuty?.comment,
          }}
          preserve={false}
        >
          <Form.Item
            label="Zeitraum"
            name="period"
            rules={[
              {
                required: true,
                message: 'Bitte wählen Sie einen Zeitraum aus.',
              },
            ]}
          >
            <RangePicker
              disabledDate={
                this.props.offDuty ? undefined : this.isDateDisabled
              }
              placeholder={['Startdatum', 'Enddatum']}
              format="DD. MMMM YYYY"
            />
          </Form.Item>
          <Form.Item
            label="Mitarbeiter"
            name="membershipId"
            rules={[
              {
                required: true,
                message: 'Bitte wählen Sie einen Mitarbeiter aus.',
              },
            ]}
            style={{ width: '255px' }}
          >
            <Select disabled={!!this.props.offDuty}>{this.renderOptions()}</Select>
          </Form.Item>
          <Form.Item
            label="Bemerkung"
            name="comment"
            rules={[
              {
                required: false,
                message: 'Bitte geben Sie eine kurze Bemerkung dazu',
              },
            ]}
          >
            <TextArea placeholder="Bemerkung" rows={4} />
          </Form.Item>
        </Form>
      </>
    )
  }
}
