import { format, getDay, isSameMonth, isWeekend } from 'date-fns'
import { useMemo } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import { selectDragInfo } from '../../../../features/app/appSlice'
import {
  IOffDuty,
  selectOffDutiesByDate,
  selectVacationsByDateAndMembership,
} from '../../../../features/off-duty/offDutySlice'
import { selectSchoolHolidaysByDate } from '../../../../features/school-holidays/schoolHolidaySlice'
import {
  IShift,
  selectAllShifts,
} from '../../../../features/shifts/shiftsSlice'
import { useDutyRosterContext } from '../../../contexts/dutyRoster'
import { ShiftsPerDayContext } from '../../../contexts/shiftsPerDayContext'
import HolidayRepository from '../../../lib/util/holidays'
import DayPresentational from './Day'
import {
  IMembership,
  selectMembershipsByEmploymentType
} from "../../../../features/memberships/membershipsSlice";

interface IProps {
  date: Date
  firstDayOfMonth: Date
}

function Day(props: IProps) {
  const dutyRosterContext = useDutyRosterContext()
  const { draggedMembershipId } = useAppSelector(selectDragInfo)
  const isLoading = useAppSelector((state) => state.shifts.status === 'pending')
  const shifts = useAppSelector(selectAllShifts)
  const isMemberOnVacation = !!useAppSelector((state) =>
    selectVacationsByDateAndMembership(
      state,
      format(props.date, 'yyyy-MM-dd'),
      draggedMembershipId
    )
  )

  const filteredMemberships = useAppSelector((state) =>
    selectMembershipsByEmploymentType(state, dutyRosterContext?.dutyRoster?.employmentType)
  )
  const dayTimeOffs = useAppSelector((state) =>
    selectOffDutiesByDate(state, props.date)
  ).filter((offDuty: IOffDuty) => {
    return filteredMemberships.find((member: IMembership) => {
      return offDuty.membershipId === member.id
    })
  })

  const schoolHolidays = useAppSelector((state) =>
    selectSchoolHolidaysByDate(state, props.date)
  )

  const isDayAtWeekend = useMemo(() => {
    return isWeekend(props.date)
  }, [props.date])

  const isDayOfADifferentMonth = useMemo(() => {
    return !isSameMonth(props.date, props.firstDayOfMonth)
  }, [props.date, props.firstDayOfMonth])

  const filteredShifts = useMemo(() => {
    if (!dutyRosterContext.dutyRoster) return []
    const dutyRosterShiftIds = dutyRosterContext.dutyRoster.shifts || []
    const dayIndex = getDay(props.date)
    return shifts
      .filter((shift: IShift) => {
        return (
          dutyRosterShiftIds.includes(shift.id) &&
          shift.validOn.includes(dayIndex)
        )
      })
      .sort((a, b) => {
        return a.from.localeCompare(b.from)
      })
  }, [dutyRosterContext.dutyRoster, props.date, shifts])

  const holiday = HolidayRepository.isHoliday(props.date)
  const nameOfPublicHoliday =
    Array.isArray(holiday) && holiday[0].type === 'public'
      ? holiday[0].name
      : ''
  const schoolHoliday = schoolHolidays[0]
  const hasMemberRequestedTimeOff = !!dayTimeOffs.find(
    (timeOff) => timeOff.membershipId === draggedMembershipId
  )

  return (
    <ShiftsPerDayContext.Provider value={filteredShifts}>
      <DayPresentational
        dayTimeOffs={dayTimeOffs}
        schoolHolidayName={schoolHoliday?.name}
        date={props.date}
        shifts={filteredShifts}
        isMemberOnVacation={isMemberOnVacation}
        hasMemberRequestedTimeOff={hasMemberRequestedTimeOff}
        isDayAtWeekend={isDayAtWeekend}
        isDayOfADifferentMonth={isDayOfADifferentMonth}
        isLoading={isLoading}
        nameOfPublicHoliday={nameOfPublicHoliday}
      />
    </ShiftsPerDayContext.Provider>
  )
}

export default Day
