import {
  createSlice,
  createEntityAdapter,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { createAsyncThunk } from '@reduxjs/toolkit'
import config from '../../app/config'
import { authorizedFetch } from '../../common/lib/fetch'
import { format, parse } from 'date-fns'
import { message } from 'antd'
import { replaceBaseUrl } from '../../common/lib/util/urlHelper'
import {i18n} from "../../common/lib/i18n"

export interface IVacationLog {
  id: string
  employee: string
  date: string
  additionalVacationDays: number
  approvedDays: number
  unapprovedDays: number
  carryOverPreviousMonth: number
  carryOverNextMonth: number
  vacationDaysPerYear: number
  canRemove: boolean
  canUpdate: boolean
}

interface FetchByDateParams {
  date: Date
  url?: URL
}

export const fetchByDate = createAsyncThunk(
  'vacationLogs/fetchByDate',
  async (params: FetchByDateParams, thunkApi) => {
    try {
      let url = params?.url
      const baseUrl = `${config.backend.url}/api/off_duty_log/vacation_log`
      if (!url) {
        url = new URL(baseUrl)
        const filters = {
          'fields[off_duty_log--vacation_log]': 'employee,date,additional_vacation,approved,unapproved,carry_over,carry_over_next_month,entitlement',
          'filter[date]': format(params.date, 'yyyy-MM-dd'),
        }
        url.search = new URLSearchParams(filters).toString()
      }
      const response = await authorizedFetch(url)

      if (response.status !== 200) {
        return thunkApi.rejectWithValue(response.statusText)
      }
      const json = await response.json()
      if ('next' in json.links) {
        // Jsonapi generates wrong urls
        url.href = replaceBaseUrl(baseUrl, json.links.next.href)
        thunkApi.dispatch(
          await fetchByDate({
            ...params,
            url: url,
          })
        )
      }
      return mapVacationLogArray(json.data)
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

interface FetchByRangeAndEmployeeParams {
  employeeId: string,
  startDate: Date,
  endDate: Date,
  url?: any
}

export const fetchByRangeAndEmployee = createAsyncThunk(
  'vacationLogs/fetchByRangeAndEmployee',
  async (params: FetchByRangeAndEmployeeParams, thunkApi) => {
    try {
      let url = params?.url
      const baseUrl = `${config.backend.url}/api/off_duty_log/vacation_log`
      if (!url) {
        url = new URL(baseUrl)
        const filters = {
          'fields[off_duty_log--vacation_log]': 'employee,date,additional_vacation,approved,unapproved,carry_over,carry_over_next_month,entitlement',
          'filter[a-Min-Filter][condition][path]': 'date',
          'filter[a-Min-Filter][condition][operator]': '>=',
          'filter[a-Min-Filter][condition][value]': format(
            params.startDate,
            'yyyy-MM-dd'
          ),
          'filter[a-Max-Filter][condition][path]': 'date',
          'filter[a-Max-Filter][condition][operator]': '<=',
          'filter[a-Max-Filter][condition][value]': format(
            params.endDate,
            'yyyy-MM-dd'
          ),
          'filter[employee.id]': params.employeeId,
        }
        url.search = new URLSearchParams(filters).toString()
      }
      const response = await authorizedFetch(url)

      if (response.status !== 200) {
        return thunkApi.rejectWithValue(response.statusText)
      }
      const json = await response.json()
      if ('next' in json.links) {
        // Jsonapi generates wrong urls
        url.href = replaceBaseUrl(baseUrl, json.links.next.href)
        thunkApi.dispatch(
          await fetchByRangeAndEmployee({
            ...params,
            url: url,
          })
        )
      }
      return mapVacationLogArray(json.data)
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const updateAdditionalDays = createAsyncThunk<void, any>(
  'vacationLogs/updateAdditionalDays',
  async ({ id, days }, thunkApi) => {
    const data = {
      data: {
        type: 'off_duty_log--vacation_log',
        id: id,
        attributes: {
          additional_vacation: days,
        },
      },
    }

    const url = `${config.backend.url}/api/off_duty_log/vacation_log/${id}`
    let response
    try {
      response = await authorizedFetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
      })

      if (response.status !== 200) {
        if (response.status === 422) {
          const violationData = await response.json()
          const errors = violationData.errors
          const code = errors[0].detail.split(': ')[1]
          message.error(i18n.t('validation-violation-' + code))
        } else {
          message.error(
            'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
          )
        }
        return thunkApi.rejectWithValue(response.statusText)
      }

      const json: any = await response.json()
      const updatedVacationLog = mapVacationLog(json.data)
      thunkApi.dispatch(slice.actions.updatedAdditionalDays(updatedVacationLog))
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export function mapVacationLog(jsonApiEntity: any) {
  return {
    id: jsonApiEntity.id,
    employee: jsonApiEntity.relationships.employee.data.id,
    date: jsonApiEntity.attributes.date,
    additionalVacationDays: jsonApiEntity.attributes.additional_vacation,
    approvedDays: jsonApiEntity.attributes.approved,
    unapprovedDays: jsonApiEntity.attributes.unapproved,
    carryOverPreviousMonth: jsonApiEntity.attributes.carry_over,
    carryOverNextMonth: jsonApiEntity.attributes.carry_over_next_month,
    vacationDaysPerYear: jsonApiEntity.attributes.entitlement,
    canRemove: jsonApiEntity.links?.mutableControls
      ? jsonApiEntity.links.mutableControls.meta.linkParams.rel.indexOf(
          'remove'
        ) !== -1
      : false,
    canUpdate: jsonApiEntity.links?.mutableControls
      ? jsonApiEntity.links.mutableControls.meta.linkParams.rel.indexOf(
          'update'
        ) !== -1
      : false,
  }
}

export function mapVacationLogArray(jsonApiResponse: any) {
  if (jsonApiResponse.length === 0) return []

  return jsonApiResponse.map((jsonApiEntity: any) =>
    mapVacationLog(jsonApiEntity)
  )
}

export const entityAdapter = createEntityAdapter<IVacationLog>()

export const slice = createSlice({
  name: 'vacationLogs',
  initialState: entityAdapter.getInitialState({
    status: 'idle',
    error: null,
  }),
  reducers: {
    updatedAdditionalDays: (state, action: PayloadAction<IVacationLog>) => {
      const vacationLog = action.payload
      entityAdapter.updateOne(state, {
        id: vacationLog.id,
        changes: {
          additionalVacationDays: vacationLog.additionalVacationDays,
          carryOverNextMonth: vacationLog.carryOverNextMonth,
          unapprovedDays: vacationLog.unapprovedDays,
        },
      })
    },
  },
  extraReducers: {
    [fetchByDate.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded'
      entityAdapter.upsertMany(state, action.payload)
    },
    [fetchByRangeAndEmployee.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded'
      entityAdapter.upsertMany(state, action.payload)
    },
  },
})

export default slice.reducer

export const {
  selectAll: selectAllVacationLogs,
  selectById: selectVacationLogById,
} = entityAdapter.getSelectors<RootState>((state) => state.vacationLogs)

export const selectVacationLogsByDate = createSelector(
  [selectAllVacationLogs, (state: RootState, date: Date | undefined) => date],
  (vacationLogs, date) =>
    vacationLogs.filter((vacationLog) => {
      if (!vacationLog) return false
      if (!date) return false
      return vacationLog.date === format(date, 'yyyy-MM-dd')
    })
)

const getYear = (state: RootState, year: string): string => year
const getEmployeeId = (
  state: RootState,
  year: string,
  employeeId: string
): string => employeeId

export const selectVacationLogsByYearAndEmployee = createSelector(
  [selectAllVacationLogs, getYear, getEmployeeId],
  (vacationLogs, year, employeeId) => {
    return vacationLogs.filter((vacationLog) => {
      const logDate = parse(vacationLog.date, 'yyyy-MM-dd', new Date())
      const logYear = format(logDate, 'yyyy')
      if (!vacationLog) return false
      if (!year) return false
      if (!employeeId) return false
      return year === logYear && vacationLog.employee === employeeId
    })
  }
)

export const selectVacationLogsByYearRangeAndEmployee = createSelector(
  [
    selectAllVacationLogs,
    (state: RootState, fromYear: number, toYear: number, employeeId: string): number => fromYear,
    (state: RootState, fromYear: number, toYear: number, employeeId: string): number => toYear,
    (state: RootState, fromYear: number, toYear: number, employeeId: string): string => employeeId
  ],
  (vacationLogs, fromYear, toYear, employeeId) => {
    return vacationLogs.filter((vacationLog) => {
      const logDate = parse(vacationLog.date, 'yyyy-MM-dd', new Date())
      const logYear = logDate.getFullYear()
      if (!vacationLog) return false
      if (!fromYear || !toYear) return false
      if (!employeeId) return false
      return (logYear <= toYear && logYear >= fromYear) && vacationLog.employee === employeeId
    })
  }
)
