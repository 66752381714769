import { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  fetchDutyRosters,
  selectAllDutyRosters,
  selectDutyRosterStatus,
} from '../../features/duty-rosters/dutyRostersSlice'
import DutyRosters from './DutyRosters'
import { selectCurrentUser } from '../../features/currentUser/currentUserSlice'
import { EEmploymentType } from '../../features/memberships/employmentType'

export default function ConnectedDutyRosters(props: RouteComponentProps) {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(selectCurrentUser)

  // set the employmenttype when currentuserinfo is loaded.
  useEffect(() => {
    if (currentUser?.employmentType)
      setEmploymentType(currentUser.employmentType)
  }, [currentUser])

  const dataSource = useAppSelector(selectAllDutyRosters)
  const requestStatus = useAppSelector(selectDutyRosterStatus)
  const [employmentType, setEmploymentType] = useState(EEmploymentType.Nurse)

  // Filter the dutyrosters according to table option
  const filteredDutyRosters = dataSource.filter((dutyRoster) => {
    return dutyRoster.employmentType === employmentType
  })

  useEffect(() => {
    dispatch(fetchDutyRosters())
  }, [dispatch])

  return (
    <DutyRosters
      dataSource={filteredDutyRosters}
      isPending={requestStatus === 'pending'}
      history={props.history}
      employmentType={employmentType}
      handleEmploymentTypeChanged={setEmploymentType}
    />
  )
}
