import { IWorkAssignment } from '../../../../../features/workAssignments/workAssignmentsSlice'
import Avatar from '../../../Avatar'
import styles from './WorkAssignmentSwapEntity.module.css'
import Text from 'antd/lib/typography/Text'
import { useAppSelector } from '../../../../../app/hooks'
import { selectMembershipById } from '../../../../../features/memberships/membershipsSlice'
import { format, parse } from 'date-fns'
import deLocale from 'date-fns/locale/de'
import { selectShiftById } from '../../../../../features/shifts/shiftsSlice'

interface IProps {
  workAssignment: IWorkAssignment
}

const WorkAssignmentSwapEntity = (props: IProps) => {
  const employee = useAppSelector(state => selectMembershipById(state, props.workAssignment.membershipId))
  const shift = useAppSelector(state => selectShiftById(state, props.workAssignment.shiftId))

  if(!employee || !shift) return null

  return (
    <div className={styles.root}>
      <div className={styles.userWrapper}>
        <Avatar membershipId={props.workAssignment.membershipId} className={styles.avatar}/>
        <Text strong>{`${employee.firstName} ${employee.lastName}`}</Text>
      </div>
      <Text className={styles.date}>{format(parse(props.workAssignment.date, 'yyyy-MM-dd', new Date()), 'dd. MMM. yyyy', {locale: deLocale})}</Text>
      <Text>{shift.longName}</Text>
    </div>
  )
}

export default WorkAssignmentSwapEntity
