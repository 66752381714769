import { eachDayOfInterval } from 'date-fns'
import React, { ForwardedRef, memo } from 'react'
import HolidayRepository from '../../../../../../common/lib/util/holidays'
import Day from '../DayLine'
import styles from './DayLines.module.css'

interface Props {
  year: number
}

const DayLines = (props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div className={styles.root} ref={ref}>
      {eachDayOfInterval({
        start: new Date(props.year, 0, 1),
        end: new Date(props.year, 11, 31),
      }).map((day, index) => {
        const holiday = HolidayRepository.isHoliday(day)
        const nameOfPublicHoliday =
          Array.isArray(holiday) && holiday[0].type === 'public'
            ? holiday[0].name
            : ''

        return (
          <Day
            key={index}
            day={day}
            isPublicHoliday={!!nameOfPublicHoliday}
          />
        )
      })}
    </div>
  )
}

export default memo(React.forwardRef<HTMLDivElement, Props>(DayLines))
