import { startOfYear, endOfYear, parse } from 'date-fns'
import locale from 'date-fns/esm/locale/de'

export function limitDateByYear(date: Date, year: number): Date {
  const _date = parse(`${year}-01-01`, 'yyyy-MM-dd', new Date())
  const _startOfYear = startOfYear(_date)
  if (date < _startOfYear) {
    return _startOfYear
  }
  const _endOfYear = endOfYear(_date)
  if (date > _endOfYear) return _endOfYear

  return date
}

export function getDayShortNames(weekDays: number[]) {
  //@ts-ignore object being possibly undefined
  const days = [...Array(7).keys()].map(i => locale.localize.day(i, { width: 'short' }))
  return days.filter((day, index) => weekDays.includes(index));
}
