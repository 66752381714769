export const i18n = {
  t: (code: string) => {
    return strings[code]
  },
}

interface IStringRepository {
  [key: string]: any
}

const strings: IStringRepository = {
  'validation-violation-overlapping-offduties': 'Der Mitarbeiter hat bereits Urlaub/Dienstfrei in diesem Zeitraum',
  'validation-violation-invalid-employee': 'Sie dürfen diesen Beitrag nicht bearbeiten',
  'validation-violation-invalid-employment': 'Sie dürfen diesen Beitrag nicht bearbeiten',
  'validation-violation-year-limit-exceeded': 'Diese Aktion überschreitet das Jahreslimit von 4 Zusatzurlaubstagen',
  'validation-violation-assignment-shift-overlapping': 'Der Nutzer wurde an diesem Tag bereits in eine zeitlich überlappende Schicht eingetragen.',
  'validation-violation-assignment-institution-fail' : 'Für diese Institution kann kein Dienst hinzugefügt werden',
  'validation-violation-assignment-date-range-fail' : 'Dieser Zeitraum kann nicht gespeichert werden',
  'validation-violation-assignment-employee-fail' : 'Dieser Mitarbeiter kann nicht gespeichert werden',
  'validation-violation-assignment-employee-forbidden' : 'Sie dürfen keinen Dienst für diesen Bereich bearbeiten',
  'validation-violation-assignment-value-change' : 'Dieser Wert darf nicht mehr geändert werden',
  'validation-violation-assignment-approved-fail' : 'Der Dienst eines bestätigten Dienstplans darf nicht mehr geändert werden',
  'validation-violation-assignment-user-blocked' : 'Dieser Nutzer ist geblockt',
  'validation-violation-work-assigned' : 'Der Nutzer ist während dieser Zeit einem Dienst zugeteilt.',
  'validation-violation-assignment-roster-fail' : 'Der Dienstplan konnte nicht geladen werden. Bitte laden Sie die Seite neu.',
}

