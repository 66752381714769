import clx from 'classnames'
import { format, isToday } from 'date-fns'
import locale from 'date-fns/locale/de'
import { memo } from 'react'
import styles from './MonthHeaderDayName.module.css'

interface Props {
  day: Date
}
const MonthHeaderDayName = (props: Props) => {
  const rootClasses = clx({
    [styles.root]: true,
    [styles.isToday]: isToday(props.day),
  })

  return (
    <span className={rootClasses}>
      {format(props.day, 'EEEEEE', { locale })}
    </span>
  )
}

export default memo(MonthHeaderDayName)
