import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { createAsyncThunk } from '@reduxjs/toolkit'
import config from '../../app/config'
import { authorizedFetch } from '../../common/lib/fetch'
import {replaceBaseUrl} from "../../common/lib/util/urlHelper";

export interface IWorkLog {
  id: string
  employee: string
  date: string
  contractualWorkingHours: number
  numberWeekDays: number
  setWorkingTime: number
  maxAvailableHolidays: number
  currentWorkingTime: number
  carryOver: number
  carryOverNextMonth: number
  dutyRoster: string
  deviatingHours: number
  plannedTime: number
}

interface FetchByDutyRosterParams {
  dutyRosterId: string
  url?: any
}

export const fetchByDutyRoster = createAsyncThunk(
  'workLogs/fetchByDutyRoster',
  async (params: FetchByDutyRosterParams, thunkApi) => {
    try {
      let url = params?.url
      const baseUrl = `${config.backend.url}/api/work_log/medical_facility_work`
      if (!url) {
        url = new URL(baseUrl)
        const filters = {
          'filter[duty_roster.id]': params.dutyRosterId,
          'fields[work_log--medical_facility_work]': 'employee,date,duty_roster,contractual_week_days,contractual_working_hours,holiday_entitlement,current_work_time,carry_over,carry_over_next_month,deviating_hours,planned_time,set_work_time',
        }
        url.search = new URLSearchParams(filters).toString()
      }
      const response = await authorizedFetch(url)

      if (response.status !== 200) {
        return thunkApi.rejectWithValue(response.statusText)
      }
      const json = await response.json()
      if ('next' in json.links) {
        // Jsonapi generates wrong urls
        url.href = replaceBaseUrl(baseUrl, json.links.next.href)
        thunkApi.dispatch(
          await fetchByDutyRoster({
            ...params,
            url: url,
          })
        )
      }
      return mapWorkLogArray(json.data)
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

interface FetchFollowingWorkLogsParams {
  employeeId: string
  date: string
  url?: any
}

export const fetchFollowingWorkLogs = createAsyncThunk(
  'workLog/fetchFollowingWorkLogs',
  async (params: FetchFollowingWorkLogsParams, thunkApi) => {
    try {
      let url = params.url
      const baseUrl = `${config.backend.url}/api/work_log/medical_facility_work`
      if (!url) {
        url = new URL(baseUrl)
        const filters = {
          'filter[dateMinFilter][condition][path]': 'date',
          'filter[dateMinFilter][condition][operator]': '>=',
          'filter[dateMinFilter][condition][value]': params.date,
          'filter[employee.id]': params.employeeId,
          'fields[work_log--medical_facility_work]': 'employee,date,duty_roster,contractual_week_days,contractual_working_hours,holiday_entitlement,current_work_time,carry_over,carry_over_next_month,deviating_hours,planned_time,set_work_time'
      }
        url.search = new URLSearchParams(filters).toString()
      }

      const response: any = await authorizedFetch(url)
      if (response.status !== 200) {
        return thunkApi.rejectWithValue(response.statusText)
      }
      const json = await response.json()
      if ('next' in json.links) {
        // Jsonapi generates wrong urls
        url.href = replaceBaseUrl(baseUrl, json.links.next.href)
        thunkApi.dispatch(
          await fetchFollowingWorkLogs({
            ...params,
            url: url,
          })
        )
      }
      return mapWorkLogArray(json.data)
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const fetchById = createAsyncThunk(
  'workLog/fetchById',
  async (id: string, thunkApi) => {
    try {
      const response: any = await authorizedFetch(
        `${config.backend.url}/api/work_log/medical_facility_work/${id}` +
          `?fields[work_log--medical_facility_work]=employee,date,contractual_week_days,duty_roster,contractual_working_hours,holiday_entitlement,current_work_time,carry_over,carry_over_next_month,deviating_hours,planned_time,set_work_time`
      )

      if (response.status !== 200) {
        return thunkApi.rejectWithValue(response.statusText)
      }
      const json = await response.json()
      return mapWorkLog(json.data)
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export function mapWorkLog(jsonApiEntity: any) {
  return {
    id: jsonApiEntity.id,
    employee: jsonApiEntity.relationships.employee.data.id,
    date: jsonApiEntity.attributes.date,
    dutyRoster: jsonApiEntity.relationships.duty_roster.data.id,
    contractualWorkingHours: jsonApiEntity.attributes.contractual_working_hours,
    numberWeekDays: jsonApiEntity.attributes.contractual_week_days,
    maxAvailableHolidays: jsonApiEntity.attributes.holiday_entitlement,
    currentWorkingTime: jsonApiEntity.attributes.current_work_time,
    carryOver: jsonApiEntity.attributes.carry_over,
    carryOverNextMonth: jsonApiEntity.attributes.carry_over_next_month,
    deviatingHours: jsonApiEntity.attributes.deviating_hours,
    plannedTime: jsonApiEntity.attributes.planned_time,
    setWorkingTime: jsonApiEntity.attributes.set_work_time,
  }
}

export function mapWorkLogArray(jsonApiResponse: any) {
  if (jsonApiResponse.length === 0) return []

  return jsonApiResponse.map((jsonApiEntity: any) => mapWorkLog(jsonApiEntity))
}

export const entityAdapter = createEntityAdapter<IWorkLog>()

export const slice = createSlice({
  name: 'workLogs',
  initialState: entityAdapter.getInitialState({
    status: 'idle',
    error: null,
  }),
  reducers: {},
  extraReducers: {
    [fetchByDutyRoster.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded'
      entityAdapter.upsertMany(state, action.payload)
    },
    [fetchByDutyRoster.pending.toString()]: (state) => {
      state.status = 'pending'
    },
    [fetchById.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded'
      entityAdapter.upsertOne(state, action.payload)
    },
    [fetchById.pending.toString()]: (state, action) => {
      state.status = 'pending'
    },
    [fetchFollowingWorkLogs.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded'
      entityAdapter.upsertMany(state, action.payload)
    },
    [fetchFollowingWorkLogs.pending.toString()]: (state) => {
      state.status = 'pending'
    },
  },
})

export default slice.reducer

export const { selectAll: selectAllWorkLogs, selectById: selectWorkLogById } =
  entityAdapter.getSelectors<RootState>((state) => state.workLogs)

export const selectWorkLogsByDutyRoster = createSelector(
  [
    selectAllWorkLogs,
    (state: RootState, dutyRosterId: string | undefined) => dutyRosterId,
  ],
  (workLogs, dutyRosterId) =>
    workLogs.filter((workLog) => {
      if (!workLog) return false
      if (!dutyRosterId) return false
      return workLog.dutyRoster === dutyRosterId
    })
)
