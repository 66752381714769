import {
  CheckOutlined,
  CloseOutlined,
  FormatPainterFilled,
  FormatPainterOutlined,
  LockOutlined,
  QuestionCircleFilled,
  QuestionCircleTwoTone
} from '@ant-design/icons'
import { Button, PageHeader, Popconfirm, Space, Table, Tooltip } from 'antd'
import Radio from 'antd/lib/radio'
import { ColumnsType } from 'antd/lib/table/interface'
import { format } from 'date-fns'
import locale from 'date-fns/locale/de'
import { History } from 'history'
import { memo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import CreateDutyRosterFormPresentational, {
  ICreateDutyRosterFormValues,
} from '../../common/components/CreateDutyRosterForm/CreateDutyRosterForm'
import CreateDutyRosterModal from '../../common/components/CreateDutyRosterModal/CreateDutyRosterModal'
import {
  selectCurrentUser,
  selectCurrentUserCreatePermissions,
} from '../../features/currentUser/currentUserSlice'
import {
  approve,
  create,
  disapprove,
  IDutyRoster,
  makeTemplate,
  remove,
  removeTemplate,
} from '../../features/duty-rosters/dutyRostersSlice'
import { EEmploymentType } from '../../features/memberships/employmentType'
import DeleteButton from './components/DeleteButton/DeleteButton'
import styles from './DutyRosters.module.css'
import DutyRosterReviewModal
  from '../../common/components/DutyRosterReviewModal/DutyRosterReviewModal'

interface IProps {
  dataSource: IDutyRoster[]
  isPending: boolean
  history: History
  employmentType: EEmploymentType
  handleEmploymentTypeChanged: (value: EEmploymentType) => void
}

function DutyRosters(props: IProps) {
  const dispatch = useAppDispatch()
  const [visible, setVisible] = useState(false)
  const [reviewModalVisible, setReviewModalVisible] = useState<boolean>(false)
  const [reviewDutyRosterId, setReviewDutyRosterId] = useState<string | undefined>(undefined)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const formRef = useRef<CreateDutyRosterFormPresentational>(null)

  const showModal = () => {
    setVisible(true)
  }

  const showReviewModal = () => {
    setReviewModalVisible(true)
  }

  const handleCloseReview = () => {
    setReviewModalVisible(false)
  }

  const handleOk = () => {
    formRef.current?.submit()
  }

  const handleFinish = async (values: ICreateDutyRosterFormValues) => {
    setConfirmLoading(true)
    await dispatch(
      create({
        history: props.history,
        templateId: values.templateId,
        period: values.period,
      })
    )
    setConfirmLoading(false)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const onDelete = (id: string) => () => {
    dispatch(remove(id))
  }

  const currentUser: any = useAppSelector(selectCurrentUser)
  const createPermissions: any = useAppSelector(
    selectCurrentUserCreatePermissions
  )
  const canCreateRoster =
    (createPermissions &&
      createPermissions['duty_roster'] &&
      currentUser.employmentType === props.employmentType) ||
    currentUser.roles?.includes('administrator')

  const columns: ColumnsType<IDutyRoster> = [
    {
      title: 'Datum',
      key: 'name',
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        return a.year * 12 + a.month - (b.year * 12 + b.month)
      },
      render: (record: IDutyRoster) => {
        const date = new Date(record.year, record.month, 1)
        return (
          <Link to={`/duty-roster/${record.id}`}>
            {format(date, 'MMMM yyyy', { locale })}
          </Link>
        )
      },
    },
    {
      title: 'Praxis',
      dataIndex: 'institutionName',
      key: 'institutionName',
      sorter: (a, b) => {
        return a.institutionName! < b.institutionName! ? -1 : 1
      },
    },
    {
      title: 'Vorlage',
      dataIndex: 'isTemplate',
      key: 'template',
      render: (value: boolean) => {
        return value ? (
          <CheckOutlined className={styles.checked} />
        ) : (
          <CloseOutlined className={styles.unchecked} />
        )
      },
    },
    {
      title: 'Final bestätigt',
      dataIndex: 'isApproved',
      key: 'approved',
      render: (value: boolean) => {
        return value ? (
          <>
            <CheckOutlined className={styles.checked} />
            <LockOutlined />
          </>
        ) : (
          <CloseOutlined className={styles.unchecked} />
        )
      },
    },
    {
      title: 'Aktionen',
      key: 'action',
      render: (record: IDutyRoster) => (
        <Space>
          {record.isTemplate && (
            <Popconfirm
              placement="left"
              icon={<QuestionCircleFilled />}
              title="Soll dieser Dienstplan nicht mehr als Vorlage verwenden werden?"
              onConfirm={() => dispatch(removeTemplate(record.id))}
              okText="Ja"
              okButtonProps={{ danger: true }}
              cancelText="Nein"
              disabled={!record.canUpdate || !record.isTemplate}
            >
              <Tooltip title="Diesen Dienstplan nicht mehr als Vorlage verwenden">
                <Button
                  icon={<FormatPainterFilled />}
                  shape={'circle'}
                  disabled={!record.canUpdate}
                />
              </Tooltip>
            </Popconfirm>
          )}
          {!record.isTemplate && (
            <Popconfirm
              placement="left"
              icon={<QuestionCircleFilled />}
              title="Soll dieser Dienstplan als Vorlage verwenden werden?"
              onConfirm={() => dispatch(makeTemplate(record.id))}
              okText="Ja"
              okButtonProps={{ danger: true }}
              cancelText="Nein"
              disabled={!record.canUpdate || record.isTemplate}
            >
              <Tooltip title="Diesen Dienstplan als Vorlage verwenden">
                <Button
                  icon={<FormatPainterOutlined />}
                  shape={'circle'}
                  disabled={!record.canUpdate}
                />
              </Tooltip>
            </Popconfirm>
          )}
          {record.isApproved && (
            <Popconfirm
              placement="left"
              icon={<QuestionCircleFilled />}
              title="Soll die Bestätigung des Dienstplans rückgängig gemacht werden?"
              onConfirm={() => dispatch(disapprove(record.id))}
              okText="Ja"
              okButtonProps={{ danger: true }}
              cancelText="Nein"
              disabled={!record.canUpdate || !record.isApproved}
            >
              <Tooltip title="Die Bestätigung dieses Dienstplans zurückziehen">
                <Button
                  icon={<CloseOutlined />}
                  shape={'circle'}
                  disabled={!record.canUpdate}
                />
              </Tooltip>
            </Popconfirm>
          )}
          {!record.isApproved && (
            <Popconfirm
              placement="left"
              icon={<QuestionCircleFilled />}
              title="Soll dieser Dienstplan wirklich bestätigt und somit für die weitere Bearbeitung gesperrt werden?"
              onConfirm={() => dispatch(approve(record.id))}
              okText="Ja, bestätigen"
              okButtonProps={{ danger: true }}
              cancelText="Nein"
              disabled={!record.canUpdate}
            >
              <Tooltip title="Diesen Dienstplan bestätigen">
                <Button
                  icon={<CheckOutlined />}
                  shape={'circle'}
                  disabled={!record.canUpdate}
                />
              </Tooltip>
            </Popconfirm>
          )}
          <Tooltip title="Richtigkeit prüfen">
            <Button
              onClick={() => {
                setReviewDutyRosterId(record.id)
                showReviewModal()
              }}
              icon={<QuestionCircleTwoTone twoToneColor= '#52c41a'/>}
              shape={'circle'}
            />
          </Tooltip>
          <DeleteButton
            canRemove={record.canRemove}
            isApproved={record.isApproved}
            onDelete={onDelete(record.id)}
          />
        </Space>
      ),
    },
  ]

  const tableOptions = [
    { label: 'Ärztliches Personal', value: EEmploymentType.Doctor },
    { label: 'Pflegepersonal', value: EEmploymentType.Nurse },
  ]

  return (
    <div className={styles.root}>
      <PageHeader
        className={styles.pageHeader}
        title="Dienstpläne"
        extra={[
          <Button
            onClick={showModal}
            type="primary"
            key="1"
            disabled={!canCreateRoster}
          >
            Dienstplan hinzufügen
          </Button>,
        ]}
      />
      <div className={styles.optionsHeader}>
        <Tooltip title={'Typ auswählen'}>
          <Radio.Group
            options={tableOptions}
            onChange={(e) => {
              props.handleEmploymentTypeChanged(e.target.value)
            }}
            value={props.employmentType}
            optionType="button"
            buttonStyle={'solid'}
          />
        </Tooltip>
      </div>
      <Table<IDutyRoster>
        loading={props.isPending}
        rowKey="id"
        dataSource={props.dataSource}
        columns={columns}
      />
      <CreateDutyRosterModal
        formRef={formRef}
        visible={visible}
        handleOk={handleOk}
        handleFinish={handleFinish}
        confirmLoading={confirmLoading}
        handleCancel={handleCancel}
        employmentType={props.employmentType}
      />
      {reviewModalVisible && reviewDutyRosterId && (
        <DutyRosterReviewModal
          visible={reviewModalVisible}
          handleClose={handleCloseReview}
          dutyRosterId={reviewDutyRosterId}
        />
      )}
    </div>
  )
}

export default memo(DutyRosters)
