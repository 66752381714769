import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import config from '../../app/config'
import { fetchXsrfToken } from '../../common/lib/fetch'

export interface ReplaceAvatarActionParams {
  options: any
  membershipId: string
  userId: string
}
export const replaceAvatar = createAsyncThunk<any, ReplaceAvatarActionParams>(
  'currentUser/replaceAvatar',
  async (params, thunkApi) => {
    const { file, onProgress } = params.options

    const formData = new FormData()
    formData.append('avatar', file)

    const state: any = thunkApi.getState()

    try {
      const csrfToken = await fetchXsrfToken()

      const _config: any = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${state.authentication.accessToken}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        onUploadProgress: (event: any) => {
          onProgress({ percent: (event.loaded / event.total) * 100 })
        },
      }

      const res = await axios.post(
        `${config.backend.url}/update-avatar/${params.userId}`,
        formData,
        _config
      )

      if (res.status === 200) {
        return {
          avatar: `${config.backend.url}${res.data.url}`,
          membershipId: params.membershipId,
        }
      } else {
        console.error(`code: ${res.status} text: ${res.statusText}`)
        return thunkApi.rejectWithValue('error.unknown')
      }
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
