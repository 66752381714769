import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { Dropdown, Menu, Popconfirm, Popover } from 'antd'
import {format, getDayOfYear, isSameMonth, parseISO} from 'date-fns'
import locale from 'date-fns/locale/de'
import {useCallback, useContext, useMemo, useState} from 'react'
import { useAppDispatch } from '../../../../../../app/hooks'
import {
  disapprove,
  EOffDutyType,
  IOffDuty,
  remove,
} from '../../../../../../features/off-duty/offDutySlice'
import { OffDutiesContext } from '../../../../OffDutiesContext'
import { DAY_WIDTH, RENDER_LANE_HEIGHT } from '../../CalendarView'
import OffDutyPopover from '../OffDutyPopover/OffDutyPopover'
import styles from './OffDuty.module.css'

interface Props {
  offDuty: IOffDuty
  yPosIndex: number
  doesOverlap: boolean
  doesOverlapWithApproved: boolean
}

const OffDuty = (props: Props) => {
  const dispatch = useAppDispatch()
  const context = useContext(OffDutiesContext)

  const calendarYear = context.date.getFullYear()

  const calendarCoordinates = useMemo(() => {
    const startDate = parseISO(props.offDuty.from)
    const endDate = parseISO(props.offDuty.to)

    const displayStart = startDate.getFullYear() === calendarYear
      ? startDate
      : new Date(calendarYear, 0, 1)
    const displayEnd = endDate.getFullYear() === calendarYear
      ? endDate
      : new Date(calendarYear, 11, 31)

    return {
      start: getDayOfYear(displayStart),
      end: getDayOfYear(displayEnd)
    }
  }, [props.offDuty, calendarYear])

  const width = useMemo(() => {
    const numberOfDays = calendarCoordinates.end - calendarCoordinates.start + 1
    return DAY_WIDTH * numberOfDays
  }, [calendarCoordinates])

  const xPos = DAY_WIDTH * (calendarCoordinates.start - 1)

  const [isOpen, setOpen] = useState(false)
  const [timer, setTimer] = useState<number>()

  const getOffDutyDuration = () => {
    const to = parseISO(props.offDuty.to)
    const from = parseISO(props.offDuty.from)
    if (isSameMonth(to, from)) {
      return `${format(from, 'd.', { locale })} - ${format(to, 'd. MMMM', {
        locale,
      })}`
    } else {
      return `${format(from, 'd. MMMM', { locale })} - ${format(to, 'd. MMMM', {
        locale,
      })}`
    }
  }

  const handleClick = useCallback(
    (e) => {
      switch (e.key) {
        case 'edit-vacation':
          context.showVacationForm(props.offDuty)
          break

        case 'edit-timeoff':
          context.showTimeOffForm(props.offDuty)
          break

        case 'approve':
          context.showApprovalForm(props.offDuty)
          break

        case 'disapprove':
          dispatch(disapprove(props.offDuty.id))
          break

        default:
          return
      }
    },
    [context, dispatch, props.offDuty]
  )

  const menu = (
    <Menu
      onClick={handleClick}
      onMouseEnter={() => clearTimeout(timer)}
      onMouseLeave={() => setOpen(false)}
    >
      {props.offDuty.type === EOffDutyType.Vacation && (
        <Menu.Item
          key="edit-vacation"
          icon={<EditOutlined />}
          disabled={!props.offDuty.canUpdate}
        >
          Editieren
        </Menu.Item>
      )}
      {props.offDuty.type === EOffDutyType.TimeOff && (
        <Menu.Item
          key="edit-timeoff"
          icon={<EditOutlined />}
          disabled={!props.offDuty.canUpdate}
        >
          Editieren
        </Menu.Item>
      )}
      {props.offDuty.type === EOffDutyType.Vacation &&
        !props.offDuty.isApproved && (
          <Menu.Item
            key="approve"
            icon={<CheckOutlined />}
            disabled={!props.offDuty.canApprove || props.doesOverlap}
          >
            Genehmigen
          </Menu.Item>
        )}
      {props.offDuty.type === EOffDutyType.Vacation &&
        props.offDuty.isApproved && (
          <Menu.Item
            key="disapprove"
            icon={<CloseOutlined />}
            disabled={!props.offDuty.canApprove}
          >
            Genehmigung zurückziehen
          </Menu.Item>
        )}
      <Popconfirm
        title="Soll dieser Eintrag wirklich gelöscht weden?"
        onConfirm={() => dispatch(remove(props.offDuty.id))}
        okText="Ja, löschen"
        okButtonProps={{ danger: true }}
        cancelText="Nein"
        disabled={!props.offDuty.canRemove}
      >
        <Menu.Item
          key="remove"
          danger
          icon={<DeleteOutlined />}
          disabled={!props.offDuty.canRemove}
        >
          Löschen
        </Menu.Item>
      </Popconfirm>
    </Menu>
  )

  const contentClasses = [styles.content]
  if (props.offDuty.isApproved) {
    contentClasses.push(styles.approved)
  } else {
    contentClasses.push(styles.disapproved)
  }

  if (props.offDuty.type === EOffDutyType.Vacation) {
    contentClasses.push(styles.vacationEntry)
  }
  if (props.offDuty.type === EOffDutyType.TimeOff) {
    contentClasses.push(styles.timeOffEntry)
  }

  return (
    <div
      className={styles.root}
      style={{
        top: props.yPosIndex * RENDER_LANE_HEIGHT,
        marginLeft: xPos,
        width: width,
        color: 'white',
        fontSize: 10,
        height: RENDER_LANE_HEIGHT,
      }}
    >
      <Popover
        title={getOffDutyDuration()}
        content={
          <OffDutyPopover
            offDuty={props.offDuty}
            doesOverlapWithApproved={props.doesOverlapWithApproved}
          />
        }
      >
        <Dropdown
          overlay={menu}
          trigger={['click']}
          overlayClassName={styles.menuRoot}
          visible={isOpen}
          onVisibleChange={setOpen}
        >
          <div
            className={contentClasses.join(' ')}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseLeave={() =>
              setTimer(
                setTimeout(() => {
                  setOpen(false)
                }, 400)
              )
            }
          ></div>
        </Dropdown>
      </Popover>
    </div>
  )
}

export default OffDuty
