import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { selectWorkAssignmentById } from '../workAssignments/workAssignmentsSlice'
export interface IAppState {
  isDragging: boolean
  draggedMembershipId: string
  isSwapModeActive: boolean
  swapSource: string | undefined // id of a workassignment
}

const initialState: IAppState = {
  isDragging: false,
  draggedMembershipId: '',
  isSwapModeActive: false,
  swapSource: undefined
}

export function startDrag(workAssignmentId: string) {
  return (dispatch: any, getState: () => RootState) => {
    if (!workAssignmentId) {
      return null
    }
    const workAssignment = selectWorkAssignmentById(getState(), workAssignmentId)
    if (workAssignment) dispatch(dragStarted(workAssignment.membershipId))
  }
}

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    dragStarted: (state, action: PayloadAction<string>) => {
      state.isDragging = true
      state.draggedMembershipId = action.payload
    },
    dragEnded: (state) => {
      state.isDragging = false
      state.draggedMembershipId = ''
    },
    swapModeActivated: (state, action: PayloadAction<string>) => {
      state.isSwapModeActive = true
      state.swapSource = action.payload
    },
    swapModeDeactivated: (state) => {
      state.isSwapModeActive = false
      state.swapSource = undefined
    },
    swapRequested: (state) => {
      state.isSwapModeActive = false
    }
  },
})

export const selectDragInfo = (state:RootState) => ({draggedMembershipId: state.app.draggedMembershipId, isDragging: state.app.isDragging})
export const selectSwapModeActive = (state:RootState) => state.app.isSwapModeActive
export const selectSwapSource = (state:RootState) => {
  if(!state.app.swapSource) return undefined
  return state.workAssignments.entities[state.app.swapSource]
}

export const { dragStarted, dragEnded, swapModeActivated, swapModeDeactivated, swapRequested } = slice.actions
export default slice.reducer
