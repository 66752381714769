import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  Dictionary,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import config from '../../app/config'
import {
  formatISO,
  getMonth,
  getYear,
  isSameMonth,
  lastDayOfMonth,
  parseISO,
  startOfMonth,
} from 'date-fns'
import { Moment } from 'moment'
import { authorizedFetch } from '../../common/lib/fetch'
import { parse } from 'date-fns'
import { message } from 'antd'
import { i18n } from '../../common/lib/i18n'
import {replaceBaseUrl} from "../../common/lib/util/urlHelper";

export interface IOffDuty {
  id: string
  from: string
  to: string
  membershipId: string
  type: EOffDutyType
  isApproved: boolean
  comment: string
  canRemove: boolean
  canUpdate: boolean
  canApprove: boolean
}

export enum EOffDutyType {
  TimeOff = '0',
  Vacation = '1',
}

interface IOffDutyCreateParams {
  from: Moment
  to: Moment
  membershipId: string
  type: EOffDutyType
  comment?: string
}
interface IOffDutyUpdateParams {
  id: string
  from: Moment
  to: Moment
  membershipId: string
  comment?: string
}

interface IFetchParams {
  approvedOnly: boolean
  url?: any
}

export const fetch = createAsyncThunk(
  'offDuties/fetch',
  async (params: IFetchParams, thunkApi) => {
    try {
      let url = params?.url
      const baseUrl = `${config.backend.url}/api/off_duty/off_duty`
      if (!url) {
        url = new URL(baseUrl)
        let filters = {
          'fields[off_duty--off_duty]': 'comment,date_range,off_duty_type,is_approved,employee'
        }
        if (params.approvedOnly) {
          Object.assign(filters, {'filter[is_approved]': '1'});
        }
        url.search = new URLSearchParams(filters).toString()
      }
      const response: any = await authorizedFetch(url)
      if (response.status !== 200) {
        return thunkApi.rejectWithValue(response.statusText)
      }
      const json = await response.json()
      if ('next' in json.links) {
        // Jsonapi generates wrong urls
        url.href = replaceBaseUrl(baseUrl, json.links.next.href)
        thunkApi.dispatch(
          fetch({
            ...params,
            url: url,
          })
        )
      } else {
        thunkApi.dispatch(slice.actions.fetched(4))
      }
      return json
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

interface FetchByIntervalParams {
  approvedOnly: boolean
  start: Date
  end: Date
  url?: URL
}

export const fetchByInterval = createAsyncThunk(
  'offDuties/fetchByInterval',
  async (params: FetchByIntervalParams, thunkApi) => {
    // off_duty date_range is stored as UTC string in DB. Therefore pass
    // the UTC time string as a URL filter value.
    // Format should be 2021-11-30T23:00:00
    // todo find a permanent solution as it did work before DST change
    const startUTC = params.start.toISOString().replace(/.\d+Z$/g, "")
    const endUTC = params.end.toISOString().replace(/.\d+Z$/g, "")

    try {
      let url = params?.url
      const baseUrl = `${config.backend.url}/api/off_duty/off_duty`
      if (!url) {
        url = new URL(baseUrl)
        let filters = {
          'include': 'employee.user.avatar',
          'fields[off_duty--off_duty]': 'comment,date_range,off_duty_type,is_approved,employee',
          'fields[user--user]': 'avatar',
          'fields[file--file]': 'uri',
          'filter[a-Min-Filter][condition][path]': 'date_range.end_value',
          'filter[a-Min-Filter][condition][operator]': '>=',
          'filter[a-Min-Filter][condition][value]': startUTC,
          'filter[a-Max-Filter][condition][path]': 'date_range.value',
          'filter[a-Max-Filter][condition][operator]': '<=',
          'filter[a-Max-Filter][condition][value]': endUTC,
        }
        if (params.approvedOnly) {
          Object.assign(filters, {'filter[is_approved]': '1'});
        }
        url.search = new URLSearchParams(filters).toString()
      }

      const response: any = await authorizedFetch(url)
      if (response.status !== 200) {
        return thunkApi.rejectWithValue(response.statusText)
      }

      const json = await response.json()

      if ('next' in json.links) {
        // Jsonapi generates wrong urls
        url.href = replaceBaseUrl(baseUrl, json.links.next.href)
        thunkApi.dispatch(
          fetchByInterval({
            ...params,
            url: url
          })
        )
      }
      return json
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const create = createAsyncThunk(
  'offDuties/create',
  async (params: IOffDutyCreateParams, thunkApi) => {
    try {
      const data = {
        data: {
          type: 'off_duty--off_duty',
          attributes: {
            name: `${params.from.format('yyyy-MM-DD')}_${params.type}_${params.membershipId}`,
            type: params.type,
            is_approved: params.type === EOffDutyType.TimeOff,
            comment: params.comment,
            date_range: {
              value: params.from
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format(),
              end_value: params.to
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format(),
            },
          },
          relationships: {
            employee: {
              data: {
                type: 'membership--medical_facility_member',
                id: params.membershipId,
              },
            },
          },
        },
      }

      const url = `${config.backend.url}/api/off_duty/off_duty`
      const response = await authorizedFetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
      })

      if (response.status !== 201) {
        if (response.status === 422) {
          const violationData = await response.json()
          const errors = violationData.errors
          const code = errors[0].detail.split(': ')[1]
          message.error(i18n.t('validation-violation-' + code))
        } else {
          message.error(
            'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
          )
        }
        return thunkApi.rejectWithValue(response.statusText)
      }

      const json: any = await response.json()
      return map(json.data)
    } catch (error) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      //@ts-ignore error being unknown
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const update = createAsyncThunk(
  'offDuties/create',
  async (params: IOffDutyUpdateParams, thunkApi) => {
    try {
      const data = {
        data: {
          id: params.id,
          type: 'off_duty--off_duty',
          attributes: {
            comment: params.comment,
            date_range: {
              value: params.from
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format(),
              end_value: params.to
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format(),
            },
          },
          relationships: {
            employee: {
              data: {
                type: 'membership--medical_facility_member',
                id: params.membershipId,
              },
            },
          },
        },
      }

      const url = `${config.backend.url}/api/off_duty/off_duty/${params.id}`
      const response = await authorizedFetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
      })

      if (response.status !== 200) {
        if (response.status === 422) {
          const violationData = await response.json()
          const errors = violationData.errors
          const code = errors[0].detail.split(': ')[1]
          message.error(i18n.t('validation-violation-' + code))
        } else {
          message.error(
            'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
          )
        }
        return thunkApi.rejectWithValue(response.statusText)
      }

      const json: any = await response.json()
      return map(json.data)
    } catch (error) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      //@ts-ignore error being unkown
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const approve = createAsyncThunk(
  'offDuties/approve',
  async (id: string) => {
    const data = {
      data: {
        type: 'off_duty--off_duty',
        id,
        attributes: {
          is_approved: true,
        },
      },
    }

    const url = `${config.backend.url}/api/off_duty/off_duty/${id}`
    const response = await authorizedFetch(url, {
      method: 'PATCH',
      body: JSON.stringify(data),
    })
    const json: any = await response.json()
    return map(json.data)
  }
)
export const disapprove = createAsyncThunk(
  'offDuties/disapprove',
  async (id: string) => {
    const data = {
      data: {
        type: 'off_duty--off_duty',
        id,
        attributes: {
          is_approved: false,
        },
      },
    }

    const url = `${config.backend.url}/api/off_duty/off_duty/${id}`
    const response = await authorizedFetch(url, {
      method: 'PATCH',
      body: JSON.stringify(data),
    })
    const json: any = await response.json()
    return map(json.data)
  }
)
export const remove = createAsyncThunk<void, string>(
  'offDuties/delete',
  async (id, thunkApi) => {
    try {
      const url = `${config.backend.url}/api/off_duty/off_duty/${id}`
      const response = await authorizedFetch(url, {
        method: 'DELETE',
      })

      if (response.status !== 204) {
        message.error(
          'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
        )
        return thunkApi.rejectWithValue(response.statusText)
      }
    } catch (error) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      //@ts-ignore error being unknown
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

const entityAdapter = createEntityAdapter<IOffDuty>()

export const slice = createSlice({
  name: 'offDuties',
  initialState: entityAdapter.getInitialState({
    status: 'idle',
    error: null,
    isLoading: false
  }),
  reducers: {
    fetched: (state, action: PayloadAction<any>) => {
      state.isLoading = false
    },
  },
  extraReducers: {
    [fetch.pending.toString()]: (state) => {
      state.isLoading = true
      state.status = 'pending'
    },
    [fetch.fulfilled.toString()]: (state, action) => {
      state.status = 'fulfilled'
      entityAdapter.upsertMany(state, mapArray(action.payload))
    },
    [fetchByInterval.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded'
      entityAdapter.upsertMany(state, mapArray(action.payload))
    },
    [fetchByInterval.pending.toString()]: (state) => {
      state.status = 'pending'
    },
    [create.fulfilled.toString()]: (state, action) => {
      entityAdapter.upsertOne(state, action.payload)
    },
    [update.fulfilled.toString()]: (state, action) => {
      entityAdapter.upsertOne(state, action.payload)
    },
    [approve.fulfilled.toString()]: (state, action) => {
      entityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { isApproved: action.payload.isApproved },
      })
    },
    [disapprove.fulfilled.toString()]: (state, action) => {
      entityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { isApproved: action.payload.isApproved },
      })
    },
    [remove.fulfilled.toString()]: (state, action) => {
      entityAdapter.removeOne(state, action.meta.arg)
    },
  },
})

export default slice.reducer
export const { fetched } = slice.actions
export const {
  selectAll: selectAllOffDuties,
  selectById: selectOffDutyById,
  selectIds: selectOffDutyIds,
} = entityAdapter.getSelectors<RootState>((state) => state.offDuties)

export const selectOffDutiesByMembership = createSelector(
  [
    selectAllOffDuties,
    (state: RootState, membershipId: string) => membershipId,
  ],
  (offDuties, membershipId) =>
    offDuties.filter((offDuty) => offDuty.membershipId === membershipId)
)

export const selectOffDutiesByDate = createSelector(
  [selectAllOffDuties, (state: RootState, date: Date) => date],
  (offDuties, date) =>
    offDuties.filter((offDuty) => {
      if (offDuty.type !== EOffDutyType.TimeOff) return false
      const from = parseISO(offDuty.from)
      const to = parseISO(offDuty.to)

      return from.getTime() <= date.getTime() && to.getTime() >= date.getTime()
    })
)

export const selectVacationsByMonthAndYear = createSelector(
  [
    selectAllOffDuties,
    (_: RootState, month: number, __: number) => month,
    (_: RootState, __: number, year: number) => year,
  ],
  (offDuties, month, year) =>
    offDuties.filter((offDuty) => {
      if (!offDuty) return false
      if (!offDuty.isApproved) return false
      if (offDuty.type !== EOffDutyType.Vacation) return false

      const from = parseISO(offDuty.from)
      const to = parseISO(offDuty.to)
      const firstDayOfMonth = new Date(year, month, 1)
      const _lastDayOfMonth = lastDayOfMonth(firstDayOfMonth)

      return (
        to.getTime() >= firstDayOfMonth.getTime() &&
        from.getTime() <= _lastDayOfMonth.getTime()
      )
    })
)

export const selectOffDutiesByYear = createSelector(
  [
    selectAllOffDuties,
    (_: RootState, year: number) => year
  ],
  (offDuties, year) =>
    offDuties.filter((offDuty) => {
      if (!offDuty) return false

      const startDate = parseISO(offDuty.from)
      const endDate = parseISO(offDuty.to)
      return (
        getYear(startDate) === year || getYear(endDate) === year
      )
    })
)

export const selectVacationsByDateAndMembership = (
  state: RootState,
  dateString: string,
  membershipId: string
) =>
  selectOffDutyByDateMembershipAndType(
    state.offDuties.entities,
    dateString,
    membershipId,
    EOffDutyType.Vacation
  )

export const selectTimeOffsByDateAndMembership = (
  state: RootState,
  dateString: string,
  membershipId: string
) =>
  selectOffDutyByDateMembershipAndType(
    state.offDuties.entities,
    dateString,
    membershipId,
    EOffDutyType.TimeOff
  )

function selectOffDutyByDateMembershipAndType(
  entities: Dictionary<IOffDuty>,
  dateString: string,
  membershipId: string,
  type: EOffDutyType
) {
  return Object.values(entities).find((offDuty) => {
    if (!offDuty) return false
    if (!offDuty.isApproved) return false
    if (offDuty.type !== type) return false
    if (offDuty.membershipId !== membershipId) return false

    const from = parseISO(offDuty.from)
    const to = parseISO(offDuty.to)
    const date = parse(dateString, 'yyyy-MM-dd', new Date())

    if (from.getTime() <= date.getTime() && date.getTime() <= to.getTime())
      return true

    return false
  })
}

function mapArray(jsonApiResponse: any) {
  return (jsonApiResponse.data || []).flatMap(map)
}
function map(jsonApiResponse: any): IOffDuty {
  return {
    id: jsonApiResponse.id,
    from: jsonApiResponse.attributes.date_range.value,
    to: jsonApiResponse.attributes.date_range.end_value,
    membershipId: jsonApiResponse.relationships.employee.data.id,
    type: jsonApiResponse.attributes.off_duty_type,
    isApproved: jsonApiResponse.attributes.is_approved,
    comment: jsonApiResponse.attributes.comment,
    canRemove: jsonApiResponse.links?.mutableControls
      ? jsonApiResponse.links.mutableControls.meta.linkParams.rel.indexOf(
          'remove'
        ) !== -1
      : false,
    canUpdate: jsonApiResponse.links?.mutableControls
      ? jsonApiResponse.links.mutableControls.meta.linkParams.rel.indexOf(
          'update'
        ) !== -1
      : false,
    canApprove: jsonApiResponse.links?.field_is_approved_access
      ? jsonApiResponse.links.field_is_approved_access.meta.linkParams.rel.indexOf(
      'update'
    ) !== -1
      : false,
  }
}

export function selectVacationsOfMonthClamped(
  state: RootState,
  month: number,
  year: number
): IOffDuty[] {
  const vacations = selectVacationsByMonthAndYear(state, month, year)

  return vacations.map((vacation) => {
    const startDate = parseISO(vacation.from)
    const endDate = parseISO(vacation.to)

    if (isSameMonth(startDate, endDate)) return vacation

    if (getMonth(startDate) !== month) {
      const firstDayOfMonth = startOfMonth(endDate)
      return {
        id: vacation.id,
        canRemove: vacation.canRemove,
        canUpdate: vacation.canUpdate,
        canApprove: vacation.canApprove,
        comment: vacation.comment,
        from: formatISO(firstDayOfMonth),
        to: vacation.to,
        isApproved: vacation.isApproved,
        membershipId: vacation.membershipId,
        type: vacation.type,
      }
    } else {
      const _lastDayOfMonth = lastDayOfMonth(startDate)
      return {
        id: vacation.id,
        canRemove: vacation.canRemove,
        canUpdate: vacation.canUpdate,
        canApprove: vacation.canApprove,
        comment: vacation.comment,
        from: vacation.from,
        to: formatISO(_lastDayOfMonth),
        isApproved: vacation.isApproved,
        membershipId: vacation.membershipId,
        type: vacation.type,
      }
    }
  })
}
