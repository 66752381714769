import { isFirstDayOfMonth, isToday, isWeekend } from 'date-fns'
import { useAppSelector } from '../../../../../../app/hooks'
import { selectSchoolHolidaysByDate } from '../../../../../../features/school-holidays/schoolHolidaySlice'
import DayLine from './DayLine'

interface Props {
  day: Date
  isPublicHoliday: boolean
}

const Container = (props: Props) => {
  const schoolHolidays = useAppSelector((state) =>
    selectSchoolHolidaysByDate(state, props.day)
  )
  const schoolHoliday = schoolHolidays[0]
  const _isToday = isToday(props.day)

  return (
    <DayLine
      isPublicHoliday={props.isPublicHoliday}
      isToday={_isToday}
      isSchoolHoliday={!!schoolHoliday}
      isFirstDayOfMonth={isFirstDayOfMonth(props.day)}
      isWeekend={isWeekend(props.day)}
    />
  )
}

export default Container
