import { CoffeeOutlined } from '@ant-design/icons'
import { Popover, Spin } from 'antd'
import Text from 'antd/lib/typography/Text'
import clx from 'classnames'
import { format } from 'date-fns'
import { memo, useLayoutEffect, useRef } from 'react'
import { IOffDuty } from '../../../../features/off-duty/offDutySlice'
import Shift from '../../../../features/shifts'
import { IShift } from '../../../../features/shifts/shiftsSlice'
import TimeOffRequestsPopover from '../../TimeOffRequestsPopover/TimeOffRequestsPopover'
import DropDisabledInfo from '../DropDisabledInfo'
import styles from './Day.module.css'

interface IProps {
  dayTimeOffs: IOffDuty[]
  date: Date
  isDayAtWeekend: boolean
  isDayOfADifferentMonth: boolean
  isLoading: boolean
  isMemberOnVacation: boolean
  shifts: IShift[]
  hasMemberRequestedTimeOff: boolean
  nameOfPublicHoliday: string
  schoolHolidayName: string
}

function Day(props: IProps) {
  const rootRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!rootRef.current) return

    const parent = rootRef.current.parentNode
    if (!parent) return

    //@ts-ignore unknown property
    const classList = parent.classList

    if (props.isDayAtWeekend) classList.add(styles.isWeekend)
    else classList.remove(styles.isWeekend)

    if (props.hasMemberRequestedTimeOff)
      classList.add(styles.isTimeOffRequested)
    else classList.remove(styles.isTimeOffRequested)

    if (props.isDayOfADifferentMonth)
      classList.add(styles.isDayOfADifferentMonth)
    else classList.remove(styles.isDayOfADifferentMonth)

    if (props.nameOfPublicHoliday) classList.add(styles.isPublicHoliday)
    else classList.remove(styles.isPublicHoliday)

    if (
      props.schoolHolidayName &&
      !props.isDayAtWeekend &&
      !props.nameOfPublicHoliday
    )
      classList.add(styles.isSchoolHoliday)
    else classList.remove(styles.isSchoolHoliday)
  }, [
    props.hasMemberRequestedTimeOff,
    props.isDayAtWeekend,
    props.isDayOfADifferentMonth,
    props.nameOfPublicHoliday,
    props.schoolHolidayName,
  ])

  const rootClasses = clx({
    [styles.root]: true,
    [styles.isLoading]: props.isLoading,
  })

  return (
    <div ref={rootRef} className={rootClasses}>
      <div className={styles.dayHeader}>
        <Text className={styles.dayNumber}>{format(props.date, 'd')}</Text>
        <div className={styles.dayMetaWrapper}>
          {props.dayTimeOffs.length > 0 && (
            <Popover
              content={
                <TimeOffRequestsPopover
                  timeOffRequests={props.dayTimeOffs}
                  date={props.date}
                />
              }
              trigger={'hover'}
            >
              <div className={styles.dayTimeOffsPopupToggle}>
                <CoffeeOutlined />
              </div>
            </Popover>
          )}
          {!!props.schoolHolidayName && !props.nameOfPublicHoliday && (
            <Text className={styles.schoolHolidayName}>
              {props.schoolHolidayName}
            </Text>
          )}
          {!!props.nameOfPublicHoliday && (
            <Text className={styles.publicHolidayName}>
              {props.nameOfPublicHoliday}
            </Text>
          )}
        </div>
      </div>

      {props.isLoading && <Spin size="default" />}
      {!props.isLoading &&
        Object.values(props.shifts).map((shift) => {
          return (
            <Shift
              shiftId={shift.id}
              date={props.date}
              key={shift.id}
              isDropDisabled={props.isMemberOnVacation}
            />
          )
        })}

      <DropDisabledInfo
        isVacation={props.isMemberOnVacation}
        isDayOfDifferentMonth={props.isDayOfADifferentMonth}
      />
    </div>
  )
}

export default memo<IProps>(Day, areEqual)

function areEqual(prevProps: IProps, nextProps: IProps) {
  if (prevProps.date !== nextProps.date) return false
  if (prevProps.isLoading !== nextProps.isLoading) return false
  if (prevProps.isDayAtWeekend !== nextProps.isDayAtWeekend) return false
  if (prevProps.isDayOfADifferentMonth !== nextProps.isDayOfADifferentMonth)
    return false
  if (prevProps.nameOfPublicHoliday !== nextProps.nameOfPublicHoliday)
    return false
  if (prevProps.isMemberOnVacation !== nextProps.isMemberOnVacation)
    return false
  if (
    prevProps.hasMemberRequestedTimeOff !== nextProps.hasMemberRequestedTimeOff
  )
    return false

  // Compare timeoff requests
  if (prevProps.dayTimeOffs.length !== nextProps.dayTimeOffs.length)
    return false

  // Compare school holidays
  if (prevProps.schoolHolidayName !== nextProps.schoolHolidayName) return false

  prevProps.dayTimeOffs.forEach((prevTimeOff, index) => {
    if (prevTimeOff.id !== nextProps.dayTimeOffs[index].id) {
      return false
    }
  })

  // compare shifts
  if (prevProps.shifts.length !== nextProps.shifts.length) {
    return false
  }
  prevProps.shifts.forEach((prevUser, index) => {
    if (prevUser.id !== nextProps.shifts[index].id) {
      return false
    }
  })
  return true
}
