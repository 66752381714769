import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  selectSwapModeActive,
  swapModeDeactivated,
} from '../../../features/app/appSlice'
import CalendarPresentational from './Calendar'

const Calendar = () => {
  const isSwapModeActive = useAppSelector(selectSwapModeActive)
  const dispatch = useAppDispatch()

  const handleClose = useCallback(() => {
    dispatch(swapModeDeactivated())
  }, [dispatch])

  return (
    <CalendarPresentational
      isSwapModeActive={isSwapModeActive}
      handleClose={handleClose}
    />
  )
}

export default Calendar
