import { UserOutlined } from '@ant-design/icons'
import { Image, Spin } from 'antd'
import { default as AntdAvatar } from 'antd/lib/avatar/avatar'
import { memo } from 'react'
import styles from './Avatar.module.css'

interface IProps {
  avatarUrl: string | undefined,
  className?: string,
  isBlocked: boolean | undefined
}

export default memo(function Avatar(props: IProps) {
  if (!props.avatarUrl && !props.isBlocked)
    return <Spin />

  return (
    <AntdAvatar
      src={props.avatarUrl ?
        <Image
          preview={false}
          src={props.avatarUrl}
          className={styles.image}
        /> : undefined
      }
      className={props.className}
      icon={<UserOutlined />}
    />
  )
})
