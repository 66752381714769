import { View, StyleSheet } from '@react-pdf/renderer'
import Day from '../Day/Day'
import { add } from 'date-fns'
import { IShift } from '../../../features/shifts/shiftsSlice'
import { IWorkAssignment } from '../../../features/workAssignments/workAssignmentsSlice'
import { IMembership } from '../../../features/memberships/membershipsSlice'

interface IProps {
  showTitle: boolean
  date: Date
  shifts: IShift[]
  workAssignments: IWorkAssignment[]
  memberships: IMembership[]
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
  },
})

const Week = (props: IProps) => {
  return (
    <View style={styles.root} wrap={false}>
      {Array(7)
        .fill(undefined)
        .map((_, index) => (
          <Day
            date={add(props.date, {days: index})}
            shifts={props.shifts}
            key={index}
            showTitle={props.showTitle}
            workAssignments={props.workAssignments}
            memberships={props.memberships}
          />
        ))}
    </View>
  )
}

export default Week
