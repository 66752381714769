import { unwrapResult } from '@reduxjs/toolkit'
import { message } from 'antd'
import { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import { DutyRosterContextProvider } from '../../common/contexts/dutyRoster'
import { loadDutyRosterDetailViewData } from '../../features/duty-rosters/dutyRostersSlice'
import DutyRoster from './DutyRoster'
import {selectCurrentUser} from "../../features/currentUser/currentUserSlice";

type TParams = { id: string }

function ConnectedDutyRoster({ match }: RouteComponentProps<TParams>) {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(selectCurrentUser)
  const [isFullyLoaded, setFullyLoaded] = useState<boolean>(false)
  const [isLoading, setLoading] = useState(false)

  // load the duty roster and related data
  useEffect(() => {
    if (currentUser?.instututionUuid) {
      setLoading(true)
      dispatch(loadDutyRosterDetailViewData(match.params.id))
        .then(unwrapResult)
        .then((result) => {
          const failure = result.find((payload: any) => payload.error)
          if (!!failure) {
            console.error(failure)
            message.error('Beim Laden des Dienstplans sind Fehler aufgetreten.')
          } else {
            setFullyLoaded(true)
            setLoading(false)
          }
        })
        .catch((e: any) => console.error(e))
    }
  }, [dispatch, match.params.id, currentUser?.instututionUuid])

  return (
    <DutyRosterContextProvider
      dutyRosterId={match.params.id}
      isFullyLoaded={isFullyLoaded}
      isLoading={isLoading}
      setLoading={setLoading}
    >
      <DutyRoster history={history} />
    </DutyRosterContextProvider>
  )
}

export default ConnectedDutyRoster
