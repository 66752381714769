import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { message } from 'antd'
import config from '../../app/config'
import { RootState } from '../../app/store'
import { authorizedFetch } from '../../common/lib/fetch'
import { replaceAvatar } from '../currentUser/replaceAvatar'
import { EEmploymentType } from './employmentType'
import {replaceBaseUrl} from "../../common/lib/util/urlHelper";

export interface IMembership {
  id: string
  isBlocked: boolean
  userId: string
  firstName: string
  lastName: string
  backgroundColor: string
  workingMinutesPerWeek: number
  vacationDaysPerYear: number
  initialVacationDaysPerYear: number
  validWeekDays: number[]
  employmentType: EEmploymentType
  canRemove: boolean
  canUpdate: boolean
  useWhiteFont: boolean
  avatar: string | undefined
}

interface IMembershipParams {
  employment_type: EEmploymentType
  firstname: string
  lastname: string
  workinghours: number
  vacationdays: number
  initialVacationDays: number
  validWeekDays: number[]
  membershipId?: string
  useWhiteFont: boolean
  backgroundColor: string
}

interface fetchAllParams {
  institutionUuid: string
  url?: any | undefined
}
export const fetchAll = createAsyncThunk(
  'memberships/fetch',
  async (params: fetchAllParams, thunkApi) => {
    try {
      let url = params?.url
      const baseUrl = `${config.backend.url}/api/institution/medical_facility/${params.institutionUuid}`
      if (!url) {
        url = new URL(baseUrl)
        const filters = {
          'include': 'members,members.user.avatar',
          'fields[institution--medical_facility]': 'id',
          'fields[membership--medical_facility_member]': 'holiday_entitlement,initial_holiday_entitlement,valid_week_days,contractual_working_hours,employment_type,status,user',
          'fields[user--user]': 'first_name,last_name,color,avatar,use_white_font',
          'fields[file--file]': 'uri'
        }
        url.search = new URLSearchParams(filters).toString()
      }

      const result: any = await authorizedFetch(url)
      if (result.status !== 200) {
        return thunkApi.rejectWithValue(result.statusText)
      }

      const json = await result.json()
      if ('next' in json.links) {
        // Jsonapi generates wrong urls
        url.href = replaceBaseUrl(baseUrl, json.links.next.href)
        await thunkApi.dispatch(fetchAll({
          ...params,
          url: url,
        }))
      }
      return json
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

const entityAdapter = createEntityAdapter<IMembership>()

export const create = createAsyncThunk(
  'memberships/create',
  async (params: IMembershipParams, thunkApi) => {
    const data = {
      employment_type: params.employment_type,
      vacationdays: params.vacationdays,
      initialVacationDays: params.initialVacationDays,
      validWeekDays: params.validWeekDays,
      workinghours: params.workinghours,
      firstname: params.firstname,
      lastname: params.lastname,
      useWhiteFont: params.useWhiteFont,
      backgroundColor: params.backgroundColor,
    }
    const url = `${config.backend.url}/custom/membership`

    try {
      const response = await authorizedFetch(url, {
        method: 'POST',
        contentType: 'application/json',
        body: JSON.stringify(data),
      })
      if (response.status !== 201) {
        message.error('Es ist ein Fehler aufgetreten.')
        return thunkApi.rejectWithValue(response.statusText)
      }
      const json: any = await response.json()
      thunkApi.dispatch(slice.actions.created(mapCustom(json.data)))
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const update = createAsyncThunk(
  'memberships/update',
  async (params: IMembershipParams, thunkApi) => {
    const data = {
      workinghours: params.workinghours,
      firstname: params.firstname,
      lastname: params.lastname,
      useWhiteFont: params.useWhiteFont,
      backgroundColor: params.backgroundColor,
    }
    const url = `${config.backend.url}/custom/membership/${params.membershipId}`
    try {
      const response = await authorizedFetch(url, {
        method: 'PATCH',
        contentType: 'application/json',
        body: JSON.stringify(data),
      })
      if (response.status !== 200) {
        // todo try to avoid the double-error messages for the user
        message.error('Es ist ein Fehler aufgetreten.')
        return thunkApi.rejectWithValue(response.statusText)
      }
      const json: any = await response.json()
      return json
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const remove = createAsyncThunk(
  'memberships/delete',
  async (membershipId: string, thunkApi) => {
    const url = `${config.backend.url}/custom/membership/${membershipId}`
    try {
      const response = await authorizedFetch(url, {
        method: 'DELETE',
        contentType: 'application/json',
      })
      if (response.status !== 204) {
        // todo try to avoid the double-error messages for the user
        message.error('Es ist ein Fehler aufgetreten.')
        return thunkApi.rejectWithValue(response.statusText)
      }
      thunkApi.dispatch(slice.actions.removed(membershipId))
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const slice = createSlice({
  name: 'memberships',
  initialState: entityAdapter.getInitialState({
    status: 'idle',
    error: null,
  }),
  reducers: {
    created: entityAdapter.addOne,
    removed: entityAdapter.removeOne,
  },
  extraReducers: {
    [fetchAll.pending.toString()]: (state, action) => {
      state.status = 'pending'
    },
    [fetchAll.fulfilled.toString()]: (state, action) => {
      state.status = 'fulfilled'

      const jsonApiResponse = action.payload
      const memberships = jsonApiResponse.included.filter(
        (response: any) =>
          response.type === 'membership--medical_facility_member'
      )
      const users = jsonApiResponse.included.filter(
        (response: any) => response.type === 'user--user'
      )
      const avatars = jsonApiResponse.included.filter(
        (response: any) => response.type === 'file--file'
      )
      const mappedMemberships = map(memberships, users, avatars)
      entityAdapter.upsertMany(state, mappedMemberships)
    },
    [update.fulfilled.toString()]: (state, action) => {
      entityAdapter.updateOne(state, {
        id: action.payload.data.id,
        changes: {
          firstName: action.payload.data.firstName,
          lastName: action.payload.data.lastName,
          employmentType: action.payload.data.employmentType,
          vacationDaysPerYear: action.payload.data.vacationDays,
          initialVacationDaysPerYear: action.payload.data.initialVacationDays,
          validWeekDays: action.payload.data.validWeekDays,
          workingMinutesPerWeek: action.payload.data.workingHours * 60,
          useWhiteFont: action.payload.data.useWhiteFont,
          backgroundColor: action.payload.data.backgroundColor,
        },
      })
    },
    [replaceAvatar.fulfilled.toString()]: (state, action) => {
      entityAdapter.updateOne(state, {
        id: action.payload.membershipId,
        changes: {
          avatar: action.payload.avatar,
        },
      })
    },
  },
})

export default slice.reducer
export const {
  selectAll: selectAllMemberships,
  selectById: selectMembershipById,
  selectIds: selectMembershipIds,
} = entityAdapter.getSelectors<RootState>((state) => state.memberships)

function map(memberships: any, users: any, avatars: any = []): IMembership[] {
  return memberships.map((membership: any) => {
    const user = users.find(
      (user: any) => user.id === membership.relationships.user.data.id
    )
    const avatar = avatars.find(
      (avatar: any) => avatar.id === user.relationships.avatar.data?.id
    )
    return {
      id: membership.id,
      isBlocked: !membership.attributes.status,
      userId: user.id,
      firstName: user.attributes.first_name,
      lastName: user.attributes.last_name,
      avatar: avatar
        ? config.backend.url + avatar.attributes.uri.url
        : undefined,
      backgroundColor: user.attributes.color,
      useWhiteFont: !!user.attributes.use_white_font,
      vacationDaysPerYear: membership.attributes.holiday_entitlement,
      initialVacationDaysPerYear: membership.attributes.initial_holiday_entitlement,
      validWeekDays: membership.attributes.valid_week_days,
      workingMinutesPerWeek:
        membership.attributes.contractual_working_hours !== undefined
          ? membership.attributes.contractual_working_hours * 60
          : undefined,
      employmentType: membership.attributes.employment_type,
      canRemove: membership.links?.mutableControls
        ? membership.links.mutableControls.meta.linkParams.rel.indexOf(
            'remove'
          ) !== -1
        : false,
      canUpdate: membership.links?.mutableControls
        ? membership.links.mutableControls.meta.linkParams.rel.indexOf(
            'update'
          ) !== -1
        : false,
    }
  })
}

function mapCustom(json: any): IMembership {
  return {
    id: json.id,
    isBlocked: !json.status,
    avatar: undefined,
    userId: json.userId,
    firstName: json.firstName,
    lastName: json.lastName,
    vacationDaysPerYear: json.vacationDaysPerYear,
    initialVacationDaysPerYear: json.intialVacationDaysPerYear,
    validWeekDays: json.validWeekDays,
    workingMinutesPerWeek: json.workingHoursPerWeek * 60,
    employmentType: json.employmentType,
    backgroundColor: json.backgroundColor,
    useWhiteFont: json.useWhiteFont,
    canRemove: json.canRemove,
    canUpdate: json.canUpdate,
  }
}

export const selectMembershipsByEmploymentType = createSelector(
  [
    selectAllMemberships,
    (state: RootState, employmentType: EEmploymentType | undefined) =>
      employmentType,
  ],
  (memberships, employmentType) => {
    if (!employmentType) return []
    return memberships.filter(
      (membership) => membership.employmentType === employmentType
    )
  }
)
