import React, { useContext } from 'react'
import AdditionalVacationForm, {
  IAdditionalVacationFormValues,
} from './AdditionalVacationForm'
import { useAppDispatch } from '../../../../app/hooks'
import { updateAdditionalDays } from '../../../../features/offduty-logs/vacationLogSlice'
import { VacationLogContext } from '../../../contexts/vacationLog'
import { message } from 'antd'

interface IProps {
  currentAdditionalVacation: number
}

const ConnectedAdditionalVacationForm = ({
  currentAdditionalVacation,
}: IProps) => {
  const initialFormValues: IAdditionalVacationFormValues = {
    days: currentAdditionalVacation,
  }
  const dispatch = useAppDispatch()
  const vacationLog = useContext(VacationLogContext)

  if (!vacationLog) return null

  const handleFinish = async (values: IAdditionalVacationFormValues) => {
    if (vacationLog) {
      dispatch(
        updateAdditionalDays({ id: vacationLog.id, days: values.days })
      ).then((action) => {
        //@ts-ignore
        if (!action.error) {
          message.success('Die zusätzlichen Urlaubstage wurden gespeichert.')
        }
      })
    }
  }

  return (
    <AdditionalVacationForm
      handleFinish={handleFinish}
      initialValues={initialFormValues}
      disabled={!vacationLog.canUpdate}
    />
  )
}

export default ConnectedAdditionalVacationForm
