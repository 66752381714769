import { unwrapResult } from '@reduxjs/toolkit'
import { Modal, Spin } from 'antd'
import Text from 'antd/lib/typography/Text'
import { lastDayOfMonth } from 'date-fns'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { exportToExcel } from '../../../features/duty-rosters/dutyRostersSlice'
import { selectSchoolHolidaysByMonthAndYear } from '../../../features/school-holidays/schoolHolidaySlice'
import { useDutyRosterContext } from '../../contexts/dutyRoster'
import HolidayRepository from '../../lib/util/holidays'
import styles from './DutyRosterToExcelExportModal.module.css'

interface IProps {
  visible: boolean
  onCancel: () => void
}

const DutyRosterToExcelExportModal = (props: IProps) => {
  const dutyRosterContext = useDutyRosterContext()
  const dispatch = useAppDispatch()
  const [isGenerating, setGenerating] = useState(true)
  const [hasError, setError] = useState(false)
  const [fileUrl, setFileUrl] = useState<string>()
  const schoolHolidays = useAppSelector((state) =>
    selectSchoolHolidaysByMonthAndYear(
      state,
      dutyRosterContext.dutyRoster?.month || 5,
      dutyRosterContext.dutyRoster?.year || 1970
    )
  )
  useEffect(() => {
    const getPublicHolidaysByMonthAndYear = (
      month: number,
      year: number
    ): any[] => {
      const holidaysOfYear = HolidayRepository.getHolidays(year)
      const firstDayOfMonth = new Date(year, month, 1)
      const _lastDayOfMonth = lastDayOfMonth(firstDayOfMonth)

      return holidaysOfYear.filter(
        (holiday: any) =>
          holiday.type === 'public' &&
          holiday.start.getTime() <= _lastDayOfMonth.getTime() &&
          holiday.end.getTime() >= firstDayOfMonth.getTime()
      )
    }

    async function generateFile() {
      setGenerating(true)
      setError(false)
      try {
        if (!dutyRosterContext.dutyRoster)
          throw new Error('no duty roster provieded via context')

        const result = await dispatch(
          exportToExcel({
            year: dutyRosterContext.dutyRoster.year,
            month: dutyRosterContext.dutyRoster.month,
            dutyRosterId: dutyRosterContext.dutyRoster.id,
            businessDays: dutyRosterContext.businessDays,
            employmentType: dutyRosterContext.dutyRoster.employmentType,
            schoolHolidays,
            publicHolidays: getPublicHolidaysByMonthAndYear(
              dutyRosterContext.dutyRoster!.month,
              dutyRosterContext.dutyRoster!.year
            ),
          })
        )
        unwrapResult(result)
        setFileUrl(result.payload as string)
      } catch (rejectedValueOrSerializedError) {
        console.error(rejectedValueOrSerializedError)
        setError(true)
      }
      setGenerating(false)
    }

    if (props.visible) {
      generateFile()
    } else {
      setGenerating(false)
    }
  }, [dispatch, dutyRosterContext, props.visible, schoolHolidays])

  return (
    <Modal
      visible={props.visible}
      title="Excel-Export"
      footer={[]}
      onCancel={props.onCancel}
    >
      <div className={styles.wrapper}>
        {isGenerating && (
          <Spin>
            <img
              src="/Excel_2013_23480.png"
              alt="excel icon"
              className={styles.excelIcon}
            />
            <Text>wird generiert ...</Text>
          </Spin>
        )}
        {!isGenerating && !hasError && (
          <>
            <img
              src="/Excel_2013_23480.png"
              alt="excel icon"
              className={styles.excelIcon}
            />
            <a href={fileUrl} download>
              Excel-Export herunterladen
            </a>
          </>
        )}
        {!isGenerating && hasError && (
          <>
            <img
              src="/Excel_2013_23480.png"
              alt="excel icon"
              className={styles.excelIcon}
            />
            Es ist ein Fehler beim Exportieren aufgetreten. 🤨
          </>
        )}
      </div>
    </Modal>
  )
}

export default DutyRosterToExcelExportModal
