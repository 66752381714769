import { Descriptions } from 'antd'
import { useContext } from 'react'
import AdditionalVacationForm from './components/'
import styles from './EmployeeDutyRosterStatistic.module.css'
import { VacationLogContext } from '../../contexts/vacationLog'
import {format, parseISO} from "date-fns";
import locale from "date-fns/locale/de";

interface IProps {
  vertrglAZ: number
  tglAZ: number
  sollAZ: number
  planAZ: number
  istAZ: number
  carryOverNextMonth: number
  carryOverPreviousMonth: number
  deviatingHours: number
}

const EmployeeDutyRosterStatistic = (props: IProps) => {
  const difference = props.istAZ - props.sollAZ

  // Vacation details
  const vacationLog = useContext(VacationLogContext)
  const vacationDaysPerYear = vacationLog?.vacationDaysPerYear
  const vacationDaysFromPreviousMonth = vacationLog?.carryOverPreviousMonth
  const vacationDaysForNextMonth = vacationLog?.carryOverNextMonth
  const approvedVacationDays = vacationLog?.approvedDays
  const additionalVacationDays = vacationLog?.additionalVacationDays

  return (
    <>
      <Descriptions
        bordered
        title="Vertragliche AZ"
        className={styles.descriptionWrapper}
      >
        <Descriptions.Item label="Vertragl. AZ">
          {props.vertrglAZ}
        </Descriptions.Item>
        <Descriptions.Item label="Tage pro Woche">{(props.vertrglAZ / props.tglAZ).toFixed(1)}</Descriptions.Item>
        <Descriptions.Item label="Tägl. AZ">{props.tglAZ}</Descriptions.Item>
        <Descriptions.Item label="Soll AZ">{props.sollAZ}</Descriptions.Item>
        <Descriptions.Item
          className={
            props.carryOverPreviousMonth < 0 ? styles.negativeNumber : ''
          }
          label="Übertrag aus Vormonat"
        >
          {props.carryOverPreviousMonth}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions bordered title="AZ">
        <Descriptions.Item label="Plan AZ">{props.planAZ}</Descriptions.Item>
        <Descriptions.Item label="Ist AZ">{props.istAZ}</Descriptions.Item>
        <Descriptions.Item
          className={difference < 0 ? styles.negativeNumber : ''}
          label="Differenz"
        >
          {difference.toFixed(2)}
        </Descriptions.Item>
        <Descriptions.Item
          className={props.deviatingHours < 0 ? styles.negativeNumber : ''}
          label="Std. +/-"
        >
          {props.deviatingHours}
        </Descriptions.Item>
        <Descriptions.Item
          className={props.carryOverNextMonth < 0 ? styles.negativeNumber : ''}
          label="Übertrag in Folgemonat"
        >
          {props.carryOverNextMonth}
        </Descriptions.Item>
      </Descriptions>

      {vacationLog && (
        <Descriptions bordered title={"Urlaub " +
        `${format(parseISO(vacationLog.date), 'MMMM', {
          locale,
        })}`}>
          <Descriptions.Item label="Anspruch AV">
            {vacationDaysPerYear}
          </Descriptions.Item>
          <Descriptions.Item label="Übertrag">
            {vacationDaysFromPreviousMonth}
          </Descriptions.Item>
          <Descriptions.Item label="Genehmigt">
            {approvedVacationDays}
          </Descriptions.Item>
          <Descriptions.Item label="Zusatz">
            <AdditionalVacationForm
              currentAdditionalVacation={additionalVacationDays!}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Rest">
            {vacationDaysForNextMonth}
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  )
}

export default EmployeeDutyRosterStatistic
