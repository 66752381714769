import React from 'react'
import { IOffDuty } from '../../features/off-duty/offDutySlice'

export interface IOffDutiesContext {
  showVacationForm: (offDuty: IOffDuty) => void
  showTimeOffForm: (offDuty: IOffDuty) => void
  showApprovalForm: (offDuty: IOffDuty) => void
  offDutyToEdit: IOffDuty | undefined
  date: Date,
}

export const OffDutiesContext = React.createContext<IOffDutiesContext>({
  showVacationForm: (x) => null,
  showTimeOffForm: (x) => null,
  showApprovalForm: (x) => null,
  offDutyToEdit: undefined,
  date: new Date(),
})
