import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleFilled,
  StopOutlined,
  WarningTwoTone,
} from '@ant-design/icons'
import { Button, Popconfirm, Space, Table, Tag, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import {format, parseISO} from 'date-fns'
import {useContext} from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
  selectAllMemberships
} from '../../../../features/memberships/membershipsSlice'

import {
  disapprove,
  EOffDutyType,
  IOffDuty,
  remove,
} from '../../../../features/off-duty/offDutySlice'
import { doesOverlapWithApproved } from '../../helper'
import { OffDutiesContext } from '../../OffDutiesContext'

interface IProps {
  offDuties: IOffDuty[]
}

export default function TableView(props: IProps) {
  const memberships = useAppSelector(selectAllMemberships)

  const dispatch = useAppDispatch()
  const context = useContext(OffDutiesContext)

  const handleApproveVacation = (record: IOffDuty) => {
    return context.showApprovalForm(record)
  }

  const handleEditVacation = (record: IOffDuty) => {
    return context.showVacationForm(record)
  }

  const handleEditTimeOff = (record: IOffDuty) => {
    return context.showTimeOffForm(record)
  }

  const columns: ColumnsType<IOffDuty> = [
    {
      title: 'Datum / Zeitraum',
      key: 'duration',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        const startA = parseISO(a.from)
        const startB = parseISO(b.from)
        const isRangeComparisonPossible = a.to && b.to
        const startDateComparisonResult = startA.getTime() - startB.getTime()
        if (startDateComparisonResult !== 0 || !isRangeComparisonPossible)
          return startDateComparisonResult

        return parseISO(a.to).getTime() - parseISO(b.to).getTime()
      },
      render: (record) => {
        const doesOverlap = doesOverlapWithApproved(record, props.offDuties)
        const warning = (
          <>
            <Tooltip title="Dieser Urlaub überlappt mit einem bereits genehmigten Urlaub">
              <WarningTwoTone
                style={{ fontSize: '16px' }}
                twoToneColor="#fc3b02"
              />
            </Tooltip>
          </>
        )
        const from = format(parseISO(record.from), 'dd.MM.yyyy')
        if (record.from !== record.to) {
          const to = format(parseISO(record.to), 'dd.MM.yyyy')
          return doesOverlap ? (
            <>
              <span>{`${from} - ${to} `}</span> {warning}
            </>
          ) : (
            <span>{`${from} - ${to} `}</span>
          )
        } else {
          return doesOverlap ? (
            <>
              <span>{from}</span> {warning}
            </>
          ) : (
            <span>{from}</span>
          )
        }
      },
    },
    {
      title: 'Typ',
      key: 'type',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        return a.type < b.type ? -1 : 1
      },
      render: (record) => (
        <span>
          {record.type === EOffDutyType.Vacation ? 'Urlaub' : 'Dienstfrei'}
        </span>
      ),
    },
    {
      title: 'Genehmigt',
      key: 'isApproved',
      sorter: (a, b) => {
        return a.isApproved < b.isApproved ? -1 : 1
      },
      render: (record: IOffDuty) => {
        if (record.type === EOffDutyType.TimeOff) {
          return '–'
        } else {
          return (
            <Tag color={record.isApproved ? 'green' : 'volcano'}>
              {record.isApproved ? 'ja' : 'nein'}
            </Tag>
          )
        }
      },
    },
    {
      title: 'Mitarbeiter',
      key: 'employee',
      sorter: (a, b) => {
        const employeeA = memberships.find(
          (employee) => employee.id === a.membershipId
        )
        const employeeB = memberships.find(
          (employee) => employee.id === b.membershipId
        )

        if (!employeeB || !employeeA) return 0

        const lastNameComparisonResult = employeeA.lastName.localeCompare(
          employeeB.lastName
        )
        if (lastNameComparisonResult !== 0) return lastNameComparisonResult

        return employeeA.firstName.localeCompare(employeeB.firstName)
      },
      render: (record) => {
        const employee = memberships.find(
          (employee) => employee.id === record.membershipId
        )
        if (!employee) return null
        return <span>{`${employee.firstName} ${employee.lastName}`}</span>
      },
    },
    {
      title: 'Aktionen',
      key: 'action',
      render: (record: IOffDuty) => {
        const doesOverlap = doesOverlapWithApproved(record, props.offDuties)
        const approveTitle = doesOverlap
          ? 'Dieser Urlaub überlappt mit einem bereits genehmigten Urlaub'
          : 'Urlaub genehmigen'

      const approvalActions = (
          <>
            {!record.isApproved && (
              <Tooltip title={approveTitle}>
                <Button
                  onClick={() => handleApproveVacation(record)}
                  icon={<CheckOutlined />}
                  shape="circle"
                  disabled={!record.canApprove || doesOverlap}
                />
              </Tooltip>
            )}
            {record.isApproved && (
              <Tooltip title="Genehmigung zurückziehen">
                <Button
                  onClick={() => dispatch(disapprove(record.id))}
                  icon={<StopOutlined />}
                  shape="circle"
                  disabled={!record.canApprove}
                />
              </Tooltip>
            )}
          </>
        )

        return (
          <Space>
            {record.type === EOffDutyType.Vacation && approvalActions}
            {record.type === EOffDutyType.Vacation && (
              <Tooltip title="Editieren">
                <Button
                  onClick={() => handleEditVacation(record)}
                  icon={<EditOutlined />}
                  shape={'circle'}
                  disabled={!record.canUpdate}
                />
              </Tooltip>
            )}
            {record.type === EOffDutyType.TimeOff && (
              <Tooltip title="Editieren">
                <Button
                  onClick={() => handleEditTimeOff(record)}
                  icon={<EditOutlined />}
                  shape={'circle'}
                  disabled={!record.canUpdate}
                />
              </Tooltip>
            )}
            <Popconfirm
              placement="left"
              icon={<QuestionCircleFilled />}
              title="Soll dieser Datensatz wirklich gelöscht werden?"
              onConfirm={() => dispatch(remove(record.id))}
              okText="Ja, löschen"
              okButtonProps={{ danger: true }}
              cancelText="Nein"
              disabled={!record.canRemove}
            >
              <Tooltip title="Löschen">
                <Button
                  icon={<DeleteOutlined />}
                  shape={'circle'}
                  danger
                  disabled={!record.canRemove}
                />
              </Tooltip>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  return <Table rowKey="id" dataSource={props.offDuties} columns={columns} />

}
