import { parse } from 'date-fns'
import React, { ForwardedRef } from 'react'
import { useAppSelector } from '../../../app/hooks'
import {
  selectAllDutyRosters,
  selectTemplateDutyRosters
} from '../../../features/duty-rosters/dutyRostersSlice'
import { EEmploymentType } from '../../../features/memberships/employmentType'
import CreateDutyRosterForm, { ICreateDutyRosterFormValues } from './CreateDutyRosterForm'

interface IProps {
  visible: boolean
  handleFinish: (values: ICreateDutyRosterFormValues) => Promise<void>
  employmentType: EEmploymentType
}

const Container = (
  { visible, handleFinish, employmentType }: IProps,
  ref: ForwardedRef<CreateDutyRosterForm>
) => {
  const templates = useAppSelector(selectTemplateDutyRosters)
  const dutyRosters = useAppSelector(selectAllDutyRosters)
  // parse() format 'M' takes numbers from 1-12. However, dutyRosterSlice::map()
  // gets the roster month via startDate.getMonth(), which saves months 0-11.
  const dutyRosterDates = dutyRosters.filter((roster) =>
    roster.employmentType === employmentType
  ).map((date) => parse(`${date.year} ${date.month + 1} 1`, 'y M d', new Date()))
  // Filter the templates by the employmentType
  const filteredTemplates = templates.filter(template => {
    return template.employmentType === employmentType
  })

  return (
    <CreateDutyRosterForm
      templates={filteredTemplates}
      ref={ref}
      visible={visible}
      handleFinish={handleFinish}
      existingDutyRosterDates={dutyRosterDates}
    />
  )
}

export default React.forwardRef<CreateDutyRosterForm, IProps>(Container)
