import clx from 'classnames'
import { memo, useLayoutEffect, useRef } from 'react'
import styles from './DayLine.module.css'

interface Props {
  isPublicHoliday: boolean
  isToday: boolean
  isWeekend: boolean
  isSchoolHoliday: boolean
  isFirstDayOfMonth: boolean
}

const DayLine = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (props.isToday) {
      setTimeout(() => {
        ref.current!.scrollIntoView()
      }, 200)
    }
  }, [props.isToday])

  const rootClasses = clx({
    [styles.root]: true,
    [styles.isToday]: props.isToday,
    [styles.isWeekend]: props.isWeekend,
    [styles.isFirstOfMonth]: props.isFirstDayOfMonth,
    [styles.isPublicHoliday]: props.isPublicHoliday,
    [styles.isSchoolHoliday]: props.isSchoolHoliday,
  })

  return <div className={rootClasses} ref={ref}></div>
}

export default memo(DayLine)
