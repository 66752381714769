import { SliderPicker } from 'react-color'

interface IProps {
  value?: any
  onChange?: (rgb: string) => void
}

const ColorInput = (props: IProps) => {
  return (
    <SliderPicker
      color={props.value}
      onChangeComplete={(color: any) => props.onChange!(color.hex)}
    />
  )
}

export default ColorInput
