import {Modal, message, Spin} from 'antd'
import {useEffect, useState} from 'react'
import { useAppDispatch } from '../../../app/hooks'

import {
  review,
} from '../../../features/duty-rosters/dutyRostersSlice'
import Text from 'antd/lib/typography/Text'

interface IProps {
  visible: boolean
  handleClose: () => void
  dutyRosterId: string
}

const DutyRosterReviewModal = (props: IProps) => {
  const [isLoading, setLoading] = useState(false)
  const [errorData, setErrorData] = useState<any>(undefined)
  const [warningsData, setWarningsData] = useState<any>(undefined)
  const dispatch = useAppDispatch()

  // load the duty roster review data.
  useEffect(() => {
    setLoading(true)
    dispatch(review(props.dutyRosterId))
      .then((result: any) => {
        //@ts-ignore error being an unknown property
        if (!result.error) {
          setErrorData(result.payload?.errors)
          setWarningsData(result.payload?.warnings)
          setLoading(false)
        } else {
          props.handleClose()
          message.error('Es ist ein unbekannter Fehler aufgetreten. 🚧')
        }
      })
      .catch((e: any) => console.error(e))
  }, [dispatch, props, props.dutyRosterId])

  return (
    <Modal
      visible={props.visible}
      onCancel={props.handleClose}
      title="Dienstplan überprüfen"
      confirmLoading={isLoading}
      width={1500}
      destroyOnClose
      bodyStyle={{overflowY: 'auto', height: '600px'}}
    >
      {isLoading && (
        <Spin>
          <Text>Dienstplan wird überprüft ...</Text>
        </Spin>
      )}
      {!isLoading && errorData && warningsData && (
        <>
          <strong>---- Fehler ----</strong>
          <pre>
            {
              JSON.stringify(Object.entries(errorData), null, 1).replace(/[[\]{},"]/g, '')
            }
          </pre>
          <strong>---- Warnungen ----</strong>
          <pre>
            {
              JSON.stringify(Object.entries(warningsData), null, 1).replace(/[[\]{},"]/g, '')
            }
          </pre>
        </>
      )}

    </Modal>
  )
}

export default DutyRosterReviewModal
