import Modal from 'antd/lib/modal/Modal'
import Text from 'antd/lib/typography/Text'
import { format, parseISO } from 'date-fns'
import locale from 'date-fns/locale/de'
import { useRef, useState } from 'react'
import { useAppDispatch } from '../../../app/hooks'
import { IMembership } from '../../../features/memberships/membershipsSlice'
import { IShift } from '../../../features/shifts/shiftsSlice'
import { updateDeviatingHours } from '../../../features/workAssignments/workAssignmentsSlice'
import DeviatingHoursForm from '../DeviatingHoursForm'
import DeviatingHoursFormPresentational, {
  IDeviatingHoursFormValues,
} from '../DeviatingHoursForm/DeviatingHoursForm'
import styles from './DeviatingHoursModal.module.css'

interface Props {
  visible: boolean
  workAssignmentId: string
  currentDeviatingHours: number
  date: string
  handleClose: () => void
  membership: IMembership
  shift: IShift
}

export default function DeviatingHoursModal(props: Props) {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const formRef = useRef<DeviatingHoursFormPresentational>(null)
  const dispatch = useAppDispatch()
  const handleOk = () => {
    formRef.current?.submit()
  }
  const handleFinish = async (values: IDeviatingHoursFormValues) => {
    setConfirmLoading(true)
    await dispatch(
      updateDeviatingHours({
        id: props.workAssignmentId,
        deviatingHours: values.deviatingHours,
      })
    )
    setConfirmLoading(false)
    props.handleClose()
  }

  return (
    <Modal
      title={'Abweichende Stunden erfassen'}
      visible={props.visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={props.handleClose}
      okText={'Speichern'}
      cancelText={'Abbrechen'}
      destroyOnClose
    >
      <Text className={styles.intro}>
        Hier können Sie erfassen in welchem Umfang die tatsächlich geleisteten
        Stunden von{' '}
        <Text strong>
          {props.membership.firstName} {props.membership.lastName}
        </Text>{' '}
        am{' '}
        <Text strong>
          {format(parseISO(props.date), 'dd.MM.yyyy', { locale })}
        </Text>{' '}
        von den geplanten{' '}
        <Text strong>
          {new Intl.NumberFormat('de-DE').format(
            props.shift.workingMinutes / 60
          )}
        </Text>{' '}
        ({props.shift.longName}) abgewichen sind.
      </Text>
      <DeviatingHoursForm
        ref={formRef}
        visible={props.visible}
        handleFinish={handleFinish}
        currentDeviatingHours={props.currentDeviatingHours}
      />
    </Modal>
  )
}
