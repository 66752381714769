import Text from 'antd/lib/typography/Text'
import { useAppSelector } from '../../../app/hooks'
import { selectMembershipById } from '../../../features/memberships/membershipsSlice'
import { IOffDuty } from '../../../features/off-duty/offDutySlice'
import Avatar from '../Avatar'
import styles from './TimeOffRequest.module.css'

interface IProps {
  request: IOffDuty
}
const TimeOffRequest = (props: IProps) => {
  const member = useAppSelector((state) =>
    selectMembershipById(state, props.request.membershipId)
  )

  if (!member) return null

  return (
    <div className={styles.root}>
      <div className={styles.member}>
      <Avatar membershipId={member.id} className={styles.avatar} />
      <Text>
        {member.firstName} {member.lastName}
      </Text>
      </div>
      <Text className={styles.comment}>{props.request.comment}</Text>
    </div>
  )
}

export default TimeOffRequest
