/* eslint-disable no-dupe-keys */

const config = {
  backend: {
    url: 'https://backend.mederp.mewedo.app',
    jsonApi: {
      pageSize: 50,
    },
  },
  keycloak: {
    url: 'https://auth.mederp.mewedo.app',
    realm: 'medERP',
    clientId: 'frontend',
  },
  excel_exporter: {
    url: 'https://files.mederp.mewedo.app',
  },
  bugfender: {
    appKey: 'dLxL6XGgh3qzxS7qtQtxoQ8tQZqNbA7G',
  },
}


export default config
