import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { format, isSameMonth, isWeekend } from 'date-fns'
import locale from 'date-fns/locale/de'
import { useMemo } from 'react'
import { IMembership } from '../../../features/memberships/membershipsSlice'
import { IShift } from '../../../features/shifts/shiftsSlice'
import { IWorkAssignment } from '../../../features/workAssignments/workAssignmentsSlice'
import { useDutyRosterContext } from '../../contexts/dutyRoster'
import HolidayRepository from '../../lib/util/holidays'
import Shift from '../Shift/Shift'

const styles = StyleSheet.create({
  root: {
    margin: 3,
  },
  contentWrapper: {
    border: '1 solid #e4e4e4',
    flexDirection: 'row',
    justifyContent: 'center',
    width: 100,
    minHeight: 70,
    flexGrow: 1,
  },
  title: {
    fontSize: 9,
    alignSelf: 'center',
    marginBottom: 4,
  },
  titleWrapper: {
    marginBottom: 10,
  },
  dayOfMonth: {
    fontSize: 6,
    color: 'gray',
    position: 'absolute',
    top: 5,
    left: 5,
  },
  publicHolidayName: {
    fontSize: 5,
    color: 'gray',
    position: 'absolute',
    bottom: 10,
    left: 5,
  },
  isDayOfADifferentMonth: {
    opacity: 0.4,
  },
  isWeekend: {
    backgroundColor: '#fee',
  },
  isPublicHoliday: {
    backgroundColor: '#e3ffda',
  },
})

interface IProps {
  showTitle: boolean
  date: Date
  shifts: IShift[]
  memberships: IMembership[]
  workAssignments: IWorkAssignment[]
}

const Day = (props: IProps) => {
  const context = useDutyRosterContext()

  const isDayAtWeekend = useMemo(() => {
    return isWeekend(props.date)
  }, [props.date])

  const isDayOfADifferentMonth = useMemo(() => {
    if (!context.firstDayOfMonth) return false

    return !isSameMonth(props.date, context.firstDayOfMonth)
  }, [props.date, context.firstDayOfMonth])

  const holiday = HolidayRepository.isHoliday(props.date)
  const isPublicHoliday = Array.isArray(holiday) && holiday[0].type === 'public'

  return (
    <View style={styles.root}>
      {props.showTitle && (
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>
            {format(props.date, 'eeee', { locale })}
          </Text>
        </View>
      )}
      <View
        style={[
          styles.contentWrapper,
          isDayAtWeekend ? styles.isWeekend : {},
          isDayOfADifferentMonth ? styles.isDayOfADifferentMonth : {},
          isPublicHoliday ? styles.isPublicHoliday : {},
        ]}
      >
        <Text style={styles.dayOfMonth}>{format(props.date, 'd')}</Text>
        {isPublicHoliday && (
          <Text style={styles.publicHolidayName}>{holiday[0].name}</Text>
        )}

        {Object.values(props.shifts).map((shift) => (
          <Shift
            shift={shift}
            date={props.date}
            key={shift.id}
            memberships={props.memberships}
            workAssignments={props.workAssignments}
          />
        ))}
      </View>
    </View>
  )
}
export default Day
