import { eachDayOfInterval, format } from 'date-fns'
import { lastDayOfMonth } from 'date-fns/esm'
import locale from 'date-fns/locale/de'
import MonthHeaderDay from '../MonthHeaderDayNumber'
import MonthHeaderDayName from '../MonthHeaderDayName/MonthHeaderDayName'
import styles from './MonthHeaderColumn.module.css'

interface Props {
  monthIndex: number
  year: number
}

const MonthHeaderColumn = (props: Props) => {
  const firstOfMonth = new Date(props.year, props.monthIndex, 1)
  const lastOfMonth = lastDayOfMonth(firstOfMonth)
  const days = eachDayOfInterval({
    start: firstOfMonth,
    end: lastOfMonth,
  })

  return (
    <div className={styles.root}>
      <span className={styles.label}>
        {format(firstOfMonth, 'MMMM', { locale })}
      </span>
      <div className={styles.flex}>
        {days.map((day, index) => (
          <MonthHeaderDayName key={index} day={day} />
        ))}
      </div>
      <div className={styles.flex}>
        {days.map((day, index) => (
          <MonthHeaderDay key={index} day={day} dayOfYear={index + 1} />
        ))}
      </div>
    </div>
  )
}

export default MonthHeaderColumn
