import Modal from 'antd/lib/modal/Modal'
import { useContext, useRef, useState } from 'react'
import StaffForm from '../StaffForm'
import StaffFormPresentational, {
  IStaffFormValues,
} from '../StaffForm/StaffForm'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { update, create } from '../../../features/memberships/membershipsSlice'
import { MembershipContext } from '../../contexts/membership'
import { selectCurrentUser } from '../../../features/currentUser/currentUserSlice'

interface IProps {
  visible: boolean
  handleClose: () => void
}

export default function StaffModal({ visible, handleClose }: IProps) {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const formRef = useRef<StaffFormPresentational>(null)
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(selectCurrentUser)

  const handleOk = () => formRef.current?.submit()
  const handleCancel = handleClose
  const membershipToBeEdited = useContext(MembershipContext)

  const handleFinish = async (values: IStaffFormValues) => {
    setConfirmLoading(true)
    if (membershipToBeEdited !== null) {
      await dispatch(
        update({
          employment_type: currentUser.employmentType,
          firstname: values.firstname,
          lastname: values.lastname,
          workinghours: values.workinghours,
          vacationdays: values.vacationdays,
          initialVacationDays: values.initialVacationDays,
          validWeekDays: values.validWeekDays,
          membershipId: membershipToBeEdited.id,
          backgroundColor: values.backgroundColor,
          useWhiteFont: values.useWhiteFont,
        })
      )
    } else {
      await dispatch(
        create({
          employment_type: values.employment_type,
          firstname: values.firstname,
          lastname: values.lastname,
          workinghours: values.workinghours,
          vacationdays: values.vacationdays,
          initialVacationDays: values.initialVacationDays,
          validWeekDays: values.validWeekDays,
          backgroundColor: values.backgroundColor,
          useWhiteFont: values.useWhiteFont,
        })
      )
    }
    setConfirmLoading(false)
    handleClose()
  }

  return (
    <Modal
      title="Mitarbeiter Formular"
      visible={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      okText={'Speichern'}
      cancelText={'Abbrechen'}
      destroyOnClose
    >
      <StaffForm ref={formRef} handleFinish={handleFinish} visible={visible} />
    </Modal>
  )
}
