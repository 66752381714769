import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import config from '../../app/config'
import { RootState } from '../../app/store'
import { authorizedFetch } from '../../common/lib/fetch'
import { calculateWorkingTimeMinutes } from '../../common/lib/workingTime'
import {replaceBaseUrl} from "../../common/lib/util/urlHelper";

export interface IShift {
  id: string
  name: string
  workingMinutes: number
  breakTime: number
  longName: string
  validOn: number[]
  from: string
  to: string
}

interface FetchAllParameters {
  url?: URL
}

export const fetchAll = createAsyncThunk(
  'shifts/fetchAll',
  async (params: FetchAllParameters | undefined, thunkApi) => {
    try {
      let url = params?.url
      const baseUrl = `${config.backend.url}/api/shift/medical_shift`
      if (!url) {
        url = new URL(baseUrl)
      }
      const response = await authorizedFetch(url)

      if (response.status !== 200) {
        return thunkApi.rejectWithValue(response.statusText)
      }

      const json = await response.json()
      if ('next' in json.links) {
        // Jsonapi generates wrong urls
        url.href = replaceBaseUrl(baseUrl, json.links.next.href)
        await thunkApi.dispatch(fetchAll({url: url}))
      }
      return json
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

const shiftsAdapter = createEntityAdapter<IShift>()

export const shiftsSlice = createSlice({
  name: 'shifts',
  initialState: shiftsAdapter.getInitialState({
    status: 'idle',
    error: null,
  }),
  reducers: {},
  extraReducers: {
    [fetchAll.pending.toString()]: (state, action) => {
      state.status = 'pending'
    },
    [fetchAll.fulfilled.toString()]: (state, action) => {
      state.status = 'fulfilled'
      const json = action.payload
      shiftsAdapter.upsertMany(state, mapArray(json.data))
    },
  },
})

export default shiftsSlice.reducer

export const {
  selectAll: selectAllShifts,
  selectById: selectShiftById,
  selectIds: selectShiftIds,
} = shiftsAdapter.getSelectors<RootState>((state) => state.shifts)

function mapArray(shiftsJsonApiResponse: any) {
  return shiftsJsonApiResponse.map(map)
}

function map(jsonApiResponse: any): IShift {
  const workingMinutes = calculateWorkingTimeMinutes(
    jsonApiResponse.attributes.break_time,
    jsonApiResponse.attributes.time_range.from,
    jsonApiResponse.attributes.time_range.to
  )

  return {
    id: jsonApiResponse.id,
    name: jsonApiResponse.attributes.abbreviation,
    longName: jsonApiResponse.attributes.name,
    validOn: jsonApiResponse.attributes.valid_on,
    breakTime: parseInt(jsonApiResponse.attributes.break_time),
    from: jsonApiResponse.attributes.time_range.from,
    to: jsonApiResponse.attributes.time_range.to,
    workingMinutes: workingMinutes,
  }
}
