import {
  differenceInCalendarDays,
  getYear,
  parseISO
} from 'date-fns'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { IMembership } from '../../../features/memberships/membershipsSlice'
import {
  EOffDutyType,
  selectOffDutiesByMembership,
} from '../../../features/off-duty/offDutySlice'
import PureEmployeeOffDutyStatistic from './EmployeeOffDutyStatistic'
import {useContext, useEffect, useMemo} from 'react'
import {
  fetchByRangeAndEmployee,
  selectVacationLogsByYearAndEmployee,
} from '../../../features/offduty-logs/vacationLogSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { OffDutiesContext } from '../../../pages/OffDuties/OffDutiesContext'
import {getAnnualVacationStats} from "../../lib/workingTime";

interface IProps {
  employee: IMembership
  numApproved: number
}

const EmployeeOffDutyStatistic = (props: IProps) => {
  const dispatch = useAppDispatch()

  const context = useContext(OffDutiesContext)
  const contextDate = new Date(context.date)
  const selectedYear = getYear(contextDate).toString()
  const firstDayOfYear = useMemo(() => new Date(parseInt(selectedYear), 0, 1), [selectedYear])
  const lastDayOfYear = useMemo(() => new Date(parseInt(selectedYear), 11, 31), [selectedYear])

  // Load all selected year's vacationlogs for the member.
  useEffect(() => {
    async function loadVacationLogs() {
      const response = await dispatch(
        fetchByRangeAndEmployee({
          employeeId: props.employee.id,
          startDate: firstDayOfYear,
          endDate: lastDayOfYear,
        })
      )
      unwrapResult(response)
    }
    loadVacationLogs()
  }, [dispatch, firstDayOfYear, props.employee.id, lastDayOfYear, props.numApproved])

  const vacationLogs = useAppSelector((state) =>
    selectVacationLogsByYearAndEmployee(state, selectedYear, props.employee.id)
  )

  const annualStats = getAnnualVacationStats(vacationLogs, firstDayOfYear, lastDayOfYear)
  const vacationDaysPerYear = annualStats.vacationDaysPerYear
  const totalAvailableDays =
    annualStats.additionalVacationDays + annualStats.vacationDaysFromPreviousYear + vacationDaysPerYear
  const offDutiesOfTheEmployee = useAppSelector((state) =>
    selectOffDutiesByMembership(state, props.employee.id)
  )

  const timeOffs = offDutiesOfTheEmployee.filter(
    (offDuty) => offDuty.type === EOffDutyType.TimeOff
  )
  const registeredTimeOffDays = timeOffs.reduce((result, timeOff) => {
    const numberOfDays =
      Math.abs(
        differenceInCalendarDays(parseISO(timeOff.from), parseISO(timeOff.to))
      ) + 1
    return result + numberOfDays
  }, 0)

  return (
    <PureEmployeeOffDutyStatistic
      totalAvailableDays={totalAvailableDays}
      remainingAvailableDays={annualStats.remainingAvailableDays}
      approvedVacationDays={annualStats.approvedVacationDays}
      vacationDaysPerYear={vacationDaysPerYear}
      additionalVacationDays={annualStats.additionalVacationDays}
      registeredTimeOffDays={registeredTimeOffDays}
      vacationDaysFromPreviousYear={annualStats.vacationDaysFromPreviousYear}
    />
  )
}

export default EmployeeOffDutyStatistic
