import { useContext, useMemo } from 'react'
import {
  EWorkAssignmentAbbreviation,
  IWorkAssignment,
} from '../../../features/workAssignments/workAssignmentsSlice'
import styles from './WorkAssignmentPopover.module.css'
import { format } from 'date-fns'
import locale from 'date-fns/locale/de'
import { ShiftContext } from '../../contexts/shift'
import { IMembership } from '../../../features/memberships/membershipsSlice'
import {
  PaperClipOutlined,
  ClockCircleOutlined,
  SwapOutlined,
} from '@ant-design/icons'
import Avatar from '../Avatar'
import Text from 'antd/lib/typography/Text'
import { Typography } from 'antd'
const { Paragraph } = Typography

interface IProps {
  membership: IMembership
  workAssignment: IWorkAssignment
}

export default function WorkAssignmentPopover(props: IProps) {
  const shiftContext = useContext(ShiftContext)
  const date = Date.parse(props.workAssignment.date)

  const deviatingHours = useMemo(() => {
    return (
      Math.round(props.workAssignment.deviatingHours * 100) / 100
    ).toFixed(2)
  }, [props.workAssignment.deviatingHours])

  let content = useMemo(() => {
    if (!shiftContext) return null

    // if the workassignment has an actual shift
    if (shiftContext.actual) {
      if (shiftContext.planned.id === shiftContext.displayedShiftId) {
        return (
          <div className={styles.content}>
            <Text className={`${styles.trailingDot} ${styles.date}`}>
              {format(date, 'dd.MM.yyyy', {
                locale,
              })}
            </Text>
            <Text className={styles.trailingDot}>
              {shiftContext.planned.longName}
            </Text>

            <Text>
              tatsächlich ausgeführt:{' '}
              <Text strong>{shiftContext.actual.longName}</Text>
            </Text>
          </div>
        )
      } else if (shiftContext.actual.id === shiftContext.displayedShiftId) {
        return (
          <div className={styles.content}>
            <Text className={`${styles.trailingDot} ${styles.date}`}>
              {format(date, 'dd.MM.yyyy', {
                locale,
              })}
            </Text>
            <Text className={styles.trailingDot}>
              {shiftContext.actual.longName}
            </Text>
            <Text>
              eigentlich geplant als:{' '}
              <Text strong>{shiftContext.planned.longName}</Text>
            </Text>
          </div>
        )
      }
    } else {
      return (
        <div className={styles.content}>
          <Text className={`${styles.trailingDot} ${styles.date}`}>
            {format(date, 'dd.MM.yyyy', {
              locale,
            })}
          </Text>
          <Text>{shiftContext.planned.longName}</Text>
        </div>
      )
    }
  }, [date, shiftContext])

  if (!shiftContext) return null

  return (
    <div className={styles.root}>
      <div className={styles.nameWrapper}>
        <Avatar membershipId={props.membership.id} className={styles.avatar} />
        <Text className={styles.name} strong>
          {props.membership.firstName} {props.membership.lastName}
        </Text>
      </div>
      {content}
      {props.workAssignment.isCanceled && (
        <Paragraph className={styles.canceled}>
          Dieser Dienst wurde storniert
        </Paragraph>
      )}
      <Paragraph>
        <SwapOutlined /> Tauschbar:{' '}
        <Text strong>{props.workAssignment.exchangeable ? 'Ja' : 'Nein'}</Text>
      </Paragraph>
      {props.workAssignment.abbreviation && (
        <Paragraph>
          <PaperClipOutlined /> Bemerkungen:{' '}
          <Text strong>
            {/* @ts-ignore invalid access to enum value */}
            {EWorkAssignmentAbbreviation[props.workAssignment.abbreviation]}
          </Text>
        </Paragraph>
      )}
      <Paragraph>
        <ClockCircleOutlined /> Std. +/-: <Text strong>{deviatingHours}</Text>
      </Paragraph>
    </div>
  )
}
