import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import config from '../../app/config'
import { fetchByInterval as fetchOffDutiesByInterval } from '../off-duty/offDutySlice'
import { fetchByInterval as fetchSchoolHolidaysByInterval, ISchoolHoliday } from '../school-holidays/schoolHolidaySlice'
import { fetchByDutyRoster as fetchWorkAssignmentsByDutyRoster } from '../workAssignments/workAssignmentsSlice'
import { message } from 'antd'
import { authorizedFetch } from '../../common/lib/fetch'
import { History } from 'history'
import { Moment } from 'moment'
import {
  fetchByDutyRoster as fetchWorkLogsByDutyRoster,
  selectWorkLogsByDutyRoster,
} from '../work-logs/workLogSlice'
import { fetchByDutyRoster as fetchSwapRequestsByDutyRoster } from '../swapRequests/swapRequestsSlice'
import { getDutiesForExport, getOffDutiesForExport } from './helper'
import parseISO from 'date-fns/parseISO'
import { calculateDailyWorkingHours } from '../../common/lib/workingTime'
import {
  fetchByDate,
  selectVacationLogsByDate,
} from '../offduty-logs/vacationLogSlice'
import { EEmploymentType } from '../memberships/employmentType'
import deLocale from 'date-fns/locale/de'
import { format } from 'date-fns'
import { fetchAll as fetchAllShifts } from '../shifts/shiftsSlice'
import {replaceBaseUrl} from "../../common/lib/util/urlHelper"
import {selectMembershipsByEmploymentType} from "../memberships/membershipsSlice"

export interface IDutyRoster {
  id: string
  institutionName: string | undefined
  institutionId: string | undefined
  month: number
  year: number
  isTemplate: boolean
  isApproved: boolean
  shifts: string[]
  employmentType: EEmploymentType
  canRemove: boolean
  canUpdate: boolean
}

interface ICreateParams {
  history: History
  templateId: string
  period: Moment
}
export interface IExportToExcelParameters {
  year: number
  month: number
  dutyRosterId: string
  businessDays: number
  employmentType: EEmploymentType
  schoolHolidays: ISchoolHoliday[]
  publicHolidays: any[]
}

export const create = createAsyncThunk<
  IDutyRoster,
  ICreateParams,
  { state: RootState }
>('dutyRoster/create', async ({ history, templateId, period }, thunkApi) => {
  const state = thunkApi.getState()
  try {
    const template = state.dutyRosters.entities[templateId]!
    const shifts = template.shifts.map((shift) => {
      return {
        type: 'shift--medical_shift',
        id: shift,
      }
    })

    const data = {
      data: {
        type: 'duty-roster--medical_facility_roster',
        attributes: {
          name: `${period.format('yyyy-MM')}_${template.employmentType}`,
          date_range: calculateDateRange(period),
          shift_employment_type: template.employmentType,
        },
        relationships: {
          institution: {
            data: {
              type: 'institution--medical_facility',
              id: template.institutionId,
            },
          },
          shifts: {
            data: shifts,
          },
        },
      },
    }

    const url = `${config.backend.url}/api/duty_roster/medical_facility_roster`
    const response = await authorizedFetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
    if (response.status !== 201) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(response.statusText)
    }
    const json: any = await response.json()
    const dutyRoster = map(json.data)
    history.push(`/duty-roster/${dutyRoster.id}`)
    return dutyRoster
  } catch (error: any) {
    message.error(
      'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
    )
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const exportToExcel = createAsyncThunk<
  string,
  IExportToExcelParameters,
  { state: RootState }
>('dutyRoster/exportToExcel', async (params, thunkApi) => {
  const state = thunkApi.getState()
  const workLogs = selectWorkLogsByDutyRoster(
    state,
    params.dutyRosterId
  )

  const firstDayOfMonth = new Date(params.year, params.month, 1)
  const vacationLogs = selectVacationLogsByDate(state, firstDayOfMonth)
  const employees =
    selectMembershipsByEmploymentType(state, params.employmentType)
    .filter((membership) => {
      const workLog = (workLogs || []).find((workLog) => {
        return workLog.employee === membership.id
      })
      return workLog?.id
    }).map((employee) => ({
      id: employee!.id,
      firstName: employee!.firstName,
      lastName: employee!.lastName,
      workingHoursPerWeek: employee!.workingMinutesPerWeek / 60,
      validWeekDays: employee!.validWeekDays
    }))
  const data = {
    year: params.year,
    month: params.month + 1,
    employees,
    offDuties: getOffDutiesForExport(state, params.month, params.year, employees),
    duties: getDutiesForExport(state, params.month, params.year),
    shifts: Object.values(state.shifts.entities).map((shift) => {
      if (!shift) return null

      return {
        name: shift.name,
        id: shift.id,
      }
    }),
    workLogs: workLogs.map((workLog) => {
      const tglAZ = calculateDailyWorkingHours(workLog.contractualWorkingHours, workLog.numberWeekDays)

      return {
        employeeId: workLog.employee,
        plannedTime: workLog.plannedTime,
        currentWorkingTime: workLog.currentWorkingTime,
        sollAZ: workLog.setWorkingTime,
        tglAZ: tglAZ,
        carryOver: workLog.carryOver,
        deviatingHours: workLog.deviatingHours,
        carryOverNextMonth: workLog.carryOverNextMonth,
      }
    }),
    vacationLogs: vacationLogs.map((vacationLog) => {
      return {
        employeeId: vacationLog.employee,
        additionalDays: vacationLog.additionalVacationDays,
        vacationDaysFromLastMonth: vacationLog.carryOverPreviousMonth,
        vacationDaysForNextMonth: vacationLog.carryOverNextMonth,
        approvedVacationDays: vacationLog.approvedDays,
      }
    }),
    schoolHolidays: params.schoolHolidays,
    publicHolidays: params.publicHolidays,
  }
  const url = `${config.excel_exporter.url}/duty-roster`
  const response = await authorizedFetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
  })
  if (response.status === 500) {
    throw new Error(
      'Could not create the excel export because of a server error'
    )
  } else {
    const fileUrl: string = await response.text()
    return fileUrl
  }
})

export const approve = createAsyncThunk(
  'dutyRoster/approve',
  async (id: string) => {
    const data = {
      data: {
        type: 'duty_roster--medical_facility_roster',
        id,
        attributes: {
          is_approved: true,
        },
      },
    }

    const url = `${config.backend.url}/api/duty_roster/medical_facility_roster/${id}`
    const response = await authorizedFetch(url, {
      method: 'PATCH',
      body: JSON.stringify(data),
    })
    const json: any = await response.json()
    return map(json.data)
  }
)

export const disapprove = createAsyncThunk(
  'dutyRoster/disapprove',
  async (id: string) => {
    const data = {
      data: {
        type: 'duty_roster--medical_facility_roster',
        id,
        attributes: {
          is_approved: false,
        },
      },
    }

    const url = `${config.backend.url}/api/duty_roster/medical_facility_roster/${id}`
    const response = await authorizedFetch(url, {
      method: 'PATCH',
      body: JSON.stringify(data),
    })
    const json: any = await response.json()
    return map(json.data)
  }
)

export const review = createAsyncThunk(
  'dutyRoster/review',
  async (id: string, thunkApi) => {
    const url = `${config.backend.url}/custom/monthreview/${id}`
    const response = await authorizedFetch(url, {
      method: 'GET',
    })
    if (response.status !== 200) {
      return thunkApi.rejectWithValue(response.statusText)
    }
    return await response.json()
  }
)

export const makeTemplate = createAsyncThunk<void, string>(
  'dutyRoster/make_template',
  async (id: string, thunkApi: any) => {
    try {
      const data = {
        data: {
          type: 'duty_roster--medical_facility_roster',
          id,
          attributes: {
            is_template: true,
          },
        },
      }

      const url = `${config.backend.url}/api/duty_roster/medical_facility_roster/${id}`
      const response = await authorizedFetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
      })
      if (response.status !== 200) {
        message.error(
          'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
        )
        return thunkApi.rejectWithValue(response.statusText)
      }
      const json: any = await response.json()
      return map(json.data)
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const removeTemplate = createAsyncThunk<void, string>(
  'dutyRoster/remove_template',
  async (id: string, thunkApi: any) => {
    try {
      const data = {
        data: {
          type: 'duty_roster--medical_facility_roster',
          id,
          attributes: {
            is_template: false,
          },
        },
      }

      const url = `${config.backend.url}/api/duty_roster/medical_facility_roster/${id}`
      const response = await authorizedFetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
      })

      if (response.status !== 200) {
        message.error(
          'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
        )
        return thunkApi.rejectWithValue(response.statusText)
      }
      const json: any = await response.json()
      return map(json.data)
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const remove = createAsyncThunk<void, string>(
  'dutyRoster/delete',
  async (id, thunkApi) => {
    try {
      const url = `${config.backend.url}/api/duty_roster/medical_facility_roster/${id}`
      const response = await authorizedFetch(url, {
        method: 'DELETE',
      })

      if (response.status !== 204) {
        message.error(
          'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
        )
        return thunkApi.rejectWithValue(response.statusText)
      }
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const loadDutyRosterDetailViewData = createAsyncThunk<
  Promise<any[]>,
  string,
  { state: RootState }
>('loadDutyRosterDetailViewData', async (id, thunkApi) => {
    const dutyRosterResult = await thunkApi.dispatch(fetchDutyRoster(id))

    const promises = []
    promises.push(thunkApi.dispatch(fetchAllShifts()))

    promises.push(
      thunkApi.dispatch(fetchWorkAssignmentsByDutyRoster({ dutyRosterId: id }))
    )

    // load the off duties
    const startDate = parseISO(
      dutyRosterResult.payload.data.attributes.date_range.value
    )
    const endDate = parseISO(
      dutyRosterResult.payload.data.attributes.date_range.end_value
    )
    promises.push(
      thunkApi.dispatch(
        fetchOffDutiesByInterval({
          approvedOnly: true,
          start: startDate,
          end: endDate,
        })
      )
    )

    // load the work_logs
    promises.push(thunkApi.dispatch(fetchWorkLogsByDutyRoster({dutyRosterId: id})))

    // load the swap requests
    promises.push(thunkApi.dispatch(fetchSwapRequestsByDutyRoster({dutyRosterId: id})))

    // Load the vacation logs
    promises.push(thunkApi.dispatch(fetchByDate({date: startDate})))

    // Load school holidays
    const currentYear = parseInt(
      format(new Date(), 'yyyy', { locale: deLocale })
    )
    const firstDayOfYear = new Date(currentYear, 0, 1)
    const lastDayOfYear = new Date(currentYear, 11, 31)

    const state = thunkApi.getState()
    if (Object.values(state.schoolHolidays.entities).length === 0) {
      promises.push(
        thunkApi.dispatch(
          fetchSchoolHolidaysByInterval({
            start: firstDayOfYear,
            end: lastDayOfYear,
          })
        )
      )
    }

    return Promise.all(promises)
})

export const fetchDutyRoster = createAsyncThunk(
  'dutyRoster/fetch',
  async (id: string) => {
    const response: any = await authorizedFetch(
      `${config.backend.url}/api/duty_roster/medical_facility_roster/${id}` +
        `?fields[duty_roster--medical_facility_roster]=date_range,shift_employment_type,shifts,is_approved`
    )
    return response.json()
  }
)

interface FetchParams {
  url?: URL
}

export const fetchDutyRosters = createAsyncThunk(
  'dutyRosters/fetch',
  async (params: FetchParams | undefined, thunkApi) => {
    let url = params?.url
    const baseUrl = `${config.backend.url}/api/duty_roster/medical_facility_roster`
    if (!url) {
      url = new URL(baseUrl)
      const filters = {
        'include': 'institution',
        'fields[duty_roster--medical_facility_roster]': 'name,date_range,is_template,is_approved,shift_employment_type,shifts',
        'fields[institution--medical_facility]': 'name'
      }
      url.search = new URLSearchParams(filters).toString()
    }

    try {
      const result: any = await authorizedFetch(url)
      const json = await result.json()
      if ('next' in json.links) {
        // Jsonapi generates wrong urls
        url.href = replaceBaseUrl(baseUrl, json.links.next.href)
        await thunkApi.dispatch(fetchDutyRosters({
          url: url,
        }))
      }
      return json
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

function mapArray(jsonApiResponse: any) {
  // todo support multiple institutions
  if (jsonApiResponse.data.length === 0) return []

  const institutionName = jsonApiResponse.included[0].attributes.name
  const institutionId = jsonApiResponse.included[0].id
  return (jsonApiResponse.data || []).map((jsonApiEntity: any) =>
    map(jsonApiEntity, institutionName, institutionId)
  )
}

function map(
  jsonApiEntity: any,
  institutionName?: string,
  institutionId?: string
): IDutyRoster {
  const startDate = parseISO(jsonApiEntity.attributes.date_range.value)
  return {
    id: jsonApiEntity.id,
    institutionName,
    institutionId,
    month: startDate.getMonth(),
    year: startDate.getFullYear(),
    isTemplate: jsonApiEntity.attributes.is_template,
    isApproved: jsonApiEntity.attributes.is_approved,
    shifts: jsonApiEntity.relationships.shifts.data.map(
      (record: any) => record.id
    ),
    employmentType: jsonApiEntity.attributes.shift_employment_type,
    canRemove: jsonApiEntity.links?.mutableControls
      ? jsonApiEntity.links.mutableControls.meta.linkParams.rel.indexOf(
          'remove'
        ) !== -1
      : false,
    canUpdate: jsonApiEntity.links?.mutableControls
      ? jsonApiEntity.links.mutableControls.meta.linkParams.rel.indexOf(
          'update'
        ) !== -1
      : false,
  }
}

const entityAdapter = createEntityAdapter<IDutyRoster>()

export const slice = createSlice({
  name: 'dutyRosters',
  initialState: entityAdapter.getInitialState({
    status: 'idle',
    error: null,
  }),
  reducers: {},
  extraReducers: {
    [fetchDutyRosters.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded'
      const json = action.payload
      const mappingResult = mapArray(json)
      entityAdapter.upsertMany(state, mappingResult)
    },
    [fetchDutyRosters.pending.toString()]: (state) => {
      state.status = 'pending'
    },
    [fetchDutyRoster.pending.toString()]: (state, action) => {
      state.status = 'pending'
    },
    [fetchDutyRoster.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded'
      const json = action.payload
      const mappingResult = map(json.data)
      entityAdapter.upsertOne(state, mappingResult)
    },
    [remove.fulfilled.toString()]: (state, action) => {
      entityAdapter.removeOne(state, action.meta.arg)
    },
    [approve.fulfilled.toString()]: (state, action) => {
      entityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { isApproved: action.payload.isApproved },
      })
    },
    [disapprove.fulfilled.toString()]: (state, action) => {
      entityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { isApproved: action.payload.isApproved },
      })
    },
    [makeTemplate.fulfilled.toString()]: (state, action) => {
      entityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { isTemplate: action.payload.isTemplate },
      })
    },
    [removeTemplate.fulfilled.toString()]: (state, action) => {
      entityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { isTemplate: action.payload.isTemplate },
      })
    },
  },
})

export default slice.reducer
export const {
  selectAll: selectAllDutyRosters,
  selectById: selectDutyRosterById,
  selectIds: selectDutyRosterIds,
} = entityAdapter.getSelectors<RootState>((state) => state.dutyRosters)
export const selectDutyRosterStatus = (state: RootState) =>
  state.dutyRosters.status
export const selectTemplateDutyRosters = (state: RootState) => {
  return Object.values(state.dutyRosters.entities).filter(
    (dutyRoster) => dutyRoster && dutyRoster.isTemplate
  ) as IDutyRoster[]
}

function calculateDateRange(date: Moment) {
  const month = date.month()
  const start = date.date(1).hours(0).minutes(0).seconds(0).format()
  const end = date.month(0).date(31).month(month).format()
  return {
    value: start,
    end_value: end,
  }
}
