import { Button, message, Popconfirm } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { useContext, useRef, useState } from 'react'
import { useAppDispatch } from '../../../app/hooks'
import {
  create,
  EOffDutyType,
  remove,
  update,
} from '../../../features/off-duty/offDutySlice'
import { OffDutiesContext } from '../../../pages/OffDuties/OffDutiesContext'
import TimeOffForm from '../TimeOffForm'
import TimeOffFormPresentational, {
  ITimeOffFormValues,
} from '../TimeOffForm/TimeOffForm'

interface IProps {
  visible: boolean
  handleClose: () => void
}

export default function TimeOffModal(props: IProps) {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const formRef = useRef<TimeOffFormPresentational>(null)
  const dispatch = useAppDispatch()
  const offDutiesContext = useContext(OffDutiesContext)

  const handleRemove = () => {
    if (!offDutiesContext.offDutyToEdit) {
      return
    }

    dispatch(remove(offDutiesContext.offDutyToEdit.id))
    props.handleClose()
  }

  const handleOk = () => formRef.current?.submit()

  const handleFinish = async (values: ITimeOffFormValues) => {
    setConfirmLoading(true)
    const vacationData = {
      from: values.period[0],
      to: values.period[1],
      membershipId: values.membershipId,
      type: EOffDutyType.TimeOff,
      comment: values.comment,
    }

    if (offDutiesContext.offDutyToEdit) {
      dispatch(
        update({ id: offDutiesContext.offDutyToEdit.id, ...vacationData })
      ).then((action) => {
        setConfirmLoading(false)
        //@ts-ignore
        if (!action.error) {
          props.handleClose()
          message.success('Die Anfrage auf Dienstfrei wurde geändert.')
        }
      })
    } else {
      dispatch(create(vacationData)).then((action) => {
        setConfirmLoading(false)
        //@ts-ignore
        if (!action.error) {
          props.handleClose()
          message.success('Die Anfrage auf Dienstfrei wurde eingetragen')
        }
      })
    }
  }

  return (
    <Modal
      title={
        offDutiesContext.offDutyToEdit
          ? 'Dienstfrei editieren'
          : 'Dienstfrei beantragen'
      }
      visible={props.visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={props.handleClose}
      destroyOnClose
      footer={[
        offDutiesContext.offDutyToEdit?.canUpdate && (
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            loading={confirmLoading}
          >
            Speichern
          </Button>
        ),
        !offDutiesContext.offDutyToEdit && (
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            loading={confirmLoading}
          >
            Beantragen
          </Button>
        ),
        <Button key="back" onClick={props.handleClose}>
          Abbrechen
        </Button>,
        // show the delete button only if
        //  1) editing an OffDuty
        //  2) user has the right to delete
        offDutiesContext.offDutyToEdit?.canRemove && (
          <Popconfirm
            title="Soll dieser Antrag auf Dienstfrei wirklich gelöscht werden?"
            onConfirm={handleRemove}
            okText="Ja"
            cancelText="Nein"
            key="remove"
          >
            <Button type="dashed" danger>
              Löschen
            </Button>
          </Popconfirm>
        ),
      ]}
    >
      <TimeOffForm
        ref={formRef}
        handleFinish={handleFinish}
        visible={props.visible}
      />
    </Modal>
  )
}
