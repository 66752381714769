import React, { ForwardedRef, useContext, useMemo } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { selectCurrentUser } from '../../../features/currentUser/currentUserSlice'
import { selectMembershipsByEmploymentType } from '../../../features/memberships/membershipsSlice'
import { OffDutiesContext } from '../../../pages/OffDuties/OffDutiesContext'
import TimeOffForm, { ITimeOffFormValues } from './TimeOffForm'

interface IProps {
  visible: boolean
  handleFinish: (values: ITimeOffFormValues) => Promise<void>
}

const ConnectedTimeOffForm = (
  { visible, handleFinish }: IProps,
  ref: ForwardedRef<TimeOffForm>
) => {
  const currentUser: any = useAppSelector(selectCurrentUser)
  const canCreateForOtherMembers =
    currentUser.roles.includes('content_administrator') ||
    currentUser.roles.includes('offduty_administrator')

  const memberships = useAppSelector((state) =>
    selectMembershipsByEmploymentType(state, currentUser.employmentType)
  )
  const offDutyContext = useContext(OffDutiesContext)
  const filteredMemberships = useMemo(() => {
    if (!canCreateForOtherMembers) {
      return memberships.filter(
        (membership) => membership.userId === currentUser.uuid
      )
    } else {
      return memberships.filter((membership) => {
        if (offDutyContext.offDutyToEdit?.membershipId === membership.id)
          return true
        return !membership.isBlocked
      })
    }
  }, [
    canCreateForOtherMembers,
    currentUser.uuid,
    memberships,
    offDutyContext.offDutyToEdit?.membershipId,
  ])

  return (
    <TimeOffForm
      ref={ref}
      memberships={filteredMemberships}
      visible={visible}
      handleFinish={handleFinish}
      offDuty={offDutyContext.offDutyToEdit}
      currentUserUuid={currentUser.uuid}
    />
  )
}

export default React.forwardRef<TimeOffForm, IProps>(ConnectedTimeOffForm)
