import { areIntervalsOverlapping, parseISO } from 'date-fns'
import { IOffDuty } from '../../features/off-duty/offDutySlice'

export function doesOverlap(
  offDuty: IOffDuty,
  offDuties: IOffDuty[]
): boolean {
  const overlappingOffDuty = offDuties
    .filter((o) => o.membershipId === offDuty.membershipId)
    .filter((o) => o.id !== offDuty.id)
    .find((_offDuty) => {
      return areIntervalsOverlapping(
        { start: parseISO(_offDuty.from), end: parseISO(_offDuty.to) },
        { start: parseISO(offDuty.from), end: parseISO(offDuty.to) },
        { inclusive: true }
      )
    })

  return !!overlappingOffDuty
}

export function doesOverlapWithApproved(
  offDuty: IOffDuty,
  offDuties: IOffDuty[]
): boolean {
  const overlappingOffDuty = offDuties
    .filter((o) => o.membershipId === offDuty.membershipId)
    .filter((o) => o.id !== offDuty.id)
    .filter((o) => o.isApproved)
    .find((_offDuty) => {
      return areIntervalsOverlapping(
        { start: parseISO(_offDuty.from), end: parseISO(_offDuty.to) },
        { start: parseISO(offDuty.from), end: parseISO(offDuty.to) },
        { inclusive: true }
      )
    })

  return !!overlappingOffDuty
}
