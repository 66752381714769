import styles from './WorktimeRemaining.module.css'
import { IWorkLog } from '../../../features/work-logs/workLogSlice'
import { Popover, Tooltip } from 'antd'
import EmployeeDutyRosterStatistic from '../EmployeeDutyRosterStatisic'
import { useAppSelector } from '../../../app/hooks'
import { selectMembershipById } from '../../../features/memberships/membershipsSlice'
import { ScheduleOutlined } from '@ant-design/icons'
import { format, parseISO } from 'date-fns'
import locale from 'date-fns/locale/de'
import { calculateDailyWorkingHours } from '../../lib/workingTime'

interface IProps {
  workLog: IWorkLog
}

export default function WorktimeRemaining(props: IProps) {
  const employeeId = props.workLog.employee
  const employee = useAppSelector((state) =>
    selectMembershipById(state, employeeId)
  )

  if (!employee) return null

  const fullName = `${employee.firstName} ${employee.lastName}`

  // Work time details
  const vertrglAZ = props.workLog.contractualWorkingHours
  const numberWeekDays = props.workLog.numberWeekDays
  const tglAZ = calculateDailyWorkingHours(vertrglAZ, numberWeekDays)
  const planAZ = props.workLog.plannedTime
  const istAZ = props.workLog.currentWorkingTime
  const sollAZ = props.workLog.setWorkingTime

  const carryOverNextMonth = props.workLog.carryOverNextMonth
  const carryOverPreviousMonth = props.workLog.carryOver

  const deviatingHours = props.workLog.deviatingHours
  const date = parseISO(`${props.workLog.date}T00:00`)

  const content = (
    <EmployeeDutyRosterStatistic
      vertrglAZ={vertrglAZ}
      tglAZ={tglAZ}
      sollAZ={sollAZ}
      planAZ={planAZ}
      istAZ={istAZ}
      carryOverNextMonth={carryOverNextMonth}
      carryOverPreviousMonth={carryOverPreviousMonth}
      deviatingHours={deviatingHours}
    />
  )

  return (
    <div className={styles.root}>
      <Tooltip title="Statistiken anzeigen">
        <Popover
          overlayClassName="employee-statistic-popover"
          placement="right"
          content={content}
          title={`AZ Statistik ${format(date, 'MMMM yyyy', {
            locale,
          })} (${fullName})`}
          trigger="click"
          destroyTooltipOnHide
        >
          <ScheduleOutlined
            className="employeeInfoIcon"
            style={{ fontSize: '16px' }}
          />
        </Popover>
      </Tooltip>
    </div>
  )
}
