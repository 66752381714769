import { LogoutOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useContext } from 'react'
import { KeycloakContext } from '../../contexts/keycloak'

function LogoutButton() {
  const keycloak = useContext(KeycloakContext)

  return (
    <Button type="link" disabled={!keycloak} onClick={() => keycloak!.logout()} icon={<LogoutOutlined />}>
      Logout
    </Button>
  )
}

export default LogoutButton
