import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { message } from 'antd'
import { isFuture, lastDayOfMonth, parse, parseISO } from 'date-fns'
import { DropResult } from 'react-beautiful-dnd'
import config from '../../app/config'
import { AppDispatch, RootState } from '../../app/store'
import { authorizedFetch } from '../../common/lib/fetch'
import { calculatePublicHolidayOvertimeHours } from '../../common/lib/workingTime'
import { fetchFollowingWorkLogs } from '../../features/work-logs/workLogSlice'
import { selectSwapModeActive, selectSwapSource } from '../app/appSlice'
import { approve } from '../swapRequests/swapRequestsSlice'
import {replaceBaseUrl} from "../../common/lib/util/urlHelper";
import {i18n} from "../../common/lib/i18n";

export interface IWorkAssignment {
  id: string
  membershipId: string
  shiftId: string
  actualShiftId: string
  date: string
  previousState?: IWorkAssignment
  abbreviation: EWorkAssignmentAbbreviation | undefined
  exchangeable: boolean
  dutyRosterId: string
  deviatingHours: number
  isHolidayWork: boolean
  isCanceled: boolean
  canUpdate: boolean
  canRemove: boolean
  isOptimistic: boolean
}

export enum EWorkAssignmentAbbreviation {
  K = 'Krank',
  KO = 'Krank ohne Schein',
  KK = 'Kind Krank',
  WB = 'Weiterbildung',
}

export const create = createAsyncThunk<
  void,
  IWorkAssignment,
  { state: RootState }
>('workAssignments/create', async (workAssignment, thunkApi) => {
  const state = thunkApi.getState() as RootState
  // make sure the member isn't participating in the shift already
  if (
    isAlreadyPartOfShift(
      state,
      workAssignment.shiftId,
      workAssignment.date,
      workAssignment.membershipId
    )
  ) {
    message.error('Dieser Nutzer ist bereits in diese Schicht eingeteilt')
    return
  }
  const workLog = Object.values(state.workLogs.entities).find(
    (workLog: any) => {
      return (
        workLog.dutyRoster === workAssignment.dutyRosterId &&
        workLog.employee === workAssignment.membershipId
      )
    }
  )

  if (!workLog) {
    message.error('Es ist ein Fehler beim Laden der AZ aufgetreten.')
    return
  }

  // dispatch an action to add the workassignment optimistically
  thunkApi.dispatch(slice.actions.createdOptimistically(workAssignment))

  const data = {
    data: {
      type: 'work_assignment--medical_facility_work',
      attributes: {
        name: `${workAssignment.date}_${workAssignment.membershipId}`,
        date: workAssignment.date,
      },
      relationships: {
        planned_shift: {
          data: {
            type: 'shift--medical_shift',
            id: workAssignment.shiftId,
          },
        },
        employee: {
          data: {
            type: 'membership--medical_facility_member',
            id: workAssignment.membershipId,
          },
        },
        duty_roster: {
          data: {
            type: 'duty_roster--medical_facility_roster',
            id: workAssignment.dutyRosterId,
          },
        },
      },
    },
  }

  try {
    const url = `${config.backend.url}/api/work_assignment/medical_facility_work`
    let response = await authorizedFetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })

    if (response.status !== 201) {
      if (response.status === 422) {
        const violationData = await response.json()
        const errors = violationData.errors
        const code = errors[0].detail.split(': ')[1]
        message.error(i18n.t('validation-violation-' + code))
      } else {
        message.error(
          'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
        )
      }
      // Remove the optimistically created work assignment.
      thunkApi.dispatch(slice.actions.createdRejected(workAssignment))
      return thunkApi.rejectWithValue(response.statusText)
    }

    const json: any = await response.json()
    const createdWorkAssignment = map(json.data)
    createdWorkAssignment.previousState = workAssignment
    thunkApi.dispatch(slice.actions.created(createdWorkAssignment))

    // Fetch the updated workLog and following worklogs from server.
    thunkApi
      .dispatch(
        fetchFollowingWorkLogs({
          employeeId: workLog.employee,
          date: workLog.date,
        })
      )
      .then((action) => {
        if (
          //@ts-ignore error being an unknown property
          !action.error &&
          createdWorkAssignment.deviatingHours > 0
        ) {
          message.success(
            `Es wurden automatisch ${createdWorkAssignment.deviatingHours}
          Überstunden aufgrund eines Feiertags hinzugefügt.`,
            10
          )
        }
      })
  } catch (error) {
    message.error(
      'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
    )
    // Remove the optimistically created work assignment.
    thunkApi.dispatch(slice.actions.createdRejected(workAssignment))
    //@ts-ignore error being an unknown property
    return thunkApi.rejectWithValue(error.response.data)
  }
})

interface FetchByDutyRosterParams {
  dutyRosterId: string
  url?: URL
}

export const fetchByDutyRoster = createAsyncThunk(
  'workAssignments/fetchByDutyRoster',
  async (params: FetchByDutyRosterParams, thunkApi) => {
    try {
      let url = params?.url
      const baseUrl = `${config.backend.url}/api/work_assignment/medical_facility_work`
      if (!url) {
        url = new URL(baseUrl)
        const filters = {
          'fields[work_assignment--medical_facility_work]':
            'date,planned_shift,actual_shift,employee,abbreviation,exchangeable,duty_roster,deviating_hours,is_holiday_work,is_canceled',
          'filter[duty_roster.id]': params.dutyRosterId
        }
        url.search = new URLSearchParams(filters).toString()
      }

      const response = await authorizedFetch(url)

      if (response.status !== 200) {
        return thunkApi.rejectWithValue(response.statusText)
      }
      const json = await response.json()
      thunkApi.dispatch(slice.actions.pageFetched(json.data.map(map)))

      if ('next' in json.links) {
        // Jsonapi generates wrong urls
        url.href = replaceBaseUrl(baseUrl, json.links.next.href)
        await thunkApi.dispatch(
          fetchByDutyRoster({
            ...params,
            url: url,
          })
        )
      }
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const updateExchangeable = createAsyncThunk<void, any>(
  'workAssignments/updateExchangeable',
  async ({ id, exchangeable }, thunkApi) => {
    const data = {
      data: {
        type: 'work_assignment--medical_facility_work',
        id: id,
        attributes: {
          exchangeable: exchangeable,
        },
      },
    }

    const url = `${config.backend.url}/api/work_assignment/medical_facility_work/${id}`
    let response
    try {
      response = await authorizedFetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
      })

      if (response.status !== 200) {
        message.error(
          'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
        )
        return thunkApi.rejectWithValue(response.statusText)
      }

      const json: any = await response.json()
      const updatedWorkAssignment = map(json.data)
      thunkApi.dispatch(
        slice.actions.updatedExchangeable(updatedWorkAssignment)
      )
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const updateAbbreviation = createAsyncThunk<void, any>(
  'workAssignments/updateAbbreviation',
  async ({ id, abbreviation }, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const data = {
      data: {
        type: 'work_assignment--medical_facility_work',
        id: id,
        attributes: {
          abbreviation: abbreviation,
        },
      },
    }

    const workAssignment = state.workAssignments.entities[id]

    if (!workAssignment) {
      message.error('Es ist ein Fehler beim Aktualisieren aufgetreten.')
      return
    }

    const workLog = Object.values(state.workLogs.entities).find(
      (workLog: any) => {
        return (
          workLog.dutyRoster === workAssignment.dutyRosterId &&
          workLog.employee === workAssignment.membershipId
        )
      }
    )

    if (!workLog) {
      message.error('Es ist ein Fehler beim Aktualisieren aufgetreten.')
      return
    }

    const url = `${config.backend.url}/api/work_assignment/medical_facility_work/${id}`
    let response
    try {
      response = await authorizedFetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
      })

      if (response.status !== 200) {
        message.error(
          'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
        )
        return thunkApi.rejectWithValue(response.statusText)
      }

      const json: any = await response.json()
      const updatedWorkAssignment = map(json.data)
      thunkApi.dispatch(
        slice.actions.updatedAbbreviation(updatedWorkAssignment)
      )

      // Fetch the updated workLog and following worklogs from server.
      thunkApi.dispatch(
        fetchFollowingWorkLogs({
          employeeId: workLog.employee,
          date: workLog.date,
        })
      )
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const cancel = createAsyncThunk<void, any>(
  'workAssignments/cancel',
  async ({ id, value }, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const data = {
      data: {
        type: 'work_assignment--medical_facility_work',
        id: id,
        attributes: {
          is_canceled: value,
        },
      },
    }

    const updatedWorkAssignment = state.workAssignments.entities[id]

    if (!updatedWorkAssignment) {
      message.error('Es ist ein Fehler beim Aktualisieren der AZ aufgetreten.')
      return
    }

    const workLog = Object.values(state.workLogs.entities).find(
      (workLog: any) => {
        return (
          workLog.dutyRoster === updatedWorkAssignment.dutyRosterId &&
          workLog.employee === updatedWorkAssignment.membershipId
        )
      }
    )

    if (!workLog) {
      message.error('Es ist ein Fehler beim Aktualisieren der AZ aufgetreten.')
      return
    }

    const url = `${config.backend.url}/api/work_assignment/medical_facility_work/${id}`
    let response
    try {
      response = await authorizedFetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
      })

      if (response.status !== 200) {
        message.error(
          'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
        )
        return thunkApi.rejectWithValue(response.statusText)
      }

      const json: any = await response.json()
      const updatedWorkAssignment = map(json.data)
      thunkApi.dispatch(slice.actions.updatedCanceled(updatedWorkAssignment))

      // Fetch the updated workLog and following worklogs from server.
      thunkApi.dispatch(
        fetchFollowingWorkLogs({
          employeeId: workLog.employee,
          date: workLog.date,
        })
      )
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const updateActualShift = createAsyncThunk<void, any>(
  'workAssignments/updateActualShift',
  async ({ id, actualShiftId }, thunkApi) => {
    const state = thunkApi.getState() as RootState

    // Find out if there's public holiday overtime etc.
    const workAssignment = state.workAssignments.entities[id]

    if (!workAssignment) {
      message.error('Es ist ein Fehler beim Aktualisieren der AZ aufgetreten.')
      return
    }

    const workLog = Object.values(state.workLogs.entities).find(
      (workLog: any) => {
        return (
          workLog.dutyRoster === workAssignment.dutyRosterId &&
          workLog.employee === workAssignment.membershipId
        )
      }
    )

    const shift = state.shifts.entities[workAssignment.shiftId]
    const actualShift = state.shifts.entities[actualShiftId]

    if (!shift || !actualShift || !workLog) {
      message.error('Es ist ein Fehler beim Aktualisieren der AZ aufgetreten.')
      return
    }

    const data = {
      data: {
        type: 'work_assignment--medical_facility_work',
        id: id,
        relationships: {
          actual_shift: {
            data: {
              type: 'shift--medical_shift',
              id: actualShiftId,
            },
          },
        },
      },
    }

    const url = `${config.backend.url}/api/work_assignment/medical_facility_work/${id}`
    let response
    try {
      response = await authorizedFetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
      })

      if (response.status !== 200) {
        message.error(
          'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
        )
        return thunkApi.rejectWithValue(response.statusText)
      }

      const json: any = await response.json()
      const updatedWorkAssignment = map(json.data)
      thunkApi.dispatch(slice.actions.updatedActualShift(updatedWorkAssignment))

      // Fetch the updated workLog and following worklogs from server.
      thunkApi
        .dispatch(
          fetchFollowingWorkLogs({
            employeeId: workLog.employee,
            date: workLog.date,
          })
        )
        .then((action) => {
          if (
            //@ts-ignore error being an unknown property
            !action.error &&
            updatedWorkAssignment.deviatingHours !==
              workAssignment.deviatingHours
          ) {
            message.success(
              `Die Überstunden haben sich durch
          diese Aktion von ${workAssignment.deviatingHours} auf
          ${updatedWorkAssignment.deviatingHours} geändert`,
              10
            )
          }
        })
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const updateDeviatingHours = createAsyncThunk<void, any>(
  'workAssignments/updateDeviatingHours',
  async ({ id, deviatingHours }, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const data = {
      data: {
        type: 'work_assignment--medical_facility_work',
        id: id,
        attributes: {
          deviating_hours: deviatingHours,
        },
      },
    }

    const updatedWorkAssignment = state.workAssignments.entities[id]

    if (!updatedWorkAssignment) {
      message.error('Es ist ein Fehler beim Aktualisieren der AZ aufgetreten.')
      return
    }

    const workLog = Object.values(state.workLogs.entities).find(
      (workLog: any) => {
        return (
          workLog.dutyRoster === updatedWorkAssignment.dutyRosterId &&
          workLog.employee === updatedWorkAssignment.membershipId
        )
      }
    )

    if (!workLog) {
      message.error('Es ist ein Fehler beim Aktualisieren der AZ aufgetreten.')
      return
    }

    const url = `${config.backend.url}/api/work_assignment/medical_facility_work/${id}`
    let response
    try {
      response = await authorizedFetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
      })

      if (response.status !== 200) {
        message.error(
          'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
        )
        return thunkApi.rejectWithValue(response.statusText)
      }

      const json: any = await response.json()
      const updatedWorkAssignment = map(json.data)
      thunkApi.dispatch(
        slice.actions.updatedDeviatingHours(updatedWorkAssignment)
      )

      // Fetch the updated workLog and following worklogs from server.
      thunkApi.dispatch(
        fetchFollowingWorkLogs({
          employeeId: workLog.employee,
          date: workLog.date,
        })
      )
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const update = createAsyncThunk<
  void,
  DropResult,
  { state: RootState; dispatch: AppDispatch }
>('workAssignments/update', async (payload, thunkApi) => {
  const state = thunkApi.getState() as RootState
  const droppedWorkAssignment =
    state.workAssignments.entities[payload.draggableId]

  if (!droppedWorkAssignment) return
  if (!payload.destination) return

  const workLog = Object.values(state.workLogs.entities).find(
    (workLog: any) => {
      return (
        workLog.dutyRoster === droppedWorkAssignment.dutyRosterId &&
        workLog.employee === droppedWorkAssignment.membershipId
      )
    }
  )

  const [shiftId, date] = payload.destination.droppableId.split(/_/)
  const [sourceShiftId, sourceDate] = payload.source.droppableId.split(/_/)

  const shiftDate = parseISO(`${date}T00:00:00`)
  const sourceShiftDate = parseISO(`${sourceDate}T00:00:00`)
  const shift = state.shifts.entities[shiftId]
  const sourceShift = state.shifts.entities[sourceShiftId]

  if (!shift || !sourceShift || !workLog) {
    message.error('Es ist ein Fehler beim Aktualisieren der AZ aufgetreten.')
    return
  }
  // todo the following calculation is only needed for the optimistic state
  // update - the calculation moved entirely to the backend. Consider getting
  // rid of the front end calclation.
  const destinationShiftHolidayTime = calculatePublicHolidayOvertimeHours(
    shift,
    shiftDate
  )
  const sourceShiftHolidayTime = calculatePublicHolidayOvertimeHours(
    sourceShift,
    sourceShiftDate
  )

  const isHolidayWork = destinationShiftHolidayTime > 0
  const newDeviatingHours =
    droppedWorkAssignment.deviatingHours -
    sourceShiftHolidayTime +
    destinationShiftHolidayTime

  // make sure the member isn't participating in the shift already
  if (
    isAlreadyPartOfShift(
      state,
      shiftId,
      date,
      droppedWorkAssignment.membershipId
    )
  ) {
    message.error('Dieser Nutzer ist bereits in diese Schicht eingeteilt')
    return
  }

  // Check if the actual or planned shift is to be updated.
  const isActualShift = droppedWorkAssignment.actualShiftId === sourceShiftId
  const updatedPlannedShift = isActualShift
    ? droppedWorkAssignment.shiftId
    : shiftId
  const updatedActualShift = isActualShift
    ? shiftId
    : droppedWorkAssignment.actualShiftId

  // dispatch an optimistic state update
  const optimisticUpdate: IWorkAssignment = {
    id: droppedWorkAssignment.id,
    date,
    shiftId: updatedPlannedShift,
    actualShiftId: updatedActualShift,
    membershipId: droppedWorkAssignment.membershipId,
    previousState: droppedWorkAssignment,
    abbreviation: droppedWorkAssignment.abbreviation,
    exchangeable: droppedWorkAssignment.exchangeable,
    dutyRosterId: droppedWorkAssignment.dutyRosterId,
    deviatingHours: newDeviatingHours,
    isHolidayWork: isHolidayWork,
    isCanceled: droppedWorkAssignment.isCanceled,
    canUpdate: droppedWorkAssignment.canUpdate,
    canRemove: droppedWorkAssignment.canRemove,
    isOptimistic: true,
  }
  thunkApi.dispatch(slice.actions.upatedOptimistically(optimisticUpdate))

  // make + await the server request
  let data = {
    data: {
      type: 'work_assignment--medical_facility_work',
      id: droppedWorkAssignment.id,
      attributes: {
        date: date,
      },
      relationships: {},
    },
  }

  if (updatedActualShift !== '') {
    data.data.relationships = {
      planned_shift: {
        data: {
          type: 'shift--medical_shift',
          id: updatedPlannedShift,
        },
      },
      actual_shift: {
        data: {
          type: 'shift--medical_shift',
          id: updatedActualShift,
        },
      },
    }
  } else {
    data.data.relationships = {
      planned_shift: {
        data: {
          type: 'shift--medical_shift',
          id: updatedPlannedShift,
        },
      },
    }
  }

  const url = `${config.backend.url}/api/work_assignment/medical_facility_work/${droppedWorkAssignment.id}`
  let response
  try {
    response = await authorizedFetch(url, {
      method: 'PATCH',
      body: JSON.stringify(data),
    })

    if (response.status !== 200) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(response.statusText)
    }

    const json: any = await response.json()
    const updatedWorkAssignment = map(json.data)
    thunkApi.dispatch(slice.actions.updated(updatedWorkAssignment))

    // Fetch the updated workLog and following worklogs from server.
    thunkApi
      .dispatch(
        fetchFollowingWorkLogs({
          employeeId: workLog.employee,
          date: workLog.date,
        })
      )
      .then((action) => {
        if (
          //@ts-ignore error being an unknown property
          !action.error &&
          updatedWorkAssignment.deviatingHours !==
            droppedWorkAssignment.deviatingHours
        ) {
          message.success(
            `Die Überstunden haben sich durch
          diese Aktion von ${droppedWorkAssignment.deviatingHours} auf
          ${updatedWorkAssignment.deviatingHours} geändert`,
            10
          )
        }
      })
  } catch (error: any) {
    message.error(
      'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
    )
    thunkApi.dispatch(slice.actions.updateRejected(droppedWorkAssignment.id))
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const remove = createAsyncThunk<
  void,
  any,
  { state: RootState; dispatch: AppDispatch }
>('workAssignments/remove', async ({ id }, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState
    const workAssignmentToDelete = state.workAssignments.entities[id]

    if (!workAssignmentToDelete) {
      message.error(
        'Es ist ein Fehler beim Löschen aufgetreten. Bitte laden Sie die Seite neu.'
      )
      return
    }

    const workLog = Object.values(state.workLogs.entities).find(
      (workLog: any) => {
        return (
          workLog.dutyRoster === workAssignmentToDelete.dutyRosterId &&
          workLog.employee === workAssignmentToDelete.membershipId
        )
      }
    )

    if (!workLog) {
      message.error('Es ist ein Fehler beim Aktualisieren der AZ aufgetreten.')
      return
    }

    // optimistically remove the work assignment
    thunkApi.dispatch(
      slice.actions.removedOptimistically(workAssignmentToDelete)
    )

    const url = `${config.backend.url}/api/work_assignment/medical_facility_work/${id}`
    const response = await authorizedFetch(url, {
      method: 'DELETE',
    })
    if (response.status !== 204) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(response.statusText)
    }
    thunkApi.dispatch(slice.actions.removed(id))

    // Fetch the updated workLog and following worklogs from server.
    thunkApi.dispatch(
      fetchFollowingWorkLogs({
        employeeId: workLog.employee,
        date: workLog.date,
      })
    )
  } catch (error: any) {
    message.error(
      'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
    )
    return thunkApi.rejectWithValue(error.response.data)
  }
})

const entityAdapter = createEntityAdapter<IWorkAssignment>()

export const slice = createSlice({
  name: 'workAssignments',
  initialState: entityAdapter.getInitialState({
    status: 'idle',
    error: null,
  }),
  reducers: {
    created: (state, action: PayloadAction<IWorkAssignment>) => {
      entityAdapter.removeOne(state, action.payload.previousState!.id)
      entityAdapter.addOne(state, action)
    },
    createdOptimistically: entityAdapter.addOne,
    createdRejected: (state, action: PayloadAction<IWorkAssignment>) => {
      entityAdapter.removeOne(state, action.payload!.id)
    },
    pageFetched: entityAdapter.upsertMany,
    updated: (state, action: PayloadAction<IWorkAssignment>) => {
      const workAssigment = action.payload
      entityAdapter.updateOne(state, {
        id: workAssigment.id,
        changes: {
          date: workAssigment.date,
          shiftId: workAssigment.shiftId,
          isHolidayWork: workAssigment.isHolidayWork,
          deviatingHours: workAssigment.deviatingHours,
          previousState: undefined,
          isOptimistic: false,
        },
      })
    },
    updateRejected: (state, action: PayloadAction<string>) => {
      const workAssignment = state.entities[action.payload]
      if (workAssignment && workAssignment.previousState) {
        workAssignment.date = workAssignment.previousState.date
        workAssignment.shiftId = workAssignment.previousState.shiftId
        workAssignment.deviatingHours =
          workAssignment.previousState.deviatingHours
        workAssignment.isHolidayWork =
          workAssignment.previousState.isHolidayWork
        workAssignment.previousState = undefined
        workAssignment.isOptimistic = false
      }
    },
    upatedOptimistically: (state, action: PayloadAction<IWorkAssignment>) => {
      const workAssignment = action.payload
      entityAdapter.updateOne(state, {
        id: workAssignment.id,
        changes: {
          date: workAssignment.date,
          shiftId: workAssignment.shiftId,
          actualShiftId: workAssignment.actualShiftId,
          previousState: workAssignment.previousState,
          deviatingHours: workAssignment.deviatingHours,
          isHolidayWork: workAssignment.isHolidayWork,
          isOptimistic: workAssignment.isOptimistic,
        },
      })
    },
    updatedAbbreviation: (state, action: PayloadAction<IWorkAssignment>) => {
      const workAssigment = action.payload
      entityAdapter.updateOne(state, {
        id: workAssigment.id,
        changes: {
          abbreviation: workAssigment.abbreviation,
          deviatingHours: workAssigment.deviatingHours,
        },
      })
    },
    updatedActualShift: (state, action: PayloadAction<IWorkAssignment>) => {
      const workAssigment = action.payload
      entityAdapter.updateOne(state, {
        id: workAssigment.id,
        changes: {
          actualShiftId: workAssigment.actualShiftId,
          isHolidayWork: workAssigment.isHolidayWork,
          deviatingHours: workAssigment.deviatingHours,
        },
      })
    },
    updatedCanceled: (state, action: PayloadAction<IWorkAssignment>) => {
      const workAssigment = action.payload
      entityAdapter.updateOne(state, {
        id: workAssigment.id,
        changes: {
          isCanceled: workAssigment.isCanceled,
          deviatingHours: workAssigment.deviatingHours,
        },
      })
    },
    updatedDeviatingHours: (state, action: PayloadAction<IWorkAssignment>) => {
      const workAssigment = action.payload
      entityAdapter.updateOne(state, {
        id: workAssigment.id,
        changes: {
          deviatingHours: workAssigment.deviatingHours,
        },
      })
    },
    updatedExchangeable: (state, action: PayloadAction<IWorkAssignment>) => {
      const workAssigment = action.payload
      entityAdapter.updateOne(state, {
        id: workAssigment.id,
        changes: {
          exchangeable: workAssigment.exchangeable,
        },
      })
    },
    removed: entityAdapter.removeOne,
    removedOptimistically: (state, action: PayloadAction<IWorkAssignment>) => {
      const workAssignment = action.payload
      entityAdapter.updateOne(state, {
        id: workAssignment.id,
        changes: {
          isOptimistic: true,
        },
      })
    },
    removeRejected: (state, action: PayloadAction<string>) => {
      const workAssignment = state.entities[action.payload]
      if (workAssignment) {
        workAssignment.isOptimistic = false
      }
    },
  },
  extraReducers: {
    [approve.fulfilled.toString()]: (state, action) => {
      const response = action.payload.data
      const newSource = Object.assign({}, state.entities[response.targetUuid], {
        id: response.newTargetUuid,
        membershipId: state.entities[response.sourceUuid]!.membershipId,
      })
      entityAdapter.addOne(state, newSource)

      const newTarget = Object.assign({}, state.entities[response.sourceUuid], {
        id: response.newSourceUuid,
        membershipId: state.entities[response.targetUuid]!.membershipId,
      })
      entityAdapter.addOne(state, newTarget)

      entityAdapter.removeOne(state, response.targetUuid)
      entityAdapter.removeOne(state, response.sourceUuid)
    },
  },
})

export const {
  selectAll: selectAllWorkAssignments,
  selectById: selectWorkAssignmentById,
  selectIds: selectWorkAssignmentIds,
} = entityAdapter.getSelectors<RootState>((state) => state.workAssignments)
const getShiftId = (state: RootState, shiftId: string): string => shiftId
const getDate = (state: RootState, shiftId: string, date: string): string =>
  date
const selectWorkAssignmentsByShiftAndDate = createSelector(
  [selectAllWorkAssignments, getShiftId, getDate],
  (workAssignments, shiftId, date) => {
    return Object.values(workAssignments).filter(
      (workAssignment) =>
        (workAssignment.shiftId === shiftId ||
          workAssignment.actualShiftId === shiftId) &&
        workAssignment.date === date
    )
  }
)
export const selectWorkAssignmentsOfShift = createSelector(
  [
    selectAllWorkAssignments,
    getShiftId,
    getDate,
    selectSwapModeActive,
    selectSwapSource,
  ],
  (workAssignments, shiftId, dateString, isSwapModeActive, swapSource) => {
    let result = Object.values(workAssignments).filter(
      (workAssignment) =>
        (workAssignment.shiftId === shiftId ||
          workAssignment.actualShiftId === shiftId) &&
        workAssignment.date === dateString
    )

    if (isSwapModeActive && swapSource) {
      // filter out all workassignments which are assigned to the swap target's
      // employee, except the swap target itself and that are not in the past.
      // Also filter out cancelled assignments and assignments with abbreviations.
      result = result.filter(
        (workAssignment) => {
          // accept the swap target itself
          if (workAssignment.id === swapSource.id)
            return true

          // Filter out assignments of the swap requesting member
          if (workAssignment.membershipId === swapSource.membershipId)
            return false

          // Filter out past dates
          if (!isFuture(parse(workAssignment.date, 'yyyy-MM-dd', new Date())))
            return false

          // Filter out cancelled assignments
          if (workAssignment.isCanceled)
            return false

          // Filter out assignments with abbreviations.
          if (workAssignment.abbreviation !== null)
            return false

          // Filter out assignments that are not exchangeable
          if (!workAssignment.exchangeable)
            return false

          // Filter out assignments that are not exchangeable
          if (!workAssignment.exchangeable)
            return false

          return true
        }
      )
    }
    return result
  }
)
export const selectByMonthAndYear = createSelector(
  [
    selectAllWorkAssignments,
    (state: RootState, month: number, year: number) => month,
    (state: RootState, month: number, year: number) => year,
  ],
  (workAssignments, month, year) => {
    const firstDayOfMonth = parse(`${year} ${month + 1}`, 'yyyy M', new Date())
    const firstDayOfMonthTimestamp = firstDayOfMonth.getTime()
    const lastDayOfMonthTimestamp = lastDayOfMonth(firstDayOfMonth).getTime()
    return workAssignments.filter((workAssignment) => {
      const waTimestamp = parseISO(workAssignment.date).getTime()
      return (
        waTimestamp >= firstDayOfMonthTimestamp &&
        waTimestamp <= lastDayOfMonthTimestamp
      )
    })
  }
)

const getMembershipId = (state: RootState, membershipId: string): string =>
  membershipId
export const selectWorkAssignmentsByMembershipId = createSelector(
  [selectAllWorkAssignments, getMembershipId],
  (workAssignments, membershipId) =>
    Object.values(workAssignments).filter(
      (workAssignments) => workAssignments.membershipId === membershipId
    )
)
export default slice.reducer

function map(json: any): IWorkAssignment {
  return {
    id: json.id,
    date: json.attributes.date,
    shiftId: json.relationships.planned_shift.data.id,
    actualShiftId: json.relationships.actual_shift.data
      ? json.relationships.actual_shift?.data.id
      : '',
    membershipId: json.relationships.employee.data.id,
    abbreviation: json.attributes.abbreviation,
    exchangeable: json.attributes.exchangeable,
    dutyRosterId: json.relationships.duty_roster.data.id,
    deviatingHours: Number.parseFloat(json.attributes.deviating_hours),
    isHolidayWork: json.attributes.is_holiday_work,
    isCanceled: json.attributes.is_canceled,
    canRemove: json.links?.mutableControls
      ? json.links.mutableControls.meta.linkParams.rel.indexOf('remove') !== -1
      : false,
    canUpdate: json.links?.mutableControls
      ? json.links.mutableControls.meta.linkParams.rel.indexOf('update') !== -1
      : false,
    isOptimistic: false,
  }
}

function isAlreadyPartOfShift(
  state: RootState,
  shiftId: string,
  date: string,
  membershipId: string
) {
  const workAssigments = selectWorkAssignmentsByShiftAndDate(
    state,
    shiftId,
    date
  )

  return (workAssigments || []).find(
    (workAssignment) => workAssignment.membershipId === membershipId
  )
}
