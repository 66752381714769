import { useAppSelector } from '../../../../../../app/hooks'
import HolidayRepository from '../../../../../../common/lib/util/holidays'
import { selectSchoolHolidaysByDate } from '../../../../../../features/school-holidays/schoolHolidaySlice'
import MonthHeaderDay from './MonthHeaderDayNumber'

interface Props {
  day: Date
  dayOfYear: number
}
const Container = (props: Props) => {
  const schoolHolidays = useAppSelector((state) =>
    selectSchoolHolidaysByDate(state, props.day)
  )
  const schoolHoliday = schoolHolidays[0]
  const holiday = HolidayRepository.isHoliday(props.day)
  const nameOfPublicHoliday =
    Array.isArray(holiday) && holiday[0].type === 'public'
      ? holiday[0].name
      : ''

  return (
    <MonthHeaderDay
      day={props.day}
      dayOfYear={props.dayOfYear}
      nameOfPublicHoliday={nameOfPublicHoliday}
      nameOfSchoolHoliday={schoolHoliday?.name}
    />
  )
}

export default Container
