import { Dropdown, Menu } from 'antd'
import Text from 'antd/lib/typography/Text'
import Avatar from '../Avatar'
import LogoutButton from '../LogoutButton/LogoutButton'
import styles from './AccountMenu.module.css'

interface Props {
  firstName: string
  lastName: string
  avatarUrl: string
}

const AccountMenu = (props: Props) => {
  const menu = (
    <Menu>
      <Menu.Item key={'logout'}>
        <LogoutButton />
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement="bottomRight" arrow trigger={['click']}>
      <div className={styles.root}>
        <Avatar url={props.avatarUrl} className={styles.avatar} />
        <Text>
          {props.firstName} {props.lastName}
        </Text>
      </div>
    </Dropdown>
  )
}

export default AccountMenu
