import { useAppSelector } from '../../../../app/hooks'
import { selectDragInfo } from '../../../../features/app/appSlice'
import DropDisabledInfo from './DropDisabledInfo'

interface IProps {
  isVacation: boolean
  isDayOfDifferentMonth: boolean
}

function ConnectedDropDisabledInfo(props: IProps) {
  const { isDragging } = useAppSelector(selectDragInfo)

  if (!isDragging) return null
  if (!props.isVacation && !props.isDayOfDifferentMonth) return null

  return <DropDisabledInfo {...props} />
}

export default ConnectedDropDisabledInfo
