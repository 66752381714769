import { StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  root: {
    backgroundColor: '#c4b0b0',
    width: 12,
    height: 12,
    marginVertical: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
  },
  notExchangeable: {
    border: .5,
  },
  title: {
    fontSize: 6,
  },
})

export default styles
