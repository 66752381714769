import {
  addDays,
  differenceInCalendarDays,
  getDate,
  parseISO,
} from 'date-fns'
import { RootState } from '../../app/store'
import {
  getWeekendsAndPublicHolidaysOfInterval,
  isNoWorkDay
} from '../../common/lib/workingTime'
import { selectVacationsOfMonthClamped } from '../off-duty/offDutySlice'
import { selectByMonthAndYear } from '../workAssignments/workAssignmentsSlice'

export function getOffDutiesForExport(
  state: RootState,
  month: number,
  year: number,
  employees: any[]
) {
  let offDuties = selectVacationsOfMonthClamped(state, month, year)
  const result: any = []

  offDuties.forEach((offDuty) => {
    if (!offDuty) return

    const startDate = parseISO(offDuty.from)
    const endDate = parseISO(offDuty.to)
    const days = differenceInCalendarDays(endDate, startDate) + 1
    const employee = employees.find((member: any) => {
      return offDuty.membershipId === member.id
    })
    const validWorkDays = employee?.validWeekDays || []
    let dayIndex = getDate(startDate)
    let currentDate = startDate
    const { weekends, publicHolidays } = getWeekendsAndPublicHolidaysOfInterval(startDate, endDate)
    for (let i = 0; i < days; i++) {
      // skip non eligable days
      if (isNoWorkDay(currentDate, weekends, publicHolidays, validWorkDays)) {
        currentDate = addDays(currentDate, 1)
        continue
      }

      result.push({
        dayIndex: dayIndex + i,
        employeeId: offDuty.membershipId,
      })
      currentDate = addDays(currentDate, 1)
    }
  })

  return result
}

export function getDutiesForExport(
  state: RootState,
  month: number,
  year: number
) {
  const duties = selectByMonthAndYear(state, month, year)

  return duties.map((duty) => {
    return {
      dayIndex: getDate(parseISO(duty.date)),
      shiftId: duty.shiftId,
      actualShiftId: duty.actualShiftId,
      employeeId: duty.membershipId,
      exchangeable: duty.exchangeable,
      canceled: duty.isCanceled,
      abbreviation: duty.abbreviation,
      deviatingHours: duty.deviatingHours
    }
  })
}
