import { configureStore, ThunkAction, Action, isPlain } from '@reduxjs/toolkit'
import shiftsReducer from '../features/shifts/shiftsSlice'
import workAssignmentsReducer from '../features/workAssignments/workAssignmentsSlice'
import offDutiesReducer from '../features/off-duty/offDutySlice'
import appReducer from '../features/app/appSlice'
import membershipsReducer from '../features/memberships/membershipsSlice'
import dutyRostersReducer from '../features/duty-rosters/dutyRostersSlice'
import authenticationReducer from '../features/authentication/authenticationSlice'
import workLogReducer from '../features/work-logs/workLogSlice'
import vacationLogReducer from '../features/offduty-logs/vacationLogSlice'
import currentUserReducer from '../features/currentUser/currentUserSlice'
import swapRequestReducer from '../features/swapRequests/swapRequestsSlice'
import schoolHolidayReducer from "../features/school-holidays/schoolHolidaySlice";

export const store = configureStore({
  reducer: {
    shifts: shiftsReducer,
    workAssignments: workAssignmentsReducer,
    offDuties: offDutiesReducer,
    app: appReducer,
    memberships: membershipsReducer,
    dutyRosters: dutyRostersReducer,
    authentication: authenticationReducer,
    workLogs: workLogReducer,
    currentUser:  currentUserReducer,
    swapRequests: swapRequestReducer,
    vacationLogs: vacationLogReducer,
    schoolHolidays: schoolHolidayReducer,
  },
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: {
      isSerializable: (value: any) => value instanceof Date || isPlain(value)
    }
  })
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
