import {
  CalendarOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  TableOutlined
} from '@ant-design/icons'
import { Button, PageHeader, Spin, Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'
import { addYears, format, getYear, subYears } from 'date-fns'
import deLocale from 'date-fns/locale/de'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import TimeOffModal from '../../common/components/TimeOffModal/TimeOffModal'
import VacationApprovalModal from '../../common/components/VacationApprovalModal/VacationApprovalModal'
import VacationModal from '../../common/components/VacationModal/VacationModal'
import { selectCurrentUser, selectCurrentUserCreatePermissions } from '../../features/currentUser/currentUserSlice'
import {
  EOffDutyType, fetch, IOffDuty,
  selectOffDutiesByYear
} from '../../features/off-duty/offDutySlice'
import CalendarView from './components/CalendarView/CalendarView'
import TableView from './components/TableView'
import styles from './OffDuties.module.css'
import { OffDutiesContext } from './OffDutiesContext'

export default function OffDuties() {
  const dispatch = useAppDispatch()
  const [date, setDate] = useState(new Date())
  const offDuties = useAppSelector((state) =>
    selectOffDutiesByYear(state, getYear(date))
  )
  const currentUser = useAppSelector(selectCurrentUser)
  const isLoading = useAppSelector(state => state.offDuties.isLoading)
  const [isVacationModalVisible, setVacationModalVisible] = useState(false)
  const [offDutyToEdit, setOffDutyToEdit] = useState<IOffDuty | undefined>()
  const [isTimeOffModalVisible, setTimeOffModalVisible] = useState(false)
  const [isVacationApprovalModalVisible, setVacationApprovalModalVisible] =
    useState(false)
  const [isCalendarViewOn, setCalendarViewOn] = useState(true)
  const [calendarOffDutyType, setCalendarOffDutyType] = useState(
    EOffDutyType.Vacation
  )

  // load the off duties
  useEffect(() => {
    async function loadOffDuties() {
      dispatch(fetch({ approvedOnly: false }))
    }
    loadOffDuties()
  }, [dispatch])

  const showVacationModal = (offDuty?: IOffDuty) => {
    setOffDutyToEdit(offDuty)
    setVacationModalVisible(true)
  }
  const hideVacationModal = () => setVacationModalVisible(false)

  const showTimeOffModal = (offDuty?: IOffDuty) => {
    setOffDutyToEdit(offDuty)
    setTimeOffModalVisible(true)
  }
  const hideTimeOffModal = () => setTimeOffModalVisible(false)

  const showVacationApprovalModal = (offDuty: IOffDuty) => {
    setOffDutyToEdit(offDuty)
    setVacationApprovalModalVisible(true)
  }
  const hideVacationApprovalModal = () => setVacationApprovalModalVisible(false)

  const showCalendarView = () => setCalendarViewOn(true)
  const hideCalendarView = () => {
    setCalendarViewOn(false)
    setDate(new Date())
    setCalendarOffDutyType(EOffDutyType.Vacation)
  }
  const decreaseCalendarYear = () => setDate(subYears(date, 1))
  const increaseCalendarYear = () => setDate(addYears(date, 1))

  const createPermissions: any = useAppSelector(
    selectCurrentUserCreatePermissions
  )
  const canCreateOffduty = createPermissions && createPermissions['off_duty']

  // we need to have the current user
  if(!currentUser.id) {
    return <Spin />
  }

  return (
    <OffDutiesContext.Provider
      value={{
        showVacationForm: showVacationModal,
        showTimeOffForm: showTimeOffModal,
        showApprovalForm: showVacationApprovalModal,
        offDutyToEdit,
        date,
      }}
    >
      <PageHeader
        title="Urlaub / Dienstfrei"
        className={styles.pageHeader}
        extra={[
          <span className={styles.yearFilter} key="year-filter">
            <Tooltip title={'Vorheriges Jahr'}>
              <Button
                onClick={decreaseCalendarYear}
                icon={<CaretLeftOutlined />}
              />
            </Tooltip>

            <Text className={styles.yearFilterLabel}>
              {format(date, 'yyyy', { locale: deLocale })}
            </Text>

            <Tooltip title={'Nächstes Jahr'}>
              <Button
                onClick={increaseCalendarYear}
                icon={<CaretRightOutlined />}
              />
            </Tooltip>
          </span>,
          isCalendarViewOn ? (
            <Tooltip key="table-view" title={'Tabellenansicht'}>
              <Button onClick={hideCalendarView} icon={<TableOutlined />} />
            </Tooltip>
          ) : (
            <Tooltip key="calendar-view" title={'Kalenderansicht'}>
              <Button onClick={showCalendarView} icon={<CalendarOutlined />} />
            </Tooltip>
          ),
          <Button
            onClick={() => showVacationModal()}
            key="vacation"
            disabled={!canCreateOffduty}
          >
            Urlaub einreichen
          </Button>,
          <Button
            onClick={() => showTimeOffModal()}
            key="timeoff"
            disabled={!canCreateOffduty}
          >
            Dienstfrei beantragen
          </Button>,
        ]}
      />

      {!isCalendarViewOn && <TableView offDuties={offDuties} />}

      {isCalendarViewOn && (
        <CalendarView
          offDutyType={calendarOffDutyType}
          handleOffDutyTypeChanged={setCalendarOffDutyType}
          offDuties={offDuties}
          year={getYear(date)}
          isLoading={isLoading}
        />
      )}

      {isVacationModalVisible && (
        <VacationModal
          visible={isVacationModalVisible}
          handleClose={hideVacationModal}
        />
      )}
      {isTimeOffModalVisible && (
        <TimeOffModal
          visible={isTimeOffModalVisible}
          handleClose={hideTimeOffModal}
        />
      )}
      {isVacationApprovalModalVisible && (
        <VacationApprovalModal
          visible={isVacationApprovalModalVisible}
          handleClose={hideVacationApprovalModal}
        />
      )}
    </OffDutiesContext.Provider>
  )
}
