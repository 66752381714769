import {
  ClockCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EnterOutlined,
  ExclamationOutlined,
  PaperClipOutlined,
  QuestionCircleFilled,
  RetweetOutlined,
} from '@ant-design/icons'
import { Badge, Dropdown, Menu, message, Modal, Popover } from 'antd'
import cs from 'classnames'
import { isFuture } from 'date-fns'
import { memo, useCallback, useContext, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import DeviatingHoursModal from '../../common/components/DeviatingHoursModal/DeviatingHoursModal'
import Initials from '../../common/components/Initials'
import SwapWorkAssignmentModal from '../../common/components/SwapWorkAssignmentModal/SwapWorkAssignmentModal'
import WorkAssignmentPopover from '../../common/components/WorkAssignmentPopover/WorkAssignmentPopover'
import { ShiftsPerDayContext } from '../../common/contexts/shiftsPerDayContext'
import { enumKeys } from '../../common/lib/enum'
import {
  selectDragInfo,
  selectSwapModeActive,
  selectSwapSource,
  swapModeActivated,
} from '../app/appSlice'
import { selectCurrentUser } from '../currentUser/currentUserSlice'
import {
  IMembership,
  selectMembershipById,
} from '../memberships/membershipsSlice'
import { IShift } from '../shifts/shiftsSlice'
import { selectSwapRequestByWorkAssignmentId } from '../swapRequests/swapRequestsSlice'
import styles from './WorkAssignment.module.css'
import {
  cancel,
  EWorkAssignmentAbbreviation,
  IWorkAssignment,
  remove,
  updateAbbreviation,
  updateActualShift,
  updateExchangeable,
} from './workAssignmentsSlice'
const { SubMenu } = Menu

interface IProps {
  workAssignment: IWorkAssignment
  noDropdown: boolean
  membership: IMembership
}

export default memo(function WorkAssignment(props: IProps) {
  const [isHovered, setHovered] = useState(false)
  const [isSwapWorkAssignmentModalVisible, setSwapWorkAssignmentModalVisible] =
    useState(false)
  const dispatch = useAppDispatch()
  const shiftsPerDayContext = useContext(ShiftsPerDayContext)
  const isSwapModeActive = useAppSelector(selectSwapModeActive)
  const dragInfo = useAppSelector(selectDragInfo)
  const swapSource = useAppSelector(selectSwapSource)
  const [isDeviatingHoursModalVisible, setDeviatingHoursModalVisible] =
    useState(false)
  const membership = useAppSelector((state) =>
    selectMembershipById(state, props.workAssignment.membershipId)
  )
  const swapRequest = useAppSelector((state) =>
    selectSwapRequestByWorkAssignmentId(state, props.workAssignment.id)
  )
  const hasPendingSwapRequest = !!swapRequest
  const hideDeviatingHoursModal = () => setDeviatingHoursModalVisible(false)
  const isSwapSource = swapSource?.id === props.workAssignment.id
  const isEligableSwapDestination = isSwapModeActive &&
    !isSwapSource &&
    !props.workAssignment.isCanceled &&
    props.workAssignment.abbreviation === null

  const handleClick = useCallback(
    (e) => {
      const action = e.key
      const showDeviatingHoursModal = () => setDeviatingHoursModalVisible(true)
      const { confirm } = Modal

      function confirmAction(
        title: string,
        content: string,
        callback: any,
        argObject: any
      ) {
        confirm({
          title: title,
          icon: <QuestionCircleFilled />,
          content: content,
          onOk() {
            dispatch(callback(argObject))
          },
          onCancel() {},
        })
      }

      if (!props.workAssignment) return null

      if (action.startsWith('abbreviation')) {
        const value = action.split(
          '-'
        )[1] as keyof typeof EWorkAssignmentAbbreviation
        const currentAbbreviation = props.workAssignment.abbreviation
        const newAbbreviation =
          currentAbbreviation?.toString() !== value ? value : ''
        dispatch(
          updateAbbreviation({
            id: props.workAssignment.id,
            abbreviation: newAbbreviation,
          })
        )
      } else if (action.startsWith('updateActualShift')) {
        const shiftId = action.split('/')[1]

        if (!shiftsPerDayContext) {
          message.error('Die Schicht konnte leider nicht geändert werden.')
          return
        }
        const newShift = shiftsPerDayContext.find((shift: IShift) => {
          return shift.id === shiftId
        })
        if (!newShift) {
          message.error('Die Schicht konnte leider nicht geändert werden.')
          return
        }
        const title = `Soll auf ${newShift.longName} gewechselt werden?`
        const content =
          'Nach diesem Schritt wird von der geplanten Schicht auf die tatsächlich stattgefundene Schicht gewechselt. Das hat evtl. Auswirkungen auf die Anrechnung der AZ'
        confirmAction(title, content, updateActualShift, {
          id: props.workAssignment.id,
          actualShiftId: shiftId,
        })
      } else {
        switch (action) {
          case 'exchangeable': {
            const newExchangeable = !props.workAssignment.exchangeable
            dispatch(
              updateExchangeable({
                id: props.workAssignment.id,
                exchangeable: newExchangeable,
              })
            )
            break
          }
          case 'deviatingHours': {
            showDeviatingHoursModal()
            break
          }
          case 'cancel': {
            const title = props.workAssignment.isCanceled
              ? 'Soll die Stornierung rückgängig gemacht werden?'
              : 'Soll dieser Dienst wirklich storniert werden?'
            const content = props.workAssignment.isCanceled
              ? 'Wird die Stornierung rückgängig gemacht, wird die AZ wieder anerkannt.'
              : 'Wird der Dienst storniert, wird die AZ nicht anerkannt. Nutzen Sie diese Funktion z.B. wenn die eingeplante Person nicht zum Dienst erschienen ist.'
            confirmAction(title, content, cancel, {
              id: props.workAssignment.id,
              value: !props.workAssignment.isCanceled,
            })
            break
          }
          case 'delete': {
            const title = 'Soll dieser Dienst wirklich gelöscht werden?'
            const content =
              'Wird der Dienst gelöscht, wird seine AZ nicht anerkannt und der Dienst wird unwideruflich entfernt. Nutzen Sie diese Funktion z.B. wenn ein Eintrag fehlerhaft oder aus Versehen angelegt wurde aber keine Relevanz hat.'
            confirmAction(title, content, remove, {
              id: props.workAssignment.id,
            })
            break
          }
          case 'swap': {
            dispatch(swapModeActivated(props.workAssignment.id))
            break
          }
          case 'swap-edit': {
            setSwapWorkAssignmentModalVisible(true)
            break
          }
          default: {
            message.error(`Unbekannte Aktion (${action})`)
          }
        }
      }
    },
    [dispatch, props.workAssignment, shiftsPerDayContext]
  )
  const handleSwapModeClick = useCallback(() => {
    if (!isSwapModeActive) return

    if (swapSource!.id === props.workAssignment.id) return

    setSwapWorkAssignmentModalVisible(true)
  }, [isSwapModeActive, props.workAssignment.id, swapSource])

  let selectedKeys = []
  if (props.workAssignment.abbreviation) {
    selectedKeys.push(`abbreviation-${props.workAssignment.abbreviation}`)
  }
  const shiftSubMenu = shiftsPerDayContext!.map((shift: IShift) => {
    return (
      shift.id !== props.workAssignment.shiftId && (
        <Menu.Item key={`updateActualShift/${shift.id}`}>
          {shift.longName}
        </Menu.Item>
      )
    )
  })

  const dateIsFuture = isFuture(new Date(props.workAssignment.date))
  const currentUser: any = useAppSelector(selectCurrentUser)
  const userIsContentAdmin = currentUser.roles.includes('content_administrator')
  const selectableAsSwapSource =
    !hasPendingSwapRequest &&
    props.workAssignment.exchangeable &&
    dateIsFuture &&
    !props.workAssignment.isCanceled &&
    props.workAssignment.abbreviation === null &&
    (props.workAssignment.membershipId === currentUser.membershipId ||
      userIsContentAdmin)

  const menu = (
    <Menu onClick={handleClick} selectedKeys={selectedKeys}>
      {!props.workAssignment.isCanceled && props.workAssignment.canUpdate && (
          <SubMenu
            key="sub1"
            icon={<PaperClipOutlined />}
            disabled={!props.workAssignment.canUpdate}
            title="Kürzel"
          >
            {enumKeys(EWorkAssignmentAbbreviation).map((key) => {
              return (
                <Menu.Item key={`abbreviation-${key}`}>
                  {key} - {EWorkAssignmentAbbreviation[key]}
                </Menu.Item>
              )
            })}
          </SubMenu>
        )
      }
      {!props.workAssignment.isCanceled &&
       props.workAssignment.abbreviation === null &&
       props.workAssignment.canUpdate &&
      (
        <>
          <Menu.Item
            key="exchangeable"
            icon={<ExclamationOutlined />}
            disabled={!props.workAssignment.canUpdate}
          >
            {props.workAssignment.exchangeable
              ? "als 'nicht tauschbar' markieren"
              : "als 'tauschbar' markieren"}
          </Menu.Item>
          {hasPendingSwapRequest && (
            <Menu.Item
              key="swap-edit"
              icon={<RetweetOutlined />}
              disabled={!props.workAssignment.canUpdate}
            >
              Tausch bearbeiten
            </Menu.Item>
          )}
          {!dateIsFuture && (
            <Menu.Item
              key="deviatingHours"
              icon={<ClockCircleOutlined />}
              disabled={!props.workAssignment.canUpdate}
            >
              Abweichende Stunden erfassen
            </Menu.Item>
          )}
          {!props.workAssignment.actualShiftId && (
            <SubMenu
              disabled={!props.workAssignment.canUpdate}
              key="sub2"
              icon={<EnterOutlined />}
              title="tatsächlichen Dienst erfassen"
            >
              {shiftSubMenu}
            </SubMenu>
          )}
        </>
      )}
      {selectableAsSwapSource && (
        <Menu.Item key="swap" icon={<RetweetOutlined />}>
          Tauschen
        </Menu.Item>
      )}
      {props.workAssignment.canUpdate &&
       props.workAssignment.abbreviation === null &&
      (
        <Menu.Item
          key="cancel"
          danger
          icon={<CloseOutlined />}
          disabled={!props.workAssignment.canUpdate}
        >
          Stornieren
        </Menu.Item>
      )}
      {props.workAssignment.canRemove && (
        <Menu.Item
          key="delete"
          danger
          icon={<DeleteOutlined />}
          disabled={!props.workAssignment.canRemove}
        >
          Löschen
        </Menu.Item>
      )}
    </Menu>
  )

  let abbreviationBadgeContent: any = 0
  if (props.workAssignment?.abbreviation) {
    abbreviationBadgeContent = props.workAssignment.abbreviation
  }

  const rootClasses = cs({
    [styles.root]: true,
    [styles.rootNotExchangeable]: !props.workAssignment.exchangeable,
    [styles.isHovered]: isHovered,
    [styles.rootClickable]: isEligableSwapDestination,
    [styles.rootIsSwapSource]: isSwapSource,
    [styles.rootIsSwapRequest]: hasPendingSwapRequest,
  })

  const dropDownIsActive =
    (props.workAssignment.canUpdate ||
      props.workAssignment.canRemove ||
      selectableAsSwapSource) &&
    !props.noDropdown

  if (!membership || !shiftsPerDayContext) return null

  const shift = shiftsPerDayContext.find((shift: IShift) => {
    return shift.id === props.workAssignment.shiftId
  })!

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']} disabled={!dropDownIsActive}>
        <div
          className={rootClasses}
          onMouseOver={() => isEligableSwapDestination && setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={handleSwapModeClick}
        >
          <Popover
            content={
              <WorkAssignmentPopover
                membership={membership!}
                workAssignment={props.workAssignment}
              />
            }
            trigger={!isSwapModeActive ? 'hover' : ''}
            visible={dragInfo.isDragging ? false : undefined}
          >
            <Badge
              count={abbreviationBadgeContent}
              size="small"
              offset={[0, 0]}
            >
              <Badge
                count={props.workAssignment?.isCanceled ? 'X' : 0}
                size="small"
                offset={[-30, 22]}
              >
                <Initials
                  small
                  membership={props.membership}
                  isLoading={props.workAssignment.isOptimistic}
                />
              </Badge>
            </Badge>
          </Popover>
        </div>
      </Dropdown>
      <DeviatingHoursModal
        visible={isDeviatingHoursModalVisible}
        handleClose={hideDeviatingHoursModal}
        currentDeviatingHours={props.workAssignment.deviatingHours}
        date={props.workAssignment.date}
        workAssignmentId={props.workAssignment.id}
        membership={membership}
        shift={shift}
      />
      <SwapWorkAssignmentModal
        visible={isSwapWorkAssignmentModalVisible}
        handleClose={() => setSwapWorkAssignmentModalVisible(false)}
        swapDestination={props.workAssignment.id}
        swapRequest={swapRequest}
      />
    </>
  )
})
