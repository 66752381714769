import { SwapOutlined } from '@ant-design/icons'
import { Modal, message, Button, Popconfirm } from 'antd'
import Text from 'antd/lib/typography/Text'
import { useState } from 'react'
import { useStore } from 'react-redux'
import { useAppDispatch } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { format } from 'date-fns'
import {
  approve,
  create,
  ISwapRequest,
  remove,
} from '../../../features/swapRequests/swapRequestsSlice'
import { IWorkAssignment } from '../../../features/workAssignments/workAssignmentsSlice'
import WorkAssignmentSwapEntity from './components/WorkAssignmentSwapEntity/WorkAssignmentSwapEntity'
import styles from './SwapWorkAssignmentModal.module.css'
import { useDutyRosterContext } from '../../contexts/dutyRoster'

interface IProps {
  visible: boolean
  handleClose: () => void
  swapRequest?: ISwapRequest
  swapDestination: string // id of workassignment
}

const SwapWorkAssignmentModal = (props: IProps) => {
  const [isLoading, setLoading] = useState(false)
  const [isSwapping, setSwapping] = useState(false)
  const [isRemoving, setRemoving] = useState(false)
  const dispatch = useAppDispatch()
  const store = useStore()
  const state: RootState = store.getState()
  const context = useDutyRosterContext()

  let sourceWorkAssignment: IWorkAssignment | undefined
  let destinationWorkAssignment: IWorkAssignment | undefined
  if (props.swapRequest) {
    sourceWorkAssignment =
      state.workAssignments.entities[props.swapRequest.source]
    destinationWorkAssignment =
      state.workAssignments.entities[props.swapRequest.destination]
  } else if (state.app.swapSource) {
    sourceWorkAssignment = state.workAssignments.entities[state.app.swapSource]
    destinationWorkAssignment =
      state.workAssignments.entities[props.swapDestination]
  }

  if (!sourceWorkAssignment || !destinationWorkAssignment) {
    return null
  }

  const handleOk = () => {
    setLoading(true)
    dispatch(
      create({
        source: sourceWorkAssignment!.id,
        destination: destinationWorkAssignment!.id,
        dutyRosterId: sourceWorkAssignment!.dutyRosterId,
      })
    ).then((action) => {
      setLoading(false)
      //@ts-ignore error being an unknown property
      if (!action.error) {
        props.handleClose()
        message.success('Es wurde ein Tauschgesuch erstellt')
      } else {
        message.error('Es ist ein unbekannter Fehler aufgetreten. 🚧')
      }
    })
  }

  const handleSwap = () => {
    setSwapping(true)
    if (
      !context.firstDayOfMonth ||
      !sourceWorkAssignment ||
      !destinationWorkAssignment
    ) {
      message.error('Es ist ein unbekannter Fehler aufgetreten. 🚧')
      return
    }
    const workLogDate = format(context.firstDayOfMonth, 'yyyy-MM-dd')
    const params = {
      swapRequest: props.swapRequest,
      sourceMemberId: sourceWorkAssignment.membershipId,
      destinationMemberId: destinationWorkAssignment.membershipId,
      date: workLogDate,
    }
    dispatch(approve(params)).then((action) => {
      setSwapping(false)
      //@ts-ignore error being an unknown property
      if (!action.error) {
        props.handleClose()
        message.success('Die Dienste wurden getauscht ✓')
      } else {
        message.error('Es ist ein unbekannter Fehler aufgetreten. 🚧')
      }
    })
  }

  const handleRemove = () => {
    dispatch(remove(props.swapRequest!.id)).then((action) => {
      setRemoving(false)
      //@ts-ignore error being an unknown property
      if (!action.error) {
        props.handleClose()
        message.success('Der Tausch wurde gelöscht')
      } else {
        message.error('Es ist ein unbekannter Fehler aufgetreten. 🚧')
      }
    })
    props.handleClose()
  }

  return (
    <Modal
      visible={props.visible}
      onCancel={props.handleClose}
      title="Dienste tauschen"
      confirmLoading={isLoading}
      width={550}
      footer={[
        props.swapRequest && (
          <Popconfirm
            title="Soll dieser geplante Tausch wirklich gelöscht werden?"
            onConfirm={handleRemove}
            okText="Ja"
            cancelText="Nein"
            onVisibleChange={(visible) => setRemoving(visible)}
          >
            <Button type="dashed" danger loading={isRemoving}>
              Löschen
            </Button>
          </Popconfirm>
        ),
        !props.swapRequest && (
          <Button onClick={props.handleClose}>Abbrechen</Button>
        ),
        !props.swapRequest && (
          <Button type="primary" onClick={handleOk} loading={isLoading}>
            OK
          </Button>
        ),
        props.swapRequest && (
          <Button type="primary" onClick={handleSwap} loading={isSwapping}>
            Tausch durchführen
          </Button>
        ),
      ]}
    >
      <div className={styles.swapEntities}>
        <WorkAssignmentSwapEntity workAssignment={sourceWorkAssignment} />
        <SwapOutlined className={styles.icon} />
        <WorkAssignmentSwapEntity workAssignment={destinationWorkAssignment} />
      </div>
      {!props.swapRequest && (
        <Text>
          Wenn Sie mit <Text strong>OK</Text> bestätigen, wird ein{' '}
          <Text italic>Tauschgesuch</Text> erstellt. Dieser muss im Anschluss
          noch von einer berechtigten Person bestätigt werden, um den Tausch der
          Dienste tatsächlich durchzuführen.
        </Text>
      )}
    </Modal>
  )
}

export default SwapWorkAssignmentModal
