import { PDFDownloadLink } from '@react-pdf/renderer'
import { Spin } from 'antd'
import { useCallback } from 'react'
import { Provider } from 'react-redux'
import { useAppSelector } from '../../../app/hooks'
import { store } from '../../../app/store'
import { selectAllMemberships } from '../../../features/memberships/membershipsSlice'
import { selectAllShifts } from '../../../features/shifts/shiftsSlice'
import { selectAllWorkAssignments } from '../../../features/workAssignments/workAssignmentsSlice'
import {
  DutyRosterContextProvider,
  useDutyRosterContext,
} from '../../contexts/dutyRoster'
import DutyRosterPDF from '../DutyRosterPDF'

interface IProps {
  from: Date
  to: Date
}

function ExportPDFWrapper(props: IProps) {
  const memberships = useAppSelector(selectAllMemberships)
  const workAssignments = useAppSelector(selectAllWorkAssignments)
  const shifts = useAppSelector(selectAllShifts)
  const dutyRosterContext = useDutyRosterContext()

  if (!dutyRosterContext.dutyRoster) {
    throw new Error(
      'No dutyroster was provided to the ExportPDFWrapper component'
    )
  }

  const noOp = useCallback(() => {}, [])

  return (
    <PDFDownloadLink
      document={
        <Provider store={store}>
          <DutyRosterContextProvider
            dutyRosterId={dutyRosterContext.dutyRoster.id}
            isFullyLoaded
            isLoading={false}
            setLoading={noOp}
          >
            <DutyRosterPDF
              from={props.from}
              to={props.to}
              memberships={memberships}
              shifts={shifts}
              workAssignments={workAssignments}
            />
          </DutyRosterContextProvider>
        </Provider>
      }
      fileName="dienstplan.pdf"
    >
      {({ loading }) => (loading ? <Spin /> : 'PDF herunterladen')}
    </PDFDownloadLink>
  )
}

export default ExportPDFWrapper
