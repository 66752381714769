import { IShift } from '../../features/shifts/shiftsSlice'
import React from 'react'

export interface IShiftContext {
  planned: IShift
  actual: IShift | undefined
  displayedShiftId: string
  isInactive: boolean
}

export const ShiftContext = React.createContext<IShiftContext | undefined>(undefined)
