import React, { ForwardedRef } from 'react'
import DeviatingHoursForm, {
  IDeviatingHoursFormValues,
} from './DeviatingHoursForm'

interface IProps {
  visible: boolean
  handleFinish: (values: IDeviatingHoursFormValues) => Promise<void>
  currentDeviatingHours: number
}

const ConnectedDeviatingHoursForm = (
  { visible, handleFinish, currentDeviatingHours }: IProps,
  ref: ForwardedRef<DeviatingHoursForm>
) => {
  const initialFormValues: IDeviatingHoursFormValues = {
    deviatingHours: currentDeviatingHours,
  }

  return (
    <DeviatingHoursForm
      ref={ref}
      visible={visible}
      handleFinish={handleFinish}
      initialValues={initialFormValues}
    />
  )

}

export default React.forwardRef<DeviatingHoursForm, IProps>(
  ConnectedDeviatingHoursForm
)
