import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { message } from 'antd'
import config from '../../app/config'
import { RootState } from '../../app/store'
import { authorizedFetch } from '../../common/lib/fetch'
import { swapModeDeactivated } from '../app/appSlice'
import { fetchFollowingWorkLogs } from '../../features/work-logs/workLogSlice'
import {replaceBaseUrl} from "../../common/lib/util/urlHelper";

export interface ISwapRequest {
  id: string
  dutyRosterId: string
  source: string // work assignment id
  destination: string // work assignment id
  isApproved: boolean
}

export interface ISwapRequestCreateParams {
  source: string // work assignment id
  destination: string // work assignment id
  dutyRosterId: string
}

const entityAdapter = createEntityAdapter<ISwapRequest>()

export const create = createAsyncThunk<void, ISwapRequestCreateParams>(
  'swapRequests/create',
  async (params, thunkApi) => {
    const data = {
      data: {
        type: 'swap_request--swap_request',
        attributes: {
          name: 'a swap request',
        },
        relationships: {
          target: {
            data: {
              type: 'work_assignment--medical_facility_work',
              id: params.destination,
            },
          },
          source: {
            data: {
              type: 'work_assignment--medical_facility_work',
              id: params.source,
            },
          },
          duty_roster: {
            data: {
              type: 'duty_roster--medical_facility_roster',
              id: params.dutyRosterId,
            },
          },
        },
      },
    }

    try {
      const url = `${config.backend.url}/api/swap_request/swap_request`
      let response = await authorizedFetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
      })

      if (response.status !== 201) {
        message.error(
          'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
        )
        return thunkApi.rejectWithValue(response.statusText)
      }

      const json: any = await response.json()
      const createdSwapRequest = map(json.data)
      thunkApi.dispatch(slice.actions.created(createdSwapRequest))
      thunkApi.dispatch(swapModeDeactivated())
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const remove = createAsyncThunk(
  'swapRequests/remove',
  async (id: string, thunkApi) => {
    try {
      const url = `${config.backend.url}/api/swap_request/swap_request/${id}`
      const response = await authorizedFetch(url, {
        method: 'DELETE',
      })
      if (response.status !== 204) {
        message.error(
          'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
        )
        return thunkApi.rejectWithValue(response.statusText)
      }
      thunkApi.dispatch(slice.actions.removed(id))
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const approve = createAsyncThunk<void, any>(
  'swapRequests/approve',
  async (
    { swapRequest, sourceMemberId, destinationMemberId, date },
    thunkApi
  ) => {
    const data = {
      data: {
        type: 'swap_request--swap_request',
        id: swapRequest.id,
        attributes: {
          is_approved: true,
        },
      },
    }
    try {
      const url = `${config.backend.url}/custom/swap_request/${swapRequest.id}/approve`
      const response = await authorizedFetch(url, {
        method: 'PATCH',
        contentType: 'application/json',
        body: JSON.stringify(data),
      })
      const json: any = await response.json()

      // Fetch the updated and following worklogs from server.
      thunkApi.dispatch(
        fetchFollowingWorkLogs({
          employeeId: sourceMemberId,
          date: date,
        })
      )
      thunkApi.dispatch(
        fetchFollowingWorkLogs({
          employeeId: destinationMemberId,
          date: date,
        })
      )

      return json
    } catch (error: any) {
      message.error(
        'Es ist ein Fehler aufgetreten. Sollte sich dieser Fehler wiederholen, laden Sie bitte die Seite neu.'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

interface FetchByDutyRosterParams {
  dutyRosterId: string
  url?: URL
}

export const fetchByDutyRoster = createAsyncThunk(
  'swapRequests/fetch',
  async (params: FetchByDutyRosterParams, thunkApi) => {
    try {
      let url = params?.url
      const baseUrl = `${config.backend.url}/api/swap_request/swap_request`
      if (!url) {
        url = new URL(baseUrl)
        const filters = {
          'filter[duty_roster.id]': params.dutyRosterId,
          'fields[swap_request--swap_request]': 'is_approved,source,target,duty_roster'
        }
        url.search = new URLSearchParams(filters).toString()
      }

      const response: any = await authorizedFetch(url)

      if (response.status !== 200) {
        return thunkApi.rejectWithValue(response.statusText)
      }
      const json = await response.json()
      if ('next' in json.links) {
        // Jsonapi generates wrong urls
        url.href = replaceBaseUrl(baseUrl, json.links.next.href)
        thunkApi.dispatch(
          await fetchByDutyRoster({
            ...params,
            url: url,
          })
        )
      }
      return json
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const slice = createSlice({
  name: 'swapRequests',
  initialState: entityAdapter.getInitialState({
    status: 'idle',
    error: null,
  }),
  reducers: {
    created: entityAdapter.addOne,
    removed: entityAdapter.removeOne,
  },
  extraReducers: {
    [fetchByDutyRoster.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded'
      const json = action.payload
      const mappingResult = mapArray(json)
      entityAdapter.upsertMany(state, mappingResult)
    },
    [approve.fulfilled.toString()]: (state, action) => {
      entityAdapter.removeOne(state, action.meta.arg.id)
    },
  },
})

export const {
  selectAll: selectAllSwapRequests,
  selectById: selectSwapRequestById,
  selectIds: selectSwapRequestIds,
} = entityAdapter.getSelectors<RootState>((state) => state.swapRequests)

export default slice.reducer

function mapArray(jsonApiResponse: any) {
  if (jsonApiResponse.data.length === 0) return []

  return (jsonApiResponse.data || []).map((jsonApiEntity: any) =>
    map(jsonApiEntity)
  )
}

function map(json: any): ISwapRequest {
  return {
    id: json.id,
    source: json.relationships.source.data.id,
    destination: json.relationships.target.data.id,
    dutyRosterId: json.relationships.duty_roster.data.id,
    isApproved: json.attributes.is_approved,
  }
}

export const selectSwapRequestByWorkAssignmentId = (
  state: RootState,
  id: string
) => {
  const swapRequests = selectAllSwapRequests(state)

  return swapRequests.find(
    (swapRequest) => swapRequest.destination === id || swapRequest.source === id
  )
}
