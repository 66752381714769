import React, { ForwardedRef, useCallback, useContext } from 'react'
import { useAppDispatch } from '../../../app/hooks'
import { replaceAvatar } from '../../../features/currentUser/replaceAvatar'
import { MembershipContext } from '../../contexts/membership'
import StaffForm, { IStaffFormValues } from './StaffForm'

interface Props {
  visible: boolean
  handleFinish: (values: IStaffFormValues) => Promise<void>
}

const ConnectedStaffForm = (props: Props, ref: ForwardedRef<StaffForm>) => {
  const membershipToBeEdited = useContext(MembershipContext)
  const dispatch = useAppDispatch()
  const _replaceAvatar = useCallback(
    (options: any) => {
      return dispatch(
        replaceAvatar({
          options,
          userId: membershipToBeEdited!.userId,
          membershipId: membershipToBeEdited!.id,
        })
      )
    },
    [dispatch, membershipToBeEdited]
  )

  let initialFormValues: IStaffFormValues | null

  if (membershipToBeEdited !== null) {
    initialFormValues = {
      employment_type: membershipToBeEdited.employmentType,
      firstname: membershipToBeEdited.firstName,
      lastname: membershipToBeEdited.lastName,
      workinghours: membershipToBeEdited.workingMinutesPerWeek / 60,
      vacationdays: membershipToBeEdited.vacationDaysPerYear,
      initialVacationDays: membershipToBeEdited.initialVacationDaysPerYear,
      validWeekDays: membershipToBeEdited.validWeekDays,
      useWhiteFont: membershipToBeEdited.useWhiteFont,
      backgroundColor: membershipToBeEdited.backgroundColor,
    }
  } else {
    initialFormValues = null
  }

  return (
    <StaffForm
      ref={ref}
      visible={props.visible}
      handleFinish={props.handleFinish}
      initialValues={initialFormValues}
      imageUrl={membershipToBeEdited?.avatar}
      replaceAvatar={_replaceAvatar}
    />
  )
}

export default React.forwardRef<StaffForm, Props>(ConnectedStaffForm)
