import { unwrapResult } from '@reduxjs/toolkit'
import { Checkbox, Spin } from 'antd'
import Text from 'antd/lib/typography/Text'
import cs from 'classnames'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useStore } from 'react-redux'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { RootState } from '../../../../app/store'
import { selectAllMemberships } from '../../../../features/memberships/membershipsSlice'
import {
  EOffDutyType,
  IOffDuty,
} from '../../../../features/off-duty/offDutySlice'
import { fetchByInterval as fetchSchoolHolidaysByInterval } from '../../../../features/school-holidays/schoolHolidaySlice'
import styles from './CalendarView.module.css'
import Content from './components/Content/Content'
import Employees from './components/Employees/Employees'
import Header from './components/Header/Header'

export const RENDER_LANE_HEIGHT = 80
export const DAY_WIDTH = 25

export interface Point {
  x: number
  y: number
}

export interface RenderLane {
  membershipId: string
  offDuties: IOffDuty[]
}
interface MembershipRenderLaneDictionary {
  [membershipId: string]: RenderLane
}

interface IProps {
  offDutyType: EOffDutyType
  handleOffDutyTypeChanged: (value: EOffDutyType) => void
  offDuties: IOffDuty[]
  year: number
  isLoading: boolean
}

export default function CalendarView(props: IProps) {
  const dispatch = useAppDispatch()
  const store = useStore()
  const stateObject: RootState = store.getState()
  const contentRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const tableWrapperRef = useRef<HTMLDivElement>(null)
  const ignoreScrollingOfHeader = useRef(false)
  const ignoreScrollingOfContent = useRef(false)
  const ignoreTimeout = useRef<number>()
  const [series, setSeries] = useState([
    EOffDutyType.Vacation,
    EOffDutyType.TimeOff,
  ])
  const memberships = useAppSelector(selectAllMemberships)
  const [isGrabbing, setGrabbing] = useState(false)
  const [grabStartingPoint, setGrabStartingPoint] = useState<
    Point | undefined
  >()
  const [grabScrollPosition, setScrollPosition] = useState<Point | undefined>()

  const onGrabStart = useCallback((e) => {
    setGrabbing(true)
    setGrabStartingPoint({
      x: e.clientX,
      y: e.clientY,
    })
    setScrollPosition({
      x: contentRef.current!.scrollLeft,
      y: 0,
    })
  }, [])
  const onGrabStop = useCallback((e) => {
    setGrabbing(false)
  }, [])

  const onMouseMove = useCallback(
    (e) => {
      if (!isGrabbing) return

      const movementOffset: Point = {
        x: e.clientX - grabStartingPoint!.x,
        y: e.clientY - grabStartingPoint!.y,
      }

      contentRef.current!.scrollLeft = grabScrollPosition!.x - movementOffset.x
      headerRef.current!.scrollLeft = grabScrollPosition!.x - movementOffset.x
    },
    [grabScrollPosition, grabStartingPoint, isGrabbing]
  )

  // load the school holidays
  useEffect(() => {
    const firstDayOfYear = new Date(props.year, 0, 1)
    const lastDayOfYear = new Date(props.year, 11, 31)

    async function loadSchoolHolidays() {
      const response = await dispatch(
        fetchSchoolHolidaysByInterval({
          start: firstDayOfYear,
          end: lastDayOfYear,
        })
      )
      unwrapResult(response)
    }
    if (Object.values(stateObject.schoolHolidays.entities).length === 0)
      loadSchoolHolidays()
  }, [dispatch, props.year, stateObject.schoolHolidays.entities])

  const filteredOffDuties = useMemo(() => {
    let offDuties = props.offDuties.filter(
      (offDuty) => series.indexOf(offDuty.type) !== -1
    )
    return offDuties
  }, [series, props.offDuties])
  const filteredMemberships = useMemo(() => {
    return memberships.filter((membership) => {
      const offDutiesOfMembership = filteredOffDuties.filter(
        (offDuty) => offDuty.membershipId === membership.id
      )
      return !membership.isBlocked || offDutiesOfMembership.length > 0
    })
  }, [filteredOffDuties, memberships])
  const renderLanes: RenderLane[] = useMemo(() => {
    const renderLanes: RenderLane[] = filteredMemberships.map((membership) => ({
      membershipId: membership.id,
      offDuties: [],
    }))
    const membershipIdsMappedToRenderLanes =
      filteredMemberships.reduce<MembershipRenderLaneDictionary>(
        (returnValue, membership, index) => {
          returnValue[membership.id] = renderLanes[index]
          return returnValue
        },
        {}
      )
    const sortedOffDuties = filteredOffDuties.sort((a, b) =>
      a.from < b.from ? -1 : 1
    )
    const offDutiesGroupedByMembership: IOffDuty[][] = []
    sortedOffDuties.forEach((offDuty) => {
      const index = filteredMemberships.findIndex(
        (membership) => membership.id === offDuty.membershipId
      )
      if (!offDutiesGroupedByMembership[index]) {
        offDutiesGroupedByMembership[index] = []
      }
      offDutiesGroupedByMembership[index].push(offDuty)
    })

    // distribute the offDuties on the render lanes
    offDutiesGroupedByMembership.forEach((offDuties) => {
      offDuties.forEach((offDuty) => {
        let renderLaneFound = false
        const renderLaneIndex = renderLanes.indexOf(
          membershipIdsMappedToRenderLanes[offDuty.membershipId]
        )
        let renderLaneOffset = 0

        do {
          const renderLane = renderLanes[renderLaneIndex + renderLaneOffset]

          if (renderLane.membershipId !== offDuty.membershipId) {
            // add a new render lane
            renderLanes.splice(renderLaneIndex + renderLaneOffset, 0, {
              membershipId: offDuty.membershipId,
              offDuties: [offDuty],
            })
            renderLaneFound = true
          } else if (renderLane.offDuties.length < 1) {
            // add offDuty to an existing render lane
            renderLanes[renderLaneIndex + renderLaneOffset].offDuties.push(
              offDuty
            )
            renderLaneFound = true
          } else if (
            // add offDuty to an existing render lane
            renderLane.offDuties[renderLane.offDuties.length - 1].to <
            offDuty.from
          ) {
            renderLanes[renderLaneIndex + renderLaneOffset].offDuties.push(
              offDuty
            )
            renderLaneFound = true
          } else {
            renderLaneOffset++
          }
        } while (!renderLaneFound)
      })
    })

    return renderLanes
  }, [filteredMemberships, filteredOffDuties])

  const onSeriesUpdated = useCallback(
    (type: EOffDutyType) => {
      if (series.indexOf(type) === -1) {
        setSeries([...series, type])
      } else {
        setSeries(series.filter((x) => x !== type))
      }
    },
    [series]
  )

  const handleScroll = useCallback(
    (headerWasScrolled: boolean) => {
      if (isGrabbing) return
      if (!contentRef.current || !headerRef.current) return

      if (headerWasScrolled && ignoreScrollingOfHeader.current) {
        return
      }
      if (!headerWasScrolled && ignoreScrollingOfContent.current) {
        return
      }

      // keep the scroll position in sync
      let scrollLeft = headerWasScrolled
        ? headerRef.current.scrollLeft
        : contentRef.current.scrollLeft

      const maxHorizontalScroll =
        contentRef.current.scrollWidth - contentRef.current.clientWidth

      if (scrollLeft > maxHorizontalScroll) {
        scrollLeft = maxHorizontalScroll
      }
      if (headerWasScrolled) {
        ignoreScrollingOfContent.current = true
        contentRef.current.scrollLeft = scrollLeft
        // correct a scrolling position which might have exceeded maxHorizontalScroll
        headerRef.current.scrollLeft = scrollLeft
      } else {
        ignoreScrollingOfHeader.current = true
        headerRef.current.scrollLeft = scrollLeft
      }

      // toggle a global class
      if (scrollLeft > 0) {
        tableWrapperRef.current?.classList.add('has-horizontal-scroll-offset')
      } else {
        tableWrapperRef.current?.classList.remove(
          'has-horizontal-scroll-offset'
        )
      }

      // stop ignoring the scrolling after a timeout
      // clear the old timeout if exists
      if (ignoreTimeout.current) {
        window.clearTimeout(ignoreTimeout.current)
      }
      ignoreTimeout.current = window.setTimeout(() => {
        if (headerWasScrolled) ignoreScrollingOfContent.current = false
        else ignoreScrollingOfHeader.current = false
      }, 300)
    },
    [isGrabbing]
  )

  // add a scroll handler to the content
  useLayoutEffect(() => {
    const callback = () => handleScroll(false)
    const refValue = contentRef.current
    if (refValue) {
      refValue.addEventListener('scroll', callback, {
        passive: true,
      })
    }
    return () => refValue?.removeEventListener('scroll', callback)
  }, [handleScroll])

  // add a scroll handler to the header
  useLayoutEffect(() => {
    const callback = () => handleScroll(true)
    const refValue = headerRef.current
    if (refValue) {
      refValue.addEventListener('scroll', callback, {
        passive: true,
      })
    }
    return () => refValue?.removeEventListener('scroll', callback)
  }, [handleScroll])

  const overlayClasses = cs({
    [styles.loadingOverlayBackground]: props.isLoading,
    [styles.loadingOverlayBackgroundInvisible]: !props.isLoading,
  })

  const height = useMemo(() => {
    return renderLanes.length * RENDER_LANE_HEIGHT
  }, [renderLanes.length])

  return (
    <div className={`${styles.root} ${props.isLoading ? 'no-scroll' : ''}`}>
      <div className={styles.header}>
        <Checkbox
          onChange={() => onSeriesUpdated(EOffDutyType.Vacation)}
          checked={series.indexOf(EOffDutyType.Vacation) !== -1}
        >
          <Text className={`${styles.filter} ${styles.filterVacation}`}>
            Urlaub
          </Text>
        </Checkbox>
        <Checkbox
          onChange={() => onSeriesUpdated(EOffDutyType.TimeOff)}
          checked={series.indexOf(EOffDutyType.TimeOff) !== -1}
        >
          <Text className={`${styles.filter} ${styles.filterTimeOff}`}>
            Dienstfrei
          </Text>
        </Checkbox>
      </div>
      <div className={styles.tableWrapper} ref={tableWrapperRef}>
        <Header year={props.year} ref={headerRef} />
        <div className={styles.tableContentWrapper}>
          <Employees
            height={height}
            memberships={filteredMemberships}
            renderLanes={renderLanes}
            offDuties={props.offDuties}
          />
          <Content
            height={height}
            year={props.year}
            ref={contentRef}
            memberships={filteredMemberships}
            renderLanes={renderLanes}
            onMouseMove={onMouseMove}
            onGrabStart={onGrabStart}
            onGrabStop={onGrabStop}
          />
          <div className={styles.loadingOverlay}>
            <div className={overlayClasses}></div>
            {props.isLoading && <Spin size="large" />}
          </div>
        </div>
      </div>
    </div>
  )
}
