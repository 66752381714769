import { Space } from 'antd'
import AccountMenu from '../AccountMenu'

function SystemTray() {
  return (
    <Space>
      <AccountMenu />
    </Space>
  )
}

export default SystemTray
