export function calculateChecksum(str: string): number {
  let result = 0

  if (!str) return result

  for (let i = 0; i < str.length; i++) {
    result += str.charCodeAt(i)
  }

  return result
}
