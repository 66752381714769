import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import config from '../../app/config'
import { RootState } from '../../app/store'
import { authorizedFetch } from '../../common/lib/fetch'
import { EEmploymentType } from '../memberships/employmentType'

interface Address {
  address: string
  countryCode: string
}
interface CurrentUser {
  displayName: string
  firstName: string
  lastName: string
  id: string
  uuid: string
  roles: any[]
  token: string
  employmentType: EEmploymentType
  membershipId: string
  createPermissions: any[]
  avatar: string
  institutionAddress: Address
  instututionUuid: string
}

const initialState: CurrentUser = {
  displayName: '',
  firstName: '',
  lastName: '',
  id: '',
  uuid: '',
  roles: [],
  token: '',
  employmentType: EEmploymentType.Other,
  membershipId: '',
  createPermissions: [],
  avatar: '',
  institutionAddress: {
    address: '',
    countryCode: '',
  },
  instututionUuid: ''
}

export const fetchCurrentUser = createAsyncThunk(
  'currentUser/fetch',
  async (_, thunkApi) => {
    try {
      const response: any = await authorizedFetch(
        `${config.backend.url}/api/me`
      )

      if (response.ok) {
        const json: any = await response.json()
        thunkApi.dispatch(slice.actions.fetched(json.data.attributes))
      }
    } catch (error: any) {
      message.error('Ihre Nutzerdaten konnten nicht geladen werden')
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const slice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    fetched: (state, action: PayloadAction<any>) => {
      state.displayName = action.payload.displayName
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.id = action.payload.id
      state.instututionUuid = action.payload.instututionUuid
      state.institutionAddress = {
        countryCode: action.payload.institutionAddress[0].country_code,
        address: action.payload.institutionAddress[0].address_line2,
      }
      state.uuid = action.payload.uuid
      // Condition is required, as the drupal subrequests modules returns an
      // object {content: [], headers: ['x sub content...']} and not an array
      // when the user does not hold any roles!
      state.roles =
        (Array.isArray(action.payload.roles) && action.payload.roles) || []
      state.token = action.payload.token
      state.employmentType = action.payload.employmentType
      state.membershipId = action.payload.membershipId
      state.createPermissions = action.payload.createPermissions
      state.avatar = config.backend.url + action.payload.avatar
    },
  },
})

export const selectCurrentUser = (state: RootState) => state.currentUser
export const selectCurrentUserRoles = (state: RootState) =>
  state.currentUser.roles
export const selectCurrentUserCreatePermissions = (state: RootState) =>
  state.currentUser.createPermissions
export const { fetched } = slice.actions
export default slice.reducer
