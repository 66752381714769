import { DeleteOutlined, QuestionCircleFilled } from '@ant-design/icons'
import { Button, Popconfirm, Tooltip } from 'antd'
import { useState } from 'react'

interface Props {
  onDelete: () => void
  canRemove: boolean
  isApproved: boolean
}

const DeleteButton = (props: Props) => {
  const [isVisible, setVisible] = useState(false)
  const [isDeleting, setDeleting] = useState(false)

  const doCancel = () => {
    setDeleting(true)
    props.onDelete()
  }

  const showConfirm = () => {
    setVisible(true)
  }

  return (
    <Popconfirm
      placement="left"
      visible={isVisible}
      icon={<QuestionCircleFilled />}
      title="Soll dieser Dienstplan wirklich gelöscht werden?"
      onConfirm={doCancel}
      onCancel={() => setVisible(false)}
      okText="Ja, löschen"
      okButtonProps={{ danger: true, loading: isDeleting }}
      cancelText="Nein"
      disabled={!props.canRemove || props.isApproved}
    >
      <Tooltip title="Löschen">
        <Button
          onClick={showConfirm}
          icon={<DeleteOutlined />}
          shape={'circle'}
          danger
          disabled={!props.canRemove || props.isApproved}
        />
      </Tooltip>
    </Popconfirm>
  )
}

export default DeleteButton
