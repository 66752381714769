import { ScheduleOutlined } from '@ant-design/icons'
import { Popover, Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'
import cs from 'classnames'
import Avatar from '../../../../../../common/components/Avatar'
import EmployeeOffDutyStatistic from '../../../../../../common/components/EmployeeOffDutyStatistic'
import { IMembership } from '../../../../../../features/memberships/membershipsSlice'
import { IOffDuty } from '../../../../../../features/off-duty/offDutySlice'
import styles from './Employee.module.css'
interface Props {
  employee: IMembership
  offDuties: IOffDuty[]
  height: number
  isOdd: boolean
}

const Employee = (props: Props) => {
  const fullName = `${props.employee.lastName}, ${props.employee.firstName}`

  const rootStyles = cs({
    [styles.root]: true,
    [styles.isOdd]: props.isOdd,
  })

  return (
    <div className={rootStyles} style={{ height: props.height }}>
      <Avatar membershipId={props.employee.id} className="employeeAvatar" />
      <Text className={styles.fullName}>{fullName}</Text>
      {!!props.employee.vacationDaysPerYear && (
        <div className={styles.infoWrapper}>
          <Tooltip title="Jahresübersicht anzeigen">
            <Popover
              overlayClassName={styles.statisticPopover}
              placement="right"
              content={
                <EmployeeOffDutyStatistic
                  employee={props.employee}
                  numApproved={
                    props.offDuties.filter((offDuty) => offDuty.isApproved)
                      .length
                  }
                />
              }
              title={`Jahresübersicht Urlaub / Dienstfrei  (${fullName})`}
              trigger="click"
            >
              <ScheduleOutlined
                className="employeeInfoIcon"
                style={{ fontSize: '16px' }}
              />
            </Popover>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default Employee
