import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

export interface IState {
  accessToken: string
  refreshToken: string | undefined
  isAuthenticated: boolean
  isSessionExpired: boolean
}

interface IAuthenticationInfo {
  accessToken: string
  refreshToken: string
}

const initialState: IState = {
  accessToken: '',
  isAuthenticated: false,
  refreshToken: undefined,
  isSessionExpired: false,
}

export const slice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    authenticated: (state, action: PayloadAction<IAuthenticationInfo>) => {
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
      state.isAuthenticated = true
      state.isSessionExpired = false
    },
    sessionExpired: (state) => {
      state.isSessionExpired = true
    },
  },
})

export const selectAuthentication = (state: RootState) => state.authentication
export const { authenticated, sessionExpired } = slice.actions
export default slice.reducer
