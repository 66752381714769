import { Descriptions } from 'antd'
import { memo } from 'react'
import styles from './EmployeeOffDutyStatistic.module.css'

interface IProps {
  remainingAvailableDays: number
  approvedVacationDays: number
  vacationDaysPerYear: number
  additionalVacationDays: number
  registeredTimeOffDays: number
  vacationDaysFromPreviousYear: number
  totalAvailableDays: number
}

const EmployeeOffDutyStatistic = memo((props: IProps) => {
  return (
    <>
      <Descriptions
        bordered
        title="Urlaub (Tage)"
        className={styles.descriptionWrapper}
      >
        <Descriptions.Item label="Anspruch laut AV">
          {props.vacationDaysPerYear}
        </Descriptions.Item>
        <Descriptions.Item label="Übertrag aus VJ">
          {props.vacationDaysFromPreviousYear}
        </Descriptions.Item>
        <Descriptions.Item label="Zusatz">
          {props.additionalVacationDays}
        </Descriptions.Item>
        <Descriptions.Item label="Anspruch Gesamt" span={2}>
          {props.totalAvailableDays}
        </Descriptions.Item>
        <Descriptions.Item label="Bisher genehmigt">
          {props.approvedVacationDays}
        </Descriptions.Item>
        <Descriptions.Item label="Noch verfügbar">
          {props.remainingAvailableDays}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions bordered title="Dienstfrei (Tage)">
        <Descriptions.Item label="Beantragt">
          {props.registeredTimeOffDays}
        </Descriptions.Item>
      </Descriptions>
    </>
  )
})

export default EmployeeOffDutyStatistic
