import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import config from '../../app/config'
import { format, lastDayOfMonth, parseISO } from 'date-fns'
import { authorizedFetch } from '../../common/lib/fetch'
import {replaceBaseUrl} from "../../common/lib/util/urlHelper";

export interface ISchoolHoliday {
  id: string
  name: string
  from: string
  to: string
  region: string
}

interface FetchByIntervalParams {
  start: Date
  end: Date
  url?: URL
}

export const fetchByInterval = createAsyncThunk(
  'schoolHolidays/fetch',
  async (params: FetchByIntervalParams, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState
      const currentUser = state.currentUser
      const countryCode = currentUser.institutionAddress.countryCode
      const countryState = currentUser.institutionAddress.address
      const region = countryCode + '-' + countryState

      let url = params?.url
      const baseUrl = `${config.backend.url}/api/node/school_holiday`
      if (!url) {
        url = new URL(baseUrl)
        const filters = {
          'fields[node--school_holiday]': 'title,date_range,region_code',
          'filter[region_code]': region,
          'filter[dateMinFilter][condition][path]': 'date_range.value',
          'filter[dateMinFilter][condition][operator]': '>=',
          'filter[dateMinFilter][condition][value]': format(
            params.start,
            'yyyy-MM-dd'
          ),
          'filter[dateMaxFilter][condition][path]': 'date_range.value',
          'filter[dateMaxFilter][condition][operator]': '<=',
          'filter[dateMaxFilter][condition][value]': format(
            params.end,
            'yyyy-MM-dd'
          ),
        }
        url.search = new URLSearchParams(filters).toString()
      }

      const response: any = await authorizedFetch(url)
      if (response.status !== 200) {
        return thunkApi.rejectWithValue(response.statusText)
      }
      const json = await response.json()
      if ('next' in json.links) {
        // Jsonapi generates wrong urls
        url.href = replaceBaseUrl(baseUrl, json.links.next.href)
        thunkApi.dispatch(
          await fetchByInterval({
            ...params,
            url: url,
          })
        )
      }
      return json
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

const entityAdapter = createEntityAdapter<ISchoolHoliday>()

export const slice = createSlice({
  name: 'schoolHolidays',
  initialState: entityAdapter.getInitialState({
    status: 'idle',
    error: null,
  }),
  reducers: {},
  extraReducers: {
    [fetchByInterval.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded'
      entityAdapter.upsertMany(state, mapArray(action.payload))
    },
    [fetchByInterval.pending.toString()]: (state) => {
      state.status = 'pending'
    },
  },
})

export default slice.reducer
export const {
  selectAll: selectAllSchoolHolidays,
  selectById: selectSchoolHolidayById,
  selectIds: selectSchoolHolidayIds,
} = entityAdapter.getSelectors<RootState>((state) => state.schoolHolidays)

export const selectSchoolHolidaysByYear = createSelector(
  [selectAllSchoolHolidays, (state: RootState, year: number) => year],
  (schoolHolidays, year) =>
    schoolHolidays.filter((schoolHoliday) => {
      if (!schoolHoliday) return false

      const from = parseISO(schoolHoliday.from)
      const to = parseISO(schoolHoliday.to)
      const firstDayOfYear = new Date(year, 0, 1)
      const lastDayOfYear = new Date(year, 11, 31)
      return (
        to.getTime() >= firstDayOfYear.getTime() &&
        from.getTime() <= lastDayOfYear.getTime()
      )
    })
)

export const selectSchoolHolidaysByMonthAndYear = createSelector(
  [
    selectAllSchoolHolidays,
    (state: RootState, month: number, year: number) => month,
    (state: RootState, month: number, year: number) => year,
  ],
  (schoolHolidays, month, year) =>
    schoolHolidays.filter((schoolHoliday) => {
      if (!schoolHoliday) return false

      const from = parseISO(schoolHoliday.from)
      const to = parseISO(schoolHoliday.to)
      const firstDayOfMonth = new Date(year, month, 1)
      const _lastDayOfMonth = lastDayOfMonth(firstDayOfMonth)

      return (
        to.getTime() >= firstDayOfMonth.getTime() &&
        from.getTime() <= _lastDayOfMonth.getTime()
      )
    })
)

export const selectSchoolHolidaysByDate = createSelector(
  [selectAllSchoolHolidays, (state: RootState, date: Date) => date],
  (schoolHolidays, date) =>
    schoolHolidays.filter((schoolHoliday) => {
      if (!schoolHoliday) return false

      const from = parseISO(schoolHoliday.from)
      const to = parseISO(schoolHoliday.to)
      return to.getTime() >= date.getTime() && from.getTime() <= date.getTime()
    })
)

function mapArray(jsonApiResponse: any) {
  return (jsonApiResponse.data || []).flatMap(map)
}
function map(jsonApiResponse: any): ISchoolHoliday {
  return {
    id: jsonApiResponse.id,
    name: jsonApiResponse.attributes.title,
    from: jsonApiResponse.attributes.date_range.value,
    to: jsonApiResponse.attributes.date_range.end_value,
    region: jsonApiResponse.attributes.region_code,
  }
}
