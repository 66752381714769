import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'
import cs from 'classnames'
import { useAppSelector } from '../../../app/hooks'
import { selectMembershipById } from '../../../features/memberships/membershipsSlice'
import { IWorkLog } from '../../../features/work-logs/workLogSlice'
import { IWorkAssignment } from '../../../features/workAssignments/workAssignmentsSlice'
import Avatar from '../Avatar'
import WorktimeRemaining from '../WorktimeRemaining/WorktimeRemaining'
import styles from './Member.module.css'

interface IProps {
  membershipId: string
  small?: boolean
  initials?: boolean
  isLoading?: boolean
  dragHandleClassName?: string
  workAssignment?: IWorkAssignment
  selectedAbbreviationKey?: string
  workLog?: IWorkLog
}

export default function Member(props: IProps) {
  const membership = useAppSelector((state) =>
    selectMembershipById(state, props.membershipId)
  )
  if (!membership) {
    return <p>Membership ({props.membershipId}) wasn't found</p>
  }

  const carryOverStyles = cs({
    [styles.negativeCarryOver]:
      props.workLog?.carryOver && props.workLog.carryOver < 0,
  })

  return (
    <div
      className={`${styles.root} ${
        membership.isBlocked && !props.small ? styles.isBlocked : ''
      }`}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <Tooltip
            title={
              !props.small
                ? ''
                : `${membership.firstName} ${membership.lastName}`
            }
          >
            <div className={props.dragHandleClassName}>
              <Avatar
                membershipId={props.membershipId}
                className={styles.avatar}
              />
            </div>
          </Tooltip>
          {!props.small && (
            <Text
              className={`${styles.displayName} ${
                membership.isBlocked ? styles.blockedText : ''
              }`}
            >
              {`${membership.lastName}, ${membership.firstName}`}
            </Text>
          )}
        </div>
        {props.workLog && !props.small && (
          <div className={styles.stats}>
            <div className={styles.carryOver}>
              <Tooltip title="Übertrag AZ">
                {<ClockCircleOutlined className={styles.icon} />}
              </Tooltip>
              <Text className={carryOverStyles}>{props.workLog.carryOver}</Text>
            </div>

            <div className={styles.setWorkingTime}>
              <Tooltip title="Ist-AZ (Soll-AZ)">
                {<CalendarOutlined className={styles.icon} />}
              </Tooltip>
              <Text>
                {props.workLog.currentWorkingTime} (
                {props.workLog.setWorkingTime})
              </Text>
            </div>
          </div>
        )}
      </div>
      <div className={styles.worktimeRemainingWrapper}>
        {props.workLog && !props.small && (
          <WorktimeRemaining workLog={props.workLog} />
        )}
      </div>
    </div>
  )
}
