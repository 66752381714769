import { View, StyleSheet } from '@react-pdf/renderer'
import Week from '../Week/Week'
import { eachWeekOfInterval } from 'date-fns'
import { IShift } from '../../../features/shifts/shiftsSlice'
import { IMembership } from '../../../features/memberships/membershipsSlice'
import { IWorkAssignment } from '../../../features/workAssignments/workAssignmentsSlice'

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
  },
})

interface IProps {
  from: Date,
  to: Date,
  shifts: IShift[]
  memberships: IMembership[]
  workAssignments: IWorkAssignment[]
}

const Month = (props: IProps) => {
  const startDates = eachWeekOfInterval(
    {
      start: props.from,
      end: props.to,
    },
    { weekStartsOn: 1 }
  )

  return (
    <View style={styles.root}>
      {startDates.map((startDate, index) => (
        <Week
          key={index}
          date={startDate}
          showTitle={index === 0}
          shifts={props.shifts}
          workAssignments={props.workAssignments}
          memberships={props.memberships}
        />
      ))}
    </View>
  )
}
export default Month
