import { MessageOutlined, WarningTwoTone } from '@ant-design/icons'
import { Space, Typography } from 'antd'
import { useAppSelector } from '../../../../../../app/hooks'
import { selectMembershipById } from '../../../../../../features/memberships/membershipsSlice'
import { EOffDutyType, IOffDuty } from '../../../../../../features/off-duty/offDutySlice'
import { calculateChecksum } from '../../../../../../common/lib/checksum'
import { getNumberOfValidOffDutyDays } from '../../../../../../common/lib/workingTime'
import Avatar from '../../../../../../common/components/Avatar'
import styles from './OffDutyPopover.module.css'

const { Text } = Typography
const vacationImages = ['/vacation1.jpg', '/vacation2.jpg', '/vacation3.jpg']
const timeOffImages = ['/timeoff1.jpg']

interface IProps {
  offDuty: IOffDuty
  doesOverlapWithApproved: boolean
}

const OffDutyPopover = (props: IProps) => {
  const employee = useAppSelector((state) =>
    selectMembershipById(state, props.offDuty.membershipId)
  )
  const offDutyType =
    props.offDuty.type === EOffDutyType.Vacation ? 'Urlaub' : 'Dienstfrei'

  const numberOfRegularDays = getNumberOfValidOffDutyDays(props.offDuty, employee)

  const approvalInformation = (
    <>
      {props.offDuty.isApproved && (
        <Text className={styles.approved}>genehmigt</Text>
      )}
      {!props.offDuty.isApproved && (
        <Text className={styles.disapproved}>nicht genehmigt</Text>
      )}
    </>
  )

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {props.doesOverlapWithApproved && (
          <div className={styles.attributesWrapper}>
            <Space>
              <WarningTwoTone
                style={{ fontSize: '16px' }}
                twoToneColor="#fc3b02"
              />
              <Text className={styles.doesOverlapWithApproved}>
                Dieser Urlaub überlappt mit einem bereits genehmigten Urlaub
              </Text>
            </Space>
          </div>
        )}
        <div className={styles.employeeWrapper}>
          <Avatar membershipId={employee!.id} className={styles.avatar} />
          <Text>{`${employee!.firstName} ${employee!.lastName}`}</Text>
        </div>
        <div className={styles.attributesWrapper}>
          <Text>
            {numberOfRegularDays} Tage <Text strong>{offDutyType}</Text>
          </Text>
          {props.offDuty.type === EOffDutyType.Vacation && approvalInformation}
        </div>
        {props.offDuty.comment && (
          <div className={styles.commentWrapper}>
            <MessageOutlined className={styles.commentIcon} />
            <Text italic className={styles.comment}>
              {props.offDuty.comment}
            </Text>
          </div>
        )}
      </div>
      <div className={styles.imageWrapper}>
        <img
          src={
            props.offDuty.type === EOffDutyType.Vacation
              ? vacationImages[
                  calculateChecksum(props.offDuty.id) % vacationImages.length
                ]
              : timeOffImages[
                  calculateChecksum(props.offDuty.id) % timeOffImages.length
                ]
          }
          className={styles.image}
          alt="a random off duty impression"
        />
      </div>
    </div>
  )
}

export default OffDutyPopover
