import { Text, View } from '@react-pdf/renderer'
import { IMembership } from '../../../features/memberships/membershipsSlice'
import { IWorkAssignment } from '../../../features/workAssignments/workAssignmentsSlice'

import styles from './styles'

interface IProps {
  workAssignment: IWorkAssignment
  memberships: IMembership[]
}

const WorkAssignment = (props: IProps) => {
  const membership = props.memberships.find(
    (membership) => membership.id === props.workAssignment.membershipId
  )

  if (!membership) return null

  const initials =
    membership.firstName.substr(0, 1) + membership.lastName.substr(0, 1)

  const rootStyles: any = [
    styles.root,
    { backgroundColor: membership.backgroundColor },
  ]
  if (!props.workAssignment.exchangeable) {
    rootStyles.push(styles.notExchangeable)
  }
  return (
    <View style={rootStyles}>
      <Text
        style={[
          styles.title,
          { color: membership.useWhiteFont ? 'white' : 'black' },
        ]}
      >
        {initials}
      </Text>
    </View>
  )
}
export default WorkAssignment
