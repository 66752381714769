import React, { ForwardedRef } from 'react'
import { IMembership } from '../../../../../../features/memberships/membershipsSlice'
import { IOffDuty } from '../../../../../../features/off-duty/offDutySlice'
import { doesOverlap, doesOverlapWithApproved } from '../../../../helper'
import { RenderLane, RENDER_LANE_HEIGHT } from '../../CalendarView'
import OffDuty from '../OffDuty/OffDuty'
import styles from './OffDuties.module.css'

interface Props {
  memberships: IMembership[]
  renderLanes: RenderLane[]
}

const OffDuties = (props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  let yPos = 0
  const membershipRenderLanes = props.memberships.map((membership) => {
    const lanes: any = props.renderLanes.filter(
      (renderLane) => renderLane.membershipId === membership.id
    )
    const returnValue = {
      lanes,
      yPos: yPos,
    }
    yPos += lanes.length
    return returnValue
  })

  const renderRenderLane = (
    renderLane: RenderLane,
    yPosIndex: number,
    offDuties: IOffDuty[]
  ) => {
    return renderLane.offDuties.map((offDuty) => {
      const _doesOverlap = doesOverlap(offDuty, offDuties)
      const _doesOverlapWithApproved = doesOverlapWithApproved(
        offDuty,
        offDuties
      )

      return (
        <OffDuty
          key={offDuty.id}
          offDuty={offDuty}
          yPosIndex={yPosIndex}
          doesOverlap={_doesOverlap}
          doesOverlapWithApproved={_doesOverlapWithApproved}
        />
      )
    })
  }

  return (
    <div className={styles.root} ref={ref}>
      {membershipRenderLanes.map((entity, index) => {
        const offDuties = entity.lanes.reduce(
          (returnValue: IOffDuty[], lane: RenderLane) =>
            returnValue.concat(lane.offDuties),
          []
        )
        return (
          <div
            key={index}
            className={styles.wrapper}
            style={{ height: entity.lanes.length * RENDER_LANE_HEIGHT }}
          >
            {entity.lanes.map((renderLane: RenderLane, index: number) =>
              renderRenderLane(renderLane, entity.yPos + index, offDuties)
            )}
          </div>
        )
      })}
    </div>
  )
}

export default React.forwardRef<HTMLDivElement, Props>(OffDuties)
