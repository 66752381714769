import {Button, Modal, Descriptions} from 'antd'
import React, {useContext, useEffect, useMemo} from 'react'
import styles from './VacationApprovalModal.module.css'
import {
  fetchByRangeAndEmployee,
  selectVacationLogsByYearRangeAndEmployee
} from "../../../features/offduty-logs/vacationLogSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
  selectAllMemberships
} from "../../../features/memberships/membershipsSlice";
import {getYear, parseISO} from "date-fns";
import {approve} from "../../../features/off-duty/offDutySlice";
import {getNumberOfValidOffDutyDaysInYear, getAnnualVacationStats} from "../../lib/workingTime";
import {OffDutiesContext} from "../../../pages/OffDuties/OffDutiesContext";

interface IProps {
  visible: boolean
  handleClose: () => void
}

export default function VacationApprovalModal(props: IProps) {
  const dispatch = useAppDispatch()
  const context = useContext(OffDutiesContext)
  const memberships = useAppSelector(selectAllMemberships)

  // If the offduty spans more than 1 year (e.g. christmas / new years vacation)
  // load vacationlogs for both years.
  const fromYear = getYear(parseISO(context.offDutyToEdit!.from))
  const toYear = getYear(parseISO(context.offDutyToEdit!.to))

  const firstDayOfRange = useMemo(() => new Date(fromYear, 0, 1), [fromYear])
  const lastDayOfRange = useMemo(() => new Date(toYear, 11, 31), [toYear])

  const employee = memberships.find(
    (employee) => employee.id === context.offDutyToEdit!.membershipId
  )

  // Load all selected year's vacationlogs for the member.
  useEffect(() => {
    async function loadVacationLogs() {
      const response = await dispatch(
        fetchByRangeAndEmployee({
          employeeId: employee!.id,
          startDate: firstDayOfRange,
          endDate: lastDayOfRange,
        })
      )
      unwrapResult(response)
    }
    loadVacationLogs()
  }, [dispatch, firstDayOfRange, lastDayOfRange, employee])

  const vacationLogs = useAppSelector((state) => (
    selectVacationLogsByYearRangeAndEmployee(state, fromYear, toYear, employee!.id)
  ))

  if (!employee || vacationLogs.length === 0) {
    return null
  }

  let content = []
  let canBeApproved = true
  let duration: number[] = []

  if (toYear - fromYear > 1) {
    content.push(
      <div>Ein Urlaub dieser Länge kann nicht genehmigt werden</div>
    )
    canBeApproved = false
  } else {
    // loop through the years
    for(let year = fromYear; year <= toYear; year++ ) {
      const firstDayOfYear = new Date(year, 0, 1)
      const lastDayOfYear = new Date(year, 11, 31)

      const annualStats = getAnnualVacationStats(vacationLogs, firstDayOfYear, lastDayOfYear)

      const regularVacationDays = context.offDutyToEdit
        ? getNumberOfValidOffDutyDaysInYear(context.offDutyToEdit, year, employee)
        : 0
      const tmpRemainingDays = annualStats.remainingAvailableDays - regularVacationDays - (duration.length > 0 ? duration[0] : 0)
      canBeApproved = canBeApproved && tmpRemainingDays >= 0

      // Log the number of vacation days. This needs to be subtracted in the
      // next loop iteration.
      duration.push(regularVacationDays)

      content.push(
        <Descriptions
          bordered
          className={styles.descriptionWrapper}
          title={'Urlaub ' + year}
          key={year}
        >
          <Descriptions.Item label="Noch verfügbar nach diesem Urlaub"
                             className={tmpRemainingDays < 0 ? styles.remainingError : ''}>
            {tmpRemainingDays}
          </Descriptions.Item>
        </Descriptions>
      )
    }
  }

  return (
    <Modal
      title={'Urlaub genehmigen: ' + employee.firstName + ' ' + employee.lastName}
      visible={props.visible}
      onCancel={props.handleClose}
      destroyOnClose
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            dispatch(approve(context.offDutyToEdit!.id))
            props.handleClose()
          }}
          disabled={!canBeApproved}
        >
          Genehmigen
        </Button>,
        <Button key="back" onClick={props.handleClose}>
          Abbrechen
        </Button>,
      ]}
    >
      {content}
    </Modal>
  )
}
