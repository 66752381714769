import { Modal } from 'antd'
import { EEmploymentType } from '../../../features/memberships/employmentType'
import CreateDutyRosterForm from '../CreateDutyRosterForm'
import CreateDutyRosterFormPresentational, {
  ICreateDutyRosterFormValues,
} from '../CreateDutyRosterForm/CreateDutyRosterForm'
import styles from './CreateDutyRosterModal.module.css'

interface IProps {
  visible: boolean
  handleOk: () => void
  confirmLoading: boolean
  handleCancel: () => void
  handleFinish: (values: ICreateDutyRosterFormValues) => Promise<void>
  employmentType: EEmploymentType
  formRef: React.RefObject<CreateDutyRosterFormPresentational>
}

const NUMBER_OF_COMIC_STRIPS = 4

const CreateDutyRosterModal = (props: IProps) => {
  return (
    <Modal
      width="600px"
      title="Dienstplan hinzufügen"
      visible={props.visible}
      onOk={props.handleOk}
      confirmLoading={props.confirmLoading}
      onCancel={props.handleCancel}
      okText={'Erstellen'}
      cancelText={'Abbrechen'}
      destroyOnClose
    >
      <div className={styles.imageWrapper}>
        <img
          src={`/create-duty-roster/comic-strip-${Math.round(
            Math.random() * (NUMBER_OF_COMIC_STRIPS - 1) + 1
          )}.jpg`}
          alt="comic strip making a joke about duty rosters"
          className={styles.image}
        />
      </div>
      <CreateDutyRosterForm
        ref={props.formRef}
        handleFinish={props.handleFinish}
        visible={props.visible}
        employmentType={props.employmentType}
      />
    </Modal>
  )
}

export default CreateDutyRosterModal
