import { getYear } from 'date-fns'
import {
  default as DateHolidays,
  default as Holidays,
  HolidaysTypes,
} from 'date-holidays'
import { store } from '../../../app/store'

interface HolidayYearDictionary {
  [year: number]: any[]
}
class HolidayRepository {
  holidayCache: HolidayYearDictionary
  dateHolidayInstance: Holidays | undefined

  constructor() {
    this.holidayCache = {}
  }

  initialize(): void {
    const currentUser = store.getState().currentUser
    const countryCode = currentUser.institutionAddress.countryCode
    const countryState = currentUser.institutionAddress.address
    if(!countryCode || !countryState)
      console.error(`The user ${currentUser.id} does not have an institutionAddress`)
    this.dateHolidayInstance = new DateHolidays(countryCode || 'DE', countryState || 'ST')
  }

  isHoliday(date: Date): HolidaysTypes.Holiday[] | false {
    if (!this.dateHolidayInstance) {
      this.initialize()
    }

    const holidays = this.getHolidays(getYear(date))
    const matches = holidays.filter(
      (holiday) => holiday.start <= date && date < holiday.end
    )
    return matches.length > 0 ? matches : false
  }

  getHolidays(year: number): HolidaysTypes.Holiday[] {
    if (!this.dateHolidayInstance) {
      this.initialize()
    }

    if (!this.holidayCache[year]) {
      this.holidayCache[year] = this.dateHolidayInstance!.getHolidays(year)
    }

    return this.holidayCache[year]
  }
}

const holidayRepository = new HolidayRepository()
export default holidayRepository
