import React, { ForwardedRef, memo } from 'react'
import MemberHeaderColumn from '../MemberHeaderColumn/MemberHeaderColumn'
import MonthHeaderColumn from '../MonthHeaderColumn/MonthHeaderColumn'
import styles from './Header.module.css'

interface Props {
  year: number
}

const Header = (props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div className={styles.root}>
      <MemberHeaderColumn />
      <div className={styles.monthColumnWrapper} ref={ref}>
        {Array(12)
          .fill(null)
          .map((_, index) => (
            <MonthHeaderColumn
              key={index}
              monthIndex={index}
              year={props.year}
            />
          ))}
        {/* make some extra room to allow for some
          extended horizontal scrolling caused by
          the vertical scrollbar of the content */}
        <div className={styles.filler}></div>
      </div>
    </div>
  )
}

export default memo(React.forwardRef<HTMLDivElement, Props>(Header))
