import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { selectMembershipById } from '../../../features/memberships/membershipsSlice'
import Avatar from './Avatar'

interface Props {
  membershipId?: string
  className?: string
  url?: string
}

const ConnectedAvatar = (props: Props) => {
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(props.url)
  const membership = useAppSelector((state) =>
    selectMembershipById(state, props.membershipId || '')
  )

  useEffect(() => {
    function loadAvatarByMembershipId() {
      setAvatarUrl(membership?.avatar || process.env.PUBLIC_URL + '/default_avatar.png')
    }

    if (props.url) {
      setAvatarUrl(props.url)
    } else if (props.membershipId && !membership?.isBlocked) {
      loadAvatarByMembershipId()
    }
  }, [membership, membership?.isBlocked, membership?.avatar, props.membershipId, props.url])

  return <Avatar
    isBlocked={membership?.isBlocked}
    avatarUrl={avatarUrl}
    {...props}
  />
}

export default ConnectedAvatar
