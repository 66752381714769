import { IMembership } from '../../../features/memberships/membershipsSlice'
import styles from './Initials.module.css'
import { Spin } from 'antd'
import { useContext } from 'react'
import { ShiftContext } from '../../contexts/shift'

interface IProps {
  small?: boolean
  isLoading?: boolean
  backgroundColor?: string
  useWhiteFont?: boolean
  firstName?: string
  lastName?: string
  membership?: IMembership
  dragHandleClassName?: string
}

export default function Initials(props: IProps) {
  const shiftContext = useContext(ShiftContext)

  let initials
  let backgroundColor
  let fontColor

  if (props.membership) {
    initials =
      props.membership.firstName.substring(0, 1) +
      props.membership.lastName.substring(0, 1)
    backgroundColor = props.membership.backgroundColor
    fontColor = props.membership.useWhiteFont ? 'white' : 'black'
  } else {
    initials =
      props.firstName && props.lastName
        ? props.firstName.substring(0, 1) + props.lastName.substring(0, 1)
        : '??'
    backgroundColor = props.backgroundColor
    fontColor = props.useWhiteFont ? 'white' : 'black'
  }

  // todo refactor shiftContext because blatantly isInactive is related to the workAssignment
  if (shiftContext?.isInactive) backgroundColor = '#F3F3F3'

  return (
    <div
      className={`${props.small ? styles.rootSmall : styles.root} ${
        props.dragHandleClassName || ''
      }`}
      style={{ backgroundColor, color: fontColor, opacity: props.isLoading ? .5:1 }}
    >
      {props.isLoading && (
        <div className={styles.spinWrapper}>
          <Spin />
        </div>
      )}
      <span>{initials}</span>
    </div>
  )
}
