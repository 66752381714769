import config from '../../app/config'
import { store } from '../../app/store'
import {
  authenticated,
  sessionExpired,
} from '../../features/authentication/authenticationSlice'

export function fetchXsrfToken() {
  const url = `${config.backend.url}/session/token`

  return fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    }),
  }).then((response) => response.text())
}

export async function authorizedFetch(url, params = {}) {
  let response = await _fetch(url, params)
  if (response.status === 401) {
    try {
      const refreshResult = await refreshToken()
      if (refreshResult.status === 200) {
        const payload = await refreshResult.json()
        store.dispatch(
          authenticated({
            accessToken: payload.access_token,
            refreshToken: payload.refresh_token,
          })
        )
        response = await _fetch(url, params)
      } else {
        store.dispatch(sessionExpired())
        throw new Error('session expired')
      }
    } catch {
      store.dispatch(sessionExpired())
      throw new Error('session expired')
    }
  }
  return response
}

async function _fetch(url, params) {
  const newParams = await updateHeaders(params)
  return fetch(url, newParams)
}

async function updateHeaders(params) {
  const newParams = Object.assign({}, params)

  const authentication = store.getState().authentication
  const headers = {
    Authorization: `Bearer ${authentication.accessToken}`,
  }
  const method = params.method ? params.method.toLowerCase() : 'get'
  if (method === 'post' || method === 'delete' || method === 'patch') {
    headers['X-CSRF-Token'] = await fetchXsrfToken(authentication.access_token)

    if (params.contentType) {
      headers['Content-Type'] = params.contentType
      headers.Accept = params.contentType
    } else {
      headers['Content-Type'] = 'application/vnd.api+json'
      headers.Accept = 'application/vnd.api+json'
    }
  } else {
    headers['Content-Type'] = 'application/json'
    headers.Accept = 'application/json'
  }
  newParams.headers = new Headers(headers)
  return newParams
}

function refreshToken() {
  const authentication = store.getState().authentication
  let formData = new FormData()
  formData.append('client_id', config.keycloak.clientId)
  formData.append('grant_type', 'refresh_token')
  formData.append('refresh_token', authentication.refreshToken)

  return fetch(
    `${config.keycloak.url}/auth/realms/${config.keycloak.realm}/protocol/openid-connect/token`,
    {
      method: 'post',
      body: new URLSearchParams(formData),
    }
  )
}
