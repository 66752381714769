import { Button, PageHeader } from 'antd'
import {useEffect, useState} from 'react'
import { useAppSelector } from '../../app/hooks'
import StaffModal from '../../common/components/StaffModal/StaffModal'
import styles from './Staff.module.css'
import {
  IMembership,
  selectMembershipsByEmploymentType,
} from '../../features/memberships/membershipsSlice'
import TableView from './components/TableView'
import { MembershipContext } from '../../common/contexts/membership'
import {
  selectCurrentUserCreatePermissions,
  selectCurrentUser
} from '../../features/currentUser/currentUserSlice'
import { EEmploymentType } from '../../features/memberships/employmentType'

export default function Staff() {
  const currentUser = useAppSelector(selectCurrentUser)

  // set the employmenttype when currentuserinfo is loaded.
  useEffect(() => {
    if (currentUser?.employmentType)
      setEmploymentType(currentUser.employmentType)
  }, [currentUser])

  const [isStaffModalVisible, setStaffModalVisible] = useState(false)
  const [membershipToBeEdited, setMembershipToBeEdited] = useState<any>(null)
  const [EmploymentType, setEmploymentType] = useState(EEmploymentType.Nurse)

  const memberships = useAppSelector((state) => selectMembershipsByEmploymentType(state, EmploymentType))

  // Filter blocked memberships.
  const filteredMemberships = memberships.filter((membership) => {
    return !membership.isBlocked
  })

  const showStaffModal = () => setStaffModalVisible(true)
  const hideStaffModal = () => {
    setStaffModalVisible(false)
    setMembershipToBeEdited(null)
  }
  const handleEditMembership = (membership: IMembership) => {
    setMembershipToBeEdited(membership)
    setStaffModalVisible(true)
  }

  const createPermissions: any = useAppSelector(
    selectCurrentUserCreatePermissions
  )
  const canCreateMember =
    createPermissions &&
    createPermissions['user'] &&
    createPermissions['membership']

  return (
    <>
      <PageHeader
        title="Mitarbeiter"
        className={styles.pageHeader}
        extra={[
          <Button onClick={showStaffModal} key="1" disabled={!canCreateMember}>
            Mitarbeiter hinzufügen
          </Button>,
        ]}
      />
      <TableView
        memberships={filteredMemberships}
        employmentType={EmploymentType}
        handleEmploymentTypeChanged={setEmploymentType}
        handleEditMembership={handleEditMembership}
      />
      <MembershipContext.Provider value={membershipToBeEdited}>
        <StaffModal
          visible={isStaffModalVisible}
          handleClose={hideStaffModal}
        />
      </MembershipContext.Provider>
    </>
  )
}
