import styles from './MemberHeaderColumn.module.css'

const MemberHeaderColumn = () => {
  return (
    <div className={styles.root}>
      <span className={styles.label}>Mitarbeiter</span>
    </div>
  )
}

export default MemberHeaderColumn
