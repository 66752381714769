import { format } from 'date-fns'
import { useAppSelector } from '../../app/hooks'
import { selectWorkAssignmentsOfShift } from '../workAssignments/workAssignmentsSlice'
import Shift from './Shift'
import { selectShiftById } from './shiftsSlice'

interface IProps {
  shiftId: string
  date: Date
  isDropDisabled: boolean
}

function ConnectedShift(props: IProps) {
  const shift = useAppSelector((state) => selectShiftById(state, props.shiftId))
  let workAssignments = useAppSelector((state) =>
    selectWorkAssignmentsOfShift(state, props.shiftId, format(props.date, 'yyyy-MM-dd'))
  )

  if (!shift) return null

  return (
    <Shift
      workAssignments={workAssignments}
      shift={shift}
      date={props.date}
      isDropDisabled={props.isDropDisabled}
    />
  )
}

export default ConnectedShift
