import { DatePicker, Form, FormInstance, Select } from 'antd'
import { Option } from 'antd/lib/mentions'
import { isSameMonth, isThisMonth, isFuture } from 'date-fns'
import { Moment } from 'moment'
import { Component, createRef } from 'react'
import { IDutyRoster } from '../../../features/duty-rosters/dutyRostersSlice'

interface IProps {
  templates: IDutyRoster[]
  visible: boolean
  existingDutyRosterDates: Date[]
  handleFinish: (values: ICreateDutyRosterFormValues) => Promise<void>
}

export interface ICreateDutyRosterFormValues {
  templateId: string
  period: Moment
}

export default class CreateDutyRosterForm extends Component<IProps> {
  private formRef = createRef<FormInstance>()

  constructor(props: IProps) {
    super(props)

    this.isDateDisabled = this.isDateDisabled.bind(this)
  }

  renderTemplateOptions() {
    if (!this.props.templates) return null

    return this.props.templates.map((template) => {
      return (
        <Option value={template.id} key={template.id}>
          {template.institutionName} {template.month + 1}/{template.year}
        </Option>
      )
    })
  }

  submit() {
    this.formRef.current?.submit()
  }

  isDateDisabled(value: Moment) {
    const dateHasGotDutyRoster = !!this.props.existingDutyRosterDates.find((date) =>
      isSameMonth(date, value.toDate())
    )
    const isCurrentMonth = isThisMonth(value.toDate())
    const isInPast = !isFuture(value.toDate())
    return dateHasGotDutyRoster || isCurrentMonth || isInPast
  }

  render() {
    return (
      <Form
        ref={this.formRef}
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        onFinish={(values) => {
          this.props.handleFinish(values)
        }}
        preserve={false}
      >
        <Form.Item
          label="Vorlage"
          name="templateId"
          tooltip="Beim Erstellen des Dienstplans werden alle Schichten der Vorlage übernommen"
          rules={[
            { required: true, message: 'Bitte wählen Sie eine Vorlage aus.' },
          ]}
        >
          <Select>{this.renderTemplateOptions()}</Select>
        </Form.Item>
        <Form.Item
          label="Zeitraum"
          name="period"
          rules={[
            { required: true, message: 'Bitte wählen Sie einen Zeitraum aus.' },
          ]}
        >
          <DatePicker
            picker="month"
            placeholder="Monat wählen"
            format="MMM YYYY"
            disabledDate={this.isDateDisabled}
          />
        </Form.Item>
      </Form>
    )
  }
}
