import { Tooltip } from 'antd'
import clx from 'classnames'
import { isFirstDayOfMonth, isToday, isWeekend } from 'date-fns'
import { memo } from 'react'
import styles from './MonthHeaderDayNumber.module.css'

interface Props {
  dayOfYear: number
  nameOfPublicHoliday: string
  day: Date
  nameOfSchoolHoliday: string
}
const MonthHeaderDayNumber = (props: Props) => {
  const rootClasses = clx({
    [styles.root]: true,
    [styles.isWeekend]: isWeekend(props.day),
    [styles.isFirstOfMonth]: isFirstDayOfMonth(props.day),
    [styles.isPublicHoliday]: !!props.nameOfPublicHoliday,
    [styles.isSchoolHoliday]: !!props.nameOfSchoolHoliday,
    [styles.isToday]: isToday(props.day),
  })

  return (
    <Tooltip
      title={
        props.nameOfPublicHoliday
          ? props.nameOfPublicHoliday
          : props.nameOfSchoolHoliday || ''
      }
    >
      <span className={rootClasses}>{props.dayOfYear}</span>
    </Tooltip>
  )
}

export default memo(MonthHeaderDayNumber)
