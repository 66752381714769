import { Spin, Table } from 'antd'
import cs from 'classnames'
import { add, eachWeekOfInterval, lastDayOfMonth } from 'date-fns'
import { useLayoutEffect, useState } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { useAppDispatch } from '../../../../app/hooks'
import { dragEnded, startDrag } from '../../../../features/app/appSlice'
import { update } from '../../../../features/workAssignments/workAssignmentsSlice'
import { useDutyRosterContext } from '../../../contexts/dutyRoster'
import Day from '../Day'
import styles from './Month.module.css'

interface RowData {
  key: number
  date: Date
  firstDayOfMonth: Date
}

export function Month() {
  const dispatch = useAppDispatch()
  const dutyRosterContext = useDutyRosterContext()
  const [tableHeight, setTableHeight] = useState(1000)

  useLayoutEffect(() => {
    if (!dutyRosterContext.firstDayOfMonth) {
      return
    }
    const wrapper = document.getElementById('table-month')
    const wrapperHeight = wrapper!.getBoundingClientRect().height
    const tableHeader = document.getElementsByClassName('ant-table-thead')[0]
    const tableHeaderHeight = tableHeader.getBoundingClientRect().height

    setTableHeight(wrapperHeight - tableHeaderHeight)
  }, [dutyRosterContext.firstDayOfMonth, tableHeight])

  if (!dutyRosterContext.firstDayOfMonth) {
    return <Spin size="large" />
  }

  const startDates = eachWeekOfInterval(
    {
      start: dutyRosterContext.firstDayOfMonth,
      end: lastDayOfMonth(dutyRosterContext.firstDayOfMonth),
    },
    { weekStartsOn: 1 }
  )

  const onDragEnd = (result: DropResult) => {
    dispatch(dragEnded())
    const { source, destination } = result

    if (!destination) return

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return

    dispatch(update(result))
  }

  //@ts-ignore tslint doesn't take the not null check at line 25 into account :shrug
  const rows: RowData[] = startDates.map((startDate, index) => ({
    key: index,
    date: startDate,
    firstDayOfMonth: dutyRosterContext.firstDayOfMonth,
  }))

  const renderDay = (dayOffset: number) => {
    return (_: any, record: RowData) => {
      return (
        <Day
          date={add(record.date, { days: dayOffset })}
          firstDayOfMonth={record.firstDayOfMonth}
        />
      )
    }
  }

  const columns = [
    {
      title: 'Montag',
      key: 'monday',
      render: renderDay(0),
      width: 250,
    },
    {
      title: 'Dienstag',
      key: 'tuesday',
      render: renderDay(1),
      width: 250,
    },
    {
      title: 'Mittwoch',
      key: 'wednesday',
      render: renderDay(2),
      width: 250,
    },
    {
      title: 'Donnerstag',
      key: 'thursday',
      render: renderDay(3),
      width: 250,
    },
    {
      title: 'Freitag',
      key: 'friday',
      render: renderDay(4),
      width: 250,
    },
    {
      title: 'Samstag',
      key: 'saturday',
      render: renderDay(5),
      width: 250,
    },
    {
      title: 'Sonntag',
      key: 'sunday',
      render: renderDay(6),
      width: 250
    },
  ]

  const overlayBackgroundStyles = cs({
    [styles.overlayBackground]: true,
    [styles.overlayBackgroundInvisible]: !dutyRosterContext.isLoading,
  })

  return (
    <DragDropContext
      onDragStart={({ draggableId: workAssignmentId }) => {
        dispatch(startDrag(workAssignmentId))
      }}
      onDragEnd={onDragEnd}
    >
      <div className={styles.root} id="table-month">
        <Table
          dataSource={rows}
          columns={columns}
          scroll={{ y: tableHeight }}
          pagination={false}
        />
        <div className={styles.overlay}>
          <div className={overlayBackgroundStyles}></div>
          {dutyRosterContext.isLoading && <Spin size="large" />}
        </div>
      </div>
    </DragDropContext>
  )
}
