import { Button, DatePicker, Form, Modal } from 'antd'
import Text from 'antd/lib/typography/Text'
import { isSameMonth } from 'date-fns'
import moment, { Moment } from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useDutyRosterContext } from '../../contexts/dutyRoster'
import ExportPDFWrapper from '../../PDF/ExportPDFWrapper/ExportPDFWrapper'
import styles from './DutyRosterToPdfExportModal.module.css'
const { RangePicker } = DatePicker

interface IProps {
  visible: boolean
  onCancel: () => void
}

const DutyRosterToPdfExportModal = (props: IProps) => {
  const context = useDutyRosterContext()
  const [date, setDate] = useState<any>([
    moment(context.firstDayOfMonth?.toISOString()),
    moment(context.firstDayOfMonth?.toISOString()),
  ])

  useEffect(() => {
    setDate([
      moment(context.firstDayOfMonth?.toISOString()),
      moment(context.firstDayOfMonth?.toISOString()),
    ])
  },[context.firstDayOfMonth])

  const [downloadLinkVisible, setDownloadLinkVisible] = useState(false)

  const handleDateChanged = useCallback((values) => {
    setDownloadLinkVisible(false)
    setDate(values)
  }, [])

  const isDateDisabled = useCallback(
    (current: Moment) => {
      if (!context.firstDayOfMonth) return true

      // disable today and days before today
      return !isSameMonth(current.toDate(), context.firstDayOfMonth)
    },
    [context.firstDayOfMonth]
  )

  return (
    <Modal
      visible={props.visible}
      title={
        <div className={styles.title}>
          <img src="/pdf-icon.svg" className={styles.pdfLogo} alt="pdf logo" />
          <Text>PDF-Export</Text>
        </div>
      }
      footer={[]}
      onCancel={props.onCancel}
    >
      <div className={styles.wrapper}>
        <div className={styles.formWrapper}>
          <Form layout="vertical">
            <Form.Item
              label="Kalenderwoche(n)"
              required
              tooltip="Auswahl der im PDF-Export enthaltenen Kalenderwoche(n)"
            >
              <RangePicker
                picker="week"
                value={date}
                disabledDate={isDateDisabled}
                onChange={handleDateChanged}
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            disabled={downloadLinkVisible}
            onClick={() => setDownloadLinkVisible(true)}
            className={styles.generateButton}
          >
            generieren
          </Button>
        </div>
        {downloadLinkVisible && (
          <div>
            <ExportPDFWrapper from={date[0].toDate()} to={date[1].toDate()} />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default DutyRosterToPdfExportModal
