import { StyleSheet } from '@react-pdf/renderer'
import { View, Text } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { IMembership } from '../../../features/memberships/membershipsSlice'
import { IShift } from '../../../features/shifts/shiftsSlice'
import { IWorkAssignment } from '../../../features/workAssignments/workAssignmentsSlice'
import WorkAssignment from '../WorkAssignment/WorkAssignment'

const styles = StyleSheet.create({
  root: {
    width: 20,
    marginTop: 2,
    marginBottom: 10,
    alignItems: 'center',
  },
  title: {
    marginTop: 15,
    fontSize: 6,
    marginBottom: 1,
  },
})

interface IProps {
  shift: IShift
  date: Date
  workAssignments: IWorkAssignment[]
  memberships: IMembership[]
}

const Shift = (props: IProps) => {

  const workAssignments = props.workAssignments.filter( (workAssignment) => {
    return workAssignment.shiftId === props.shift.id && workAssignment.date === format(props.date, 'yyyy-MM-dd')
  })

  return (
    <View style={styles.root}>
      <Text style={styles.title}>{props.shift.name}</Text>
      {Object.values(workAssignments).map((workAssignment) => (
        <WorkAssignment
          workAssignment={workAssignment}
          memberships={props.memberships}
          key={workAssignment.id}
        />
      ))}
    </View>
  )
}
export default Shift
