import {
  addDays,
  differenceInMinutes,
  eachDayOfInterval,
  format,
  isWeekend,
  differenceInCalendarDays,
  parse,
  parseISO, getDay,
} from 'date-fns'
import { IShift } from '../../features/shifts/shiftsSlice'
import {EOffDutyType, IOffDuty} from "../../features/off-duty/offDutySlice";
import deLocale from "date-fns/locale/de";
import {IVacationLog} from "../../features/offduty-logs/vacationLogSlice";
import HolidayRepository from './util/holidays'
import {IMembership} from "../../features/memberships/membershipsSlice";

export function calculateWorkingTimeMinutes(
  breakTime: string,
  from: string,
  to: string
): number {
  const start = parseISO(`2020-01-01T${from}`)
  let end = parseISO(`2020-01-01T${to}`)

  if (end < start) {
    // E.g. a shift runs from 20:00 - 04:00, enddate then must be the next
    // day.
    end = addDays(end, 1)
  }
  return differenceInMinutes(end, start) - parseInt(breakTime)
}

export function calculatePublicHolidayOvertimeHours(
  shift: IShift,
  date: Date
): number {
  let start = parseISO(`${format(date, 'yyyy-MM-dd')}T${shift.from}`)
  let end = parseISO(`${format(date, 'yyyy-MM-dd')}T${shift.to}`)
  let startHoliday = HolidayRepository.isHoliday(start)
  if (end < start) {
    // E.g. a shift runs from 20:00 - 04:00, enddate then must be the next
    // day.
    end = addDays(end, 1)
  }

  let endHoliday = HolidayRepository.isHoliday(end)

  // TODO Dec 24 is set as a public holiday starting at 14:00. Check if this is
  // really applicable in our case! For now ignore it.
  const isStartHoliday = startHoliday && startHoliday[0].type === 'public'
  const isEndHoliday = endHoliday && endHoliday[0].type === 'public'

  if (isStartHoliday && isEndHoliday) {
    // Return the entire shift overtime. Start and end are on a holiday.
    return shift.workingMinutes / 60
  }
  if (isStartHoliday && !isEndHoliday) {
    // Only the first part of the shift is on a holiday.
    // todo what about break time in this case?
    const midnightDate = parseISO(`${format(end, 'yyyy-MM-dd')}T00:00:00`)
    const diff = differenceInMinutes(midnightDate, start)
    return diff / 60
  }
  if (!isStartHoliday && isEndHoliday) {
    // Only the second part of the shift is on a holiday.
    // todo what about break time in this case?
    const midnightDate = parseISO(`${format(end, 'yyyy-MM-dd')}T00:00:00`)
    const diff = differenceInMinutes(end, midnightDate)
    return diff / 60
  }
  return 0
}

// get weekends and public holidays within a period
export function getWeekendsAndPublicHolidaysOfInterval(
  from: Date,
  to: Date
): { weekends: Date[]; publicHolidays: Date[] } {
  const publicHolidays: Date[] = []
  const weekends: Date[] = []
  const days = eachDayOfInterval({ start: from, end: to })
  days.forEach((day) => {
    if (isWeekend(day)) {
      weekends.push(day)
      return
    }
    const publicHoliday = HolidayRepository.isHoliday(day)
    if (publicHoliday && publicHoliday[0].type === 'public') {
      publicHolidays.push(day)
      return
    }
  })
  return { weekends, publicHolidays }
}

export function calculateDailyWorkingHours(vertrglAZ: number, numberWeekDays: number) {
  return Math.round((vertrglAZ / numberWeekDays) * 100) / 100
}

export function getNumberOfDays(
  from: Date,
  to: Date
): number {
  return to && from
    ? differenceInCalendarDays(to, from) + 1
    : 0
}

export function getNumberOfValidOffDutyDays(
  offDuty: IOffDuty,
  employee: IMembership | undefined
): number {
  const {weekends, publicHolidays} = getWeekendsAndPublicHolidaysOfInterval(
    parseISO(offDuty.from),
    parseISO(offDuty.to)
  )
  const validWorkDays = employee?.validWeekDays || []
  let numberOfInvalidDays = 0
  let numberOfTotalDays = 0
  const days = eachDayOfInterval({ start: parseISO(offDuty.from), end: parseISO(offDuty.to) })
  days.forEach((day) => {
    numberOfTotalDays++
    if (isNoWorkDay(day, weekends, publicHolidays, validWorkDays))
      numberOfInvalidDays++
  })
  return offDuty.type === EOffDutyType.Vacation
    ? numberOfTotalDays - numberOfInvalidDays
    : numberOfTotalDays
}

export function getNumberOfValidOffDutyDaysInYear(
  offDuty: IOffDuty,
  year: number,
  employee: IMembership
): number {
  const fromDate = parseISO(offDuty.from)
  const toDate = parseISO(offDuty.to)

  const firstDayOfYear = new Date(year, 0, 1)
  const lastDayOfYear = new Date(year, 11, 31)

  const vacationStartDate = firstDayOfYear >= fromDate
    ? firstDayOfYear
    : fromDate

  const vacationEndDate = lastDayOfYear >= toDate
    ? toDate
    : lastDayOfYear

  const specialDays = getWeekendsAndPublicHolidaysOfInterval(
    vacationStartDate,
    vacationEndDate
  )

  const validWorkDays = employee.validWeekDays || []
  let numberOfInvalidDays = 0
  let numberOfTotalDays = 0
  eachDayOfInterval({ start: vacationStartDate, end: vacationEndDate })
    .forEach((day) => {
      numberOfTotalDays++
      if (isNoWorkDay(day, specialDays.weekends, specialDays.publicHolidays, validWorkDays))
        numberOfInvalidDays++
    })

  return offDuty.type === EOffDutyType.Vacation
    ? numberOfTotalDays - numberOfInvalidDays
    : numberOfTotalDays
}

// in the context of the measurement of working time, whole days drop out of the equation under certain conditions
export function isNoWorkDay(day: Date, weekends: Date[], publicHolidays: Date[], validWorkDays: number[]) {
  return(
    weekends.some((d: Date) => d.getDate() === day.getDate()) ||
    publicHolidays.some((d: Date) => d.getDate() === day.getDate()) ||
    !validWorkDays.includes(getDay(day))
  )
}

export function getAnnualVacationStats(
  vacationLogs: IVacationLog[],
  firstDayOfYear: Date,
  lastDayOfYear: Date,
) {
  let vacationDaysFromPreviousYear = 0
  let additionalVacationDays = 0
  let remainingAvailableDays = 0
  let approvedVacationDays = 0
  let vacationDaysPerYear = 0

  vacationLogs.forEach((log) => {
    const logYear = parseInt(format(parse(log.date, 'yyyy-MM-dd', new Date()), 'yyyy', {
      locale: deLocale,
    }))
    if (firstDayOfYear.getFullYear() !== logYear)
      return

    if (
      log.date === format(firstDayOfYear, 'yyyy-MM-dd', { locale: deLocale })
    ) {
      vacationDaysFromPreviousYear = log.carryOverPreviousMonth
    }
    const logMonth = format(parse(log.date, 'yyyy-MM-dd', new Date()), 'MM', {
      locale: deLocale,
    })
    if (logMonth === format(lastDayOfYear, 'MM', { locale: deLocale })) {
      remainingAvailableDays = log.carryOverNextMonth
      // Take these from the year's last vacationlog. This way we make sure
      // we use the updated av-vacation-days value if it was changed
      // during the year.
      vacationDaysPerYear = log.vacationDaysPerYear
    }
    additionalVacationDays += log.additionalVacationDays
    approvedVacationDays += log.approvedDays
  })

  return {
    vacationDaysPerYear: vacationDaysPerYear,
    vacationDaysFromPreviousYear: vacationDaysFromPreviousYear,
    additionalVacationDays: additionalVacationDays,
    remainingAvailableDays: remainingAvailableDays,
    approvedVacationDays: approvedVacationDays
  }
}
