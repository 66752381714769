import { Month } from './Month/Month'
import styles from './Calendar.module.css'
import InfoBar from '../InfoBar/InfoBar'

interface Props {
  isSwapModeActive: boolean
  handleClose: () => void
}

const Calendar = (props: Props) => {

  const isSwapModeActive = props.isSwapModeActive

  return (
    <div className={styles.root}>
      {isSwapModeActive && (
        <InfoBar
          title="Der Dienst-Tauschmodus ist aktiviert. Bitte klicken Sie als nächstes auf den Dienst mit dem sie tauschen möchten."
          closable
          handleClose={props.handleClose}
        />
      )}
      <Month />
    </div>
  )
}

export default Calendar
