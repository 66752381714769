import { memo, useCallback, useRef, useState } from 'react'
import styles from './Shift.module.css'
import { Droppable } from 'react-beautiful-dnd'
import { useAppDispatch } from '../../app/hooks'
import { nanoid } from '@reduxjs/toolkit'
import {
  create,
  IWorkAssignment,
} from '../workAssignments/workAssignmentsSlice'
import { format } from 'date-fns'
import { IShift } from './shiftsSlice'
import WorkAssignment from '../workAssignments'
import { useDutyRosterContext } from '../../common/contexts/dutyRoster'
import throttle from 'lodash.throttle'

const { DropTarget } = require('react-drag-drop-container')

interface IProps {
  workAssignments: IWorkAssignment[]
  shift: IShift
  date: Date
  isDropDisabled: boolean
}

function Shift(props: IProps) {
  const dutyRosterContext = useDutyRosterContext()
  const [showHighlight, setShowHighlight] = useState(false)
  const dispatch = useAppDispatch()
  const rootRef = useRef(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onHit = useCallback(
    throttle(({ dragData }: { dragData: IWorkAssignment }) => {
      if (!dutyRosterContext.dutyRoster) {
        throw new Error('the dutyRoster is missing from the context')
      }

      setShowHighlight(false)
      dispatch(
        create({
          id: nanoid(),
          shiftId: props.shift.id,
          actualShiftId: dragData.actualShiftId,
          membershipId: dragData.id,
          date: format(props.date, 'yyyy-MM-dd'),
          abbreviation: dragData.abbreviation,
          exchangeable: dragData.exchangeable,
          dutyRosterId: dutyRosterContext.dutyRoster.id,
          deviatingHours: 0,
          isHolidayWork: false,
          isCanceled: dragData.isCanceled,
          canUpdate: dragData.canUpdate,
          canRemove: dragData.canRemove,
          isOptimistic: true
        })
      )
    }, 500),
    [dutyRosterContext.dutyRoster, dispatch, props.shift.id, props.date]
  )

  const handleDragEnter = useCallback(() => {
    setShowHighlight(true)
  }, [setShowHighlight])
  const handleDragLeave = useCallback(() => {
    setShowHighlight(false)
  }, [setShowHighlight])

  return (
    <DropTarget
      targetKey="shiftTargetKey"
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onHit={onHit}
    >
      <div className={styles.root} ref={rootRef}>
        <span className={styles.name}>{props.shift.name}</span>
        <Droppable
          droppableId={`${props.shift.id}_${format(props.date, 'yyyy-MM-dd')}`}
          isDropDisabled={props.isDropDisabled}
        >
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`${styles.droppableContainer} ${
                snapshot.isDraggingOver || showHighlight
                  ? styles.draggedOver
                  : ''
              }`}
            >
              {Object.values(props.workAssignments).map(
                (workAssignment, index) => (
                  <WorkAssignment
                    shiftId={props.shift.id}
                    workAssignment={workAssignment}
                    index={index}
                    key={workAssignment.id}
                  />
                )
              )}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DropTarget>
  )
}

export default memo<IProps>(Shift, areEqual)

function areEqual(prevProps: IProps, nextProps: IProps) {
  let result = true
  if (prevProps.shift !== nextProps.shift) return false
  if (prevProps.date !== nextProps.date) return false
  if (prevProps.isDropDisabled !== nextProps.isDropDisabled) return false

  // compare workAssignments
  if (prevProps.workAssignments.length !== nextProps.workAssignments.length)
    return false

  prevProps.workAssignments.forEach((prevWork, index) => {
    if (prevWork !== nextProps.workAssignments[index]) {
      result = false
      return
    }
  })
  return result
}
