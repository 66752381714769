import styles from './DropDisabledInfo.module.css'
import { CloseCircleOutlined } from '@ant-design/icons'
import { memo } from 'react'

interface IProps {
  isVacation: boolean
  isDayOfDifferentMonth: boolean
}
export default memo(function DropDisabledInfo({
  isVacation,
  isDayOfDifferentMonth
}: IProps) {
  return (
    <div className={styles.root}>
      {isVacation && <span className={styles.text}>Urlaub</span>}
      {!isVacation && isDayOfDifferentMonth && (
        <span className={styles.icon}>
          <CloseCircleOutlined />
        </span>
      )}
    </div>
  )
})
