import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Spin, Tooltip } from 'antd'
import { useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { dragEnded, dragStarted } from '../../../features/app/appSlice'
import {
  selectCurrentUserCreatePermissions,
  selectCurrentUser,
} from '../../../features/currentUser/currentUserSlice'
import {
  IMembership,
  selectMembershipsByEmploymentType,
} from '../../../features/memberships/membershipsSlice'
import { selectVacationLogsByDate } from '../../../features/offduty-logs/vacationLogSlice'
import { selectWorkLogsByDutyRoster } from '../../../features/work-logs/workLogSlice'
import { useDutyRosterContext } from '../../contexts/dutyRoster'
import { VacationLogContext } from '../../contexts/vacationLog'
import Initials from '../Initials'
import Member from '../Member/Member'
import styles from './MemberList.module.css'
const { DragDropContainer } = require('react-drag-drop-container')

export function MemberList() {
  const dispatch = useAppDispatch()
  const context = useDutyRosterContext()
  const currentUser = useAppSelector(selectCurrentUser)
  const memberships = useAppSelector((state) =>
    selectMembershipsByEmploymentType(state, context.dutyRoster?.employmentType)
  )
  const isLoadingMemberships = useAppSelector(
    (state) => state.memberships.status === 'pending'
  )
  const dutyRosterContext = useDutyRosterContext()

  const workLogs = useAppSelector((state) =>
    selectWorkLogsByDutyRoster(state, context.dutyRoster?.id)
  )
  const vacationLogs = useAppSelector((state) =>
    selectVacationLogsByDate(state, context.firstDayOfMonth)
  )

  const [isInFullMode, setInFullMode] = useState(true)
  const toggleMode = useCallback(() => {
    if (isInFullMode) {
      dutyRosterContext.setLoading(true)
      window.setTimeout(() => {
        setInFullMode(false)
        dutyRosterContext.setLoading(false)
      }, 100)
    } else {
      dutyRosterContext.setLoading(true)
      window.setTimeout(() => {
        setInFullMode(true)
        dutyRosterContext.setLoading(false)
      }, 100)
    }
  }, [dutyRosterContext, isInFullMode])

  const createPermissions: any = useAppSelector(
    selectCurrentUserCreatePermissions
  )

  const isRosterApproved = context?.dutyRoster?.isApproved

  const canCreateWorkAssignment =
    createPermissions &&
    createPermissions['work_assignment'] &&
    !isRosterApproved &&
    context.dutyRoster?.employmentType === currentUser.employmentType


  const renderMember = (membership: IMembership) => {
    const customDragElement = <Initials membership={membership} />
    const workLog = (workLogs || []).find(
      (workLog) => workLog.employee === membership.id
    )
    const vacationLog = (vacationLogs || []).find(
      (vacationLog) => vacationLog.employee === membership.id
    )

    // If no worklog exists, do not render the member.
    if (!workLog)
      return null

    return (
      <div
        key={membership.id}
        className={`${styles.item} ${(membership.isBlocked && isInFullMode) ! ? styles.isBlocked : ''} ${!isInFullMode ? styles.itemSmall : ''}`}
      >
        <DragDropContainer
          targetKey="shiftTargetKey"
          onDragStart={() => dispatch(dragStarted(membership.id))}
          onDragEnd={() => dispatch(dragEnded())}
          dragData={membership}
          dragHandleClassName={
            canCreateWorkAssignment
              ? 'dragHandle'
              : 'dragging-is-disabled-handle'
          }
          customDragElement={customDragElement}
          noDragging={!canCreateWorkAssignment || membership.isBlocked}
        >
          <VacationLogContext.Provider value={vacationLog}>
            <Member
              small={!isInFullMode}
              membershipId={membership.id}
              dragHandleClassName={'dragHandle'}
              workLog={workLog}
            />
          </VacationLogContext.Provider>
        </DragDropContainer>
      </div>
    )
  }

  return (
    <div
      className={`${styles.root} ${isInFullMode ? styles.full : styles.small}`}
    >
      {isLoadingMemberships && <Spin size="large" />}
      {!isLoadingMemberships && (
        <>
          <Tooltip title={isInFullMode ? 'Verkleinern' : 'Vergrößern'}>
            <Button
              disabled={dutyRosterContext.isLoading}
              className={styles.modeToggle}
              onClick={toggleMode}
              size="small"
              icon={isInFullMode ? <LeftOutlined /> : <RightOutlined />}
            />
          </Tooltip>
          <div className={styles.itemWrapper}>
            {(memberships || []).map(renderMember)}
          </div>
        </>
      )}
    </div>
  )
}
