import Calendar from '../../common/components/calendar'
import { MemberList } from '../../common/components/MemberList/MemberList'
import { Button, message, PageHeader, Spin, Tooltip } from 'antd'
import styles from './DutyRoster.module.css'
import * as H from 'history'
import { format } from 'date-fns'
import locale from 'date-fns/locale/de'
import { Menu, Dropdown } from 'antd'
import { useCallback, useState } from 'react'
import DutyRosterToExcelExportModal from '../../common/components/DutyRosterToExcelExportModal/DutyRosterToExcelExportModal'
import { DownOutlined } from '@ant-design/icons'
import DutyRosterToPdfExportModal from '../../common/components/DutyRosterToPdfExportModal/DutyRosterToPdfExportModal'
import { useDutyRosterContext } from '../../common/contexts/dutyRoster'
import {EEmploymentType} from "../../features/memberships/employmentType";

interface IProps {
  history: H.History
}

export default function DutyRoster(props: IProps) {
  const [isExportToExcelVisible, setExportToExcelVisible] = useState(false)
  const [isExportToPdfVisible, setExportToPdfVisible] = useState(false)

  const context = useDutyRosterContext()

  const type = context?.dutyRoster?.employmentType === EEmploymentType.Nurse
    ? 'Pflegepersonal'
    : 'Ärztliches Personal'

  const handleClick = useCallback((e) => {
    switch (e.key) {
      case 'excel':
        setExportToExcelVisible(true)
        break

      case 'pdf':
        setExportToPdfVisible(true)
        break

      default:
        message.error(`The key ${e.key} isn't handled`)
    }
  }, [])

  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item
        key="pdf"
        icon={
          <img src="/pdf-icon.svg" alt="pdf icon" className={styles.pdfIcon} />
        }
      >
        als PDF
      </Menu.Item>
      <Menu.Item
        key="excel"
        icon={
          <img
            src="/Excel_2013_23480.png"
            alt="excel icon"
            className={styles.excelIcon}
          />
        }
      >
        als Excel
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        subTitle={
          context.firstDayOfMonth ? (
            `${type} | ${format(context.firstDayOfMonth, 'MMMM yyyy', {
              locale,
            })}`
          ) : (
            <Spin size="small" className={styles.spin} />
          )
        }
        onBack={() => props.history.push('/')}
        title="Dienstplan"
        extra={[
          <Tooltip
            key="1"
            title={
              context.isFullyLoaded
                ? ''
                : 'Nicht verfügbar bis das Laden abgeschlossen ist.'
            }
          >
            <span>
              <Dropdown
                overlay={menu}
                trigger={['click']}
                placement="bottomRight"
                disabled={!context.dutyRoster || !context.isFullyLoaded}
              >
                <Button>
                  exportieren <DownOutlined />
                </Button>
              </Dropdown>
            </span>
          </Tooltip>,
        ]}
      />

      <div className={styles.content}>
        <div className={styles.innerContentWrapper}>
          <MemberList />
          <Calendar />
        </div>
      </div>

      <DutyRosterToPdfExportModal
        visible={isExportToPdfVisible}
        onCancel={() => setExportToPdfVisible(false)}
      />
      <DutyRosterToExcelExportModal
        visible={isExportToExcelVisible}
        onCancel={() => setExportToExcelVisible(false)}
      />
    </>
  )
}
