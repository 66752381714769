import { Page, Text, Document, StyleSheet, View } from '@react-pdf/renderer'
import Month from './Month/Month'
import { format } from 'date-fns'
import { IShift } from '../../features/shifts/shiftsSlice'
import { IWorkAssignment } from '../../features/workAssignments/workAssignmentsSlice'
import { IMembership } from '../../features/memberships/membershipsSlice'
import locale from 'date-fns/locale/de'
import { useDutyRosterContext } from '../contexts/dutyRoster'

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
  },
  title: {
    fontSize: 17,
    paddingVertical: 20,
  },
  titleWrapper: {
    marginRight: 10,
  },
  subtitle: {
    color: '#555',
    fontSize: 17,
  },
  header: {
    flexDirection: 'row',
    backgroundColor: '#fef7dc',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
})

interface IProps {
  shifts: IShift[]
  workAssignments: IWorkAssignment[]
  memberships: IMembership[]
  from: Date
  to: Date
}

const DutyRosterPDF = (props: IProps) => {
  const dutyRosterContext = useDutyRosterContext()
  const firstDayOfMonth = dutyRosterContext.firstDayOfMonth

  if(!firstDayOfMonth) {
    throw new Error('The firstDayOfMonth is missing')
  }

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.titleWrapper}>
            <Text style={styles.title}>Dienstplan</Text>
          </View>
          <Text style={styles.subtitle}>
            Pflegekräfte | {format(firstDayOfMonth, 'MMMM yyyy', { locale })}
          </Text>
        </View>
        <Month
          from={props.from}
          to={props.to}
          shifts={props.shifts}
          workAssignments={props.workAssignments}
          memberships={props.memberships}
        />
      </Page>
    </Document>
  )
}

export default DutyRosterPDF
