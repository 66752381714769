import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import Text from 'antd/lib/typography/Text'
import styles from './InfoBar.module.css'

interface IProps {
  title: string
  closable: boolean
  handleClose: () => void
}

const InfoBar = (props: IProps) => {
  return (
    <div className={styles.root}>
      <Text className={styles.text}>{props.title}</Text>
      {props.closable && (
        <Button
        icon={<CloseOutlined/>}
        type="text"
          style={{
            color: 'white'
          }}
          size="small"
          onClick={props.handleClose}
        ></Button>
      )}
    </div>
  )
}

export default InfoBar
