import { Form, FormInstance, InputNumber } from 'antd'
import { Component, createRef } from 'react'

interface IProps {
  visible: boolean
  handleFinish: (values: IDeviatingHoursFormValues) => Promise<void>
  initialValues: IDeviatingHoursFormValues | null
}

export interface IDeviatingHoursFormValues {
  deviatingHours: number
}

export default class DeviatingHoursForm extends Component<IProps> {
  private formRef = createRef<FormInstance>()

  submit() {
    this.formRef.current?.submit()
  }
  render() {
    return (
      <Form
        ref={this.formRef}
        layout="horizontal"
        onFinish={(values) => {
          this.props.handleFinish(values)
        }}
        preserve={false}
        initialValues={this.props.initialValues || undefined}
      >
        <Form.Item
          label="Stunden +/-"
          style={{ marginBottom: 0 }}
          name="deviatingHours"
          rules={[
            {
              required: true,
              message: 'Bitte geben Sie eine Zahl ein',
            },
          ]}
        >
          <InputNumber
            // todo hard to think of meaningful limits. Change if required (in backend too!)
            min={-24}
            max={24}
            style={{ width: 60 }}
          />
        </Form.Item>
      </Form>
    )
  }
}
