import styles from './TimeOffRequestsPopover.module.css'
import Text from 'antd/lib/typography/Text'
import { IOffDuty } from '../../../features/off-duty/offDutySlice'
import { format } from 'date-fns'
import locale from 'date-fns/locale/de'
import TimeOffRequest from '../TimeOffRequest/TimeOffRequest'

interface IProps {
  timeOffRequests: IOffDuty[]
  date: Date
}

export default function TimeOffRequestsPopover(props: IProps) {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Text strong>Dienstfrei-Wünsche</Text>
        <Text className={styles.date}>
          {format(props.date, 'dd.MM.yyyy', {
            locale,
          })}
        </Text>
      </div>
      {props.timeOffRequests.map((request: IOffDuty) => (
        <TimeOffRequest request={request} />
      ))}
    </div>
  )
}
